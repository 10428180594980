import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { LoaderIcon } from "react-hot-toast";
import { format } from "date-fns"; 
import { getIndividualScore } from "../../../../../api/helper";


const QuestionaireGraphCard = ({
  loading,
  setLoading,
  showGraph,
  selecQuestionnaires,
}) => {
   
  const [title, setTitle] = useState("");
  
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: (value) => value, // Show the formatted date
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yyyy HH:mm",
        },
      },
    },
  });


  useEffect(() => {
    if (showGraph) {
      async function getData() {
        setLoading(true);
        try {
          const resData = await getIndividualScore(showGraph);
            const response = resData.data

          if (response) {
            const label = selecQuestionnaires?.find(
              (item) => item.id === showGraph
            ).label;
            setTitle(label);
            const data = response.individualScore.map((item) => item.score);
            const date = response.individualScore.map((item) =>
              format(new Date(item.lastUpdated), "MM/dd/yyyy")
            );

            setState((prevState) => ({
              ...prevState,
              series: [
                {
                  name: label,
                  data,
                },
              ],
              options: {
                ...prevState.options,
                xaxis: {
                  ...prevState.options.xaxis,
                  categories: date,
                },
                tooltip: {
                  x: {
                    format: "dd/MM/yyyy",
                  },
                },
              },
            }));
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
      getData();
    }
  }, [showGraph]);

  return (
    <div id="chart">
      {/* {loading ? (
        <LoaderIcon />
      ) : ( */}
        <>
          <h5 className="text-lg text-center underline font-semibold ">
            {title}
          </h5>
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="line"
            height={350}
          />
        </>
      {/* )}  */}
    </div>
  );
};

export default QuestionaireGraphCard;
