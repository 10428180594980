import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import {
  Clinicrequestlist,
  ClinicTeammembers,
  FacilityTeamembers,
  GetClinic,
  GetProviderType,
  PatientAddressUpdate,
  PatientAddTeam,
  PatientgetTeam,
  PatientUpdateTeam,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { useSelector } from "react-redux";
import { DropDownManuCustom } from "../../../Components/DropDownButton";

const validationSchema = Yup.object().shape({
  facility: Yup.string().required("Facility is required"),
  providerType: Yup.string().required("Provider Type is required"),
  teamMember: Yup.string().required("Team Member is required"),
  fromDate: Yup.date()
    .typeError("Please select a valid From Date")
    .required("From Date is required"),
  toDate: Yup.date()
    .typeError("Please select a valid To Date")
    .required("To Date is required"),
  lastSeenDate: Yup.date()
    .typeError("Please select a valid Last Seen Date")
    .required("Last Seen Date is required"),
});

const Clientinternal = ({ info, closeModal, Fetchteammember,selectedMember }) => {
  const [data, setdata] = useState([]);
  const [clinicdata, setClinicData] = useState([]);
  const [teammemberdata, setteammemberdata] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const getClinician = async () => {
    try {
      const response = await Clinicrequestlist();
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.companyName} / ${e.uniqueId}`,
          ...e,
        };
      });
      setClinicData(data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const GetTeamMember = async (facilityId) => {
    try {
      const response = await ClinicTeammembers(profiledata?._id);
      const data = response?.data?.data?.doctorIds?.map((e) => {
        return {
          name: `${e.firstName} ${e.lastName}`,
          ...e,
        };
      });
      setteammemberdata(data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClinician();
    GetTeamMember();
  }, []);
  const GetAppoientmenttype = async (facilityId) => {
    // console.log(facilityId,"functionhit======")

    try {
      const response = await GetProviderType(`?id=${profiledata?._id}`);
      setdata(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching provider types:", error);
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      facility: profiledata?.companyName || "",
      providerType: selectedMember?.providerType || "",
      teamMember: selectedMember?.teamMember || "",
      fromDate: selectedMember?.fromDate
        ? new Date(selectedMember.fromDate).toISOString().split("T")[0]
        : "",
      toDate: selectedMember?.toDate
        ? new Date(selectedMember.toDate).toISOString().split("T")[0]
        : "",
      lastSeenDate: selectedMember?.lastSeenDate
        ? new Date(selectedMember.lastSeenDate).toISOString().split("T")[0]
        : "",
    },
  });

  const onSubmit = async (data) => {
    data.agencyId = info?._id;
    data.memberType = "internal";
    try {
      // const response = await PatientAddTeam(data);
         let response; 
      
            if (selectedMember) {
              response = await PatientUpdateTeam(data, selectedMember?._id);
            } else {
              response = await PatientAddTeam(data);
              reset();
            }
      // console.log(response?.data, "response======");
      ShowToast(response?.data?.message);
      reset();
      Fetchteammember();
      closeModal();
    } catch (error) {
      console.log(error);
      closeModal();
    }
    closeModal();
  };

  useEffect(() => {
    GetAppoientmenttype();
  }, []);
  return (
    <div id="internal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="facility-search-facility">Facility</label>
          <input
            type="search"
            id="facility-search-facility"
            placeholder="Search Facility"
            {...register("facility")}
            disabled
          />
          {errors.facility && (
            <p className="error-message">{errors.facility.message}</p>
          )}
        </div>
        <div className="form-group">
          <DropDownManuCustom
            label="Provider Type"
            placeholder="Select option"
            options={data}
            name="providerType"
            dropdownicon
            register={register}
            id="name"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -15 }}
            defaultValue={selectedMember?.providerType || ""}
          />
          {errors.providerType && (
            <p className="error-message">{errors.providerType.message}</p>
          )}
        </div>
        <div className="form-group">
          <DropDownManuCustom
            label="Team Member"
            placeholder="Select option"
            options={teammemberdata}
            name="teamMember"
            dropdownicon
            register={register}
            id="name"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
            defaultValue={selectedMember?.teamMember || ""}
          />
          {errors.teamMember && (
            <p className="error-message">{errors.teamMember.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -5 }}>
          <label htmlFor="from-date-internal">From Date</label>
          <input
            type="date"
            id="from-date-internal"
            {...register("fromDate")}
          />
          {errors.fromDate && (
            <p className="error-message">{errors.fromDate.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -13 }}>
          <label htmlFor="to-date-internal">To Date</label>
          <input type="date" id="to-date-internal" {...register("toDate")} />
          {errors.toDate && (
            <p className="error-message">{errors.toDate.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -12 }}>
          <label htmlFor="lastseen-date-internal">Last Seen Date</label>
          <input
            type="date"
            id="lastseen-date-internal"
            {...register("lastSeenDate")}
          />
          {errors.lastSeenDate && (
            <p className="error-message">{errors.lastSeenDate.message}</p>
          )}
        </div>

        <div className="form-buttons">
          <button className="cancel" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="save" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Clientinternal;
