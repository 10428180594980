import React, { useEffect, useState } from "react";

import {
  ClientgetTeam,
  PatientDeleteTeam,
  PatientgetTeam,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import Clientinternal from "./Clientinternal";
import ClientExternal from "./ClientExternal";
import ClientFacility from "./ClientFacility";

const CareTeam = ({ info }) => {

  // console.log(info,'===>>info')

  const [activeTab, setActiveTab] = useState("internal");
  const [ismodal, setismodal] = useState(false);
  const [members, setMembers] = useState(null);
  const [activedatatab, setactivedatatab] = useState("internal");
  const [selectedMember, setSelectedMember] = useState(null);

  const handledataTabClick = (tabId) => {
    setactivedatatab(tabId);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const openModal = () => {
    setismodal(true);
  };

  const closeModal = () => {
    setSelectedMember(null);
    setismodal(false);
  };

  const Fetchteammember = async () => {
    try {
      const response = await ClientgetTeam(info?._id, activedatatab);
      setMembers(response?.data?.data?.TeamMember);
      // console.log(response?.data?.data?.TeamMember, "get======PatientgetTeam===");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Fetchteammember();
  }, [activedatatab]);
  const handleRemove = async (id) => {
    //  console.log(id)
    const confirmed = window.confirm(
      "Are you sure you want to delete this Team Member?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const response = await PatientDeleteTeam(id);
      ShowToast(response?.data?.message);
      Fetchteammember();
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (member) => {
    setSelectedMember(member);
    setActiveTab(activedatatab || "internal");
    setismodal(true);
  };
  return (
    <div className="container_NotesTabScreen">
      <h1>Care Team</h1>
      <button
        className="add-button_NotesTabScreen"
        style={{ float: "right" }}
        onClick={openModal}
      >
        Add Member
      </button>

      <div
        className="tabs-container__three"
        style={{ display: "flex", width: "100%", justifyContent: "left" }}
      />
      <div
        className="tabs-container__two"
        style={{ justifyContent: "flex-start" }}
      >
        <button
          className={`tab-button__two ${
            activedatatab === "internal" ? "active-tab__two" : ""
          }`}
          onClick={() => handledataTabClick("internal")}
        >
          Internal
        </button>
        <button
          className={`tab-button__two ${
            activedatatab === "external" ? "active-tab__two" : ""
          }`}
          onClick={() => handledataTabClick("external")}
        >
          External
        </button>
        <button
          className={`tab-button__two ${
            activedatatab === "facility" ? "active-tab__two" : ""
          }`}
          onClick={() => handledataTabClick("facility")}
        >
          Facility
        </button>
      </div>
      <table className="table" id="member-table">
        <thead>
          <tr>
            <th>Facility</th>
            <th>Provider Type</th>
            <th>Team Member</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {members && members.length > 0 ? (
            members?.map((member) => (
              <tr key={member._id}>
                <td>{member?.facilityId?.facilityName|| member.facility|| member?.practice || "-"}</td>
                <td>{member?.providerTypeId?.name ||member.providerType  ||"-"}</td>
                <td>{member?.facilityExternalTeamId?.fullName ||member.teamMember || "-"}</td>
                <td>{new Date(member?.fromDate).toLocaleDateString()}</td>
                <td>{new Date(member?.toDate).toLocaleDateString()}</td>
                <td>
                  <button
                    className="check-eligibility_insuranceDetails"
                    onClick={() => handleEdit(member)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleRemove(member._id)}
                    style={{ marginLeft: 10 }}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                No Team Member found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {ismodal && (
        <>
          <div className="modal_twooo" id="add-member-modal">
            <h2>{selectedMember ? "Edit Team Member" : "Add Team Member"}</h2>
            {!selectedMember && (
              <div className="tab-buttons_twoooo">
                <button
                  className={activeTab === "internal" ? "active" : ""}
                  onClick={() => handleTabClick("internal")}
                >
                  Internal
                </button>
                <button
                  className={activeTab === "external" ? "active" : ""}
                  onClick={() => handleTabClick("external")}
                >
                  External
                </button>
                <button
                  className={activeTab === "facility" ? "active" : ""}
                  onClick={() => handleTabClick("facility")}
                >
                  Facility
                </button>
              </div>
            )}

            {activeTab === "internal" && (
              <Clientinternal
                info={info}
                Fetchteammember={Fetchteammember}
                closeModal={closeModal}
                selectedMember={selectedMember}
              />
            )}

            {activeTab === "external" && (
              <ClientExternal
                info={info}
                Fetchteammember={Fetchteammember}
                closeModal={closeModal}
                selectedMember={selectedMember}
              />
            )}

            {activeTab === "facility" && (
              <ClientFacility
                info={info}
                Fetchteammember={Fetchteammember}
                closeModal={closeModal}
                selectedMember={selectedMember}
              />
            )}
          </div>
        </>
      )}

      <div className="overlay" id="overlay" onClick={closeModal}></div>
    </div>
  );
};

export default CareTeam;
