import React, { useState } from "react";
import AddContract from "../AddContract";
import AddQuestion from "../AddQuestion";
import AddCategory from "../AddCategory";
import AddNewQuestionnaire from "../AddNewQuestionnaire";

const MeasurementManagement = () => {
  const [activeTab, setActiveTab] = useState("AddCategory");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div
      className="dashboard-cards"
      style={{ paddingLeft: 30, paddingRight: 30, flexDirection: "column" }}
    >
     
      <div
        className="dashboard-cards"
        style={{
          // paddingTop: 50,
          flexDirection: "column",
          paddingBottom: 0,
        }}
      >
        {activeTab === "AddCategory" && <AddCategory />}
        {activeTab === "AddQuestion" && <AddQuestion  />}
        {activeTab === "AddNewQuestionnaire" && <AddNewQuestionnaire  />}
      </div>
    </div>
  );
};

export default MeasurementManagement;
