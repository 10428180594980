import { useEffect, useState } from "react";
import { format } from "date-fns";
import { categorizeScore, customColor, severityColor } from "../../../utils/main";

const MentalHealthMetricsCard = ({ category,index, categories }) => {
  // const [categories, setCategories] = useState(categories);
  const [preColor, setPreColor] = useState("");
  const isStart= false
  
  useEffect(() => {
    if (categories) {
      const data = categories.filter((cate) => cate?.isPreData === false);
      if (data.length > 0) {
        data.some((cate) =>
          cate.scoringRange.ranges.some((element) => {
            if (
              category?.score >= Number(element?.rangeStart) &&
              category?.score < Number(element?.rangeEnd)
            ) {
              setPreColor(element.color);
              return true; 
            }
            return false;
          })
        );
      }
    }
  }, [categories, category]);

  const today = format(new Date(), "yyyy-MM-dd");

  const description = categorizeScore(category);
  const csColor = customColor(category) || "#000"; 
  const bgColor = preColor || severityColor(description); 

  return (
    <>
      {isStart ? (
        <div
          key={category?.abbreviation}
          className={`p-2 rounded-xl text-sm text-primaryBlue text-center shadow border border-primaryGray ${
            csColor ? csColor : bgColor
          }`}
          aria-label={`Score card for ${category?.abbreviation}`}
        >
          <p>{category?.score}</p>
          <p>{category?.abbreviation}</p>
          <p>{today}</p>
          <p>{category?.description || description}</p>
        </div>
      ) : (
        <div
          key={category?.abbreviation}
          className={`p-2 rounded-xl text-sm text-primaryBlue text-center shadow border border-primaryGray ${
            preColor ? preColor : bgColor
          }`}
          aria-label={`Score card for ${category?.abbreviation}`}
        >
          <p>{category?.score}</p>
          <p>{category?.abbreviation}</p>
          <p>{today}</p>
          <p>{category?.description || description}</p>
        </div>
      )}
    </>
  );
};

export default MentalHealthMetricsCard;