import React, { useEffect, useRef, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import {
  DropDownManuCustom,
  DropDownManuCustomMulti,
} from "../../Components/DropDownButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ShowToast } from "../../../helpers/ToastService";
import { clearEmptyData } from "../../../helpers/helper";
import {
  Clinicrequestlist,
  createAgencyPatient,
  GetAgencyBooking,
  GetCredentials,
  GetInsurance,
  GetPaymentSources,
  PatientSignup,
  uploadImage,
} from "../../../api/helper";
import {
  setHeaderToken,
  setLoginType,
  setUserDetails,
} from "../../../redux/cookiesReducer";
import { Store } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const serviceTypedata = [
  { _id: "Pediatrics", name: "Pediatrics" },
  { _id: "Dermatology", name: "Dermatology" },
  { _id: "Neurology", name: "Neurology" },
  { _id: "Cardiology", name: "Cardiology" },
  { _id: "Gynecology", name: "Gynecology" },
];
// const paymentSource = [
//   { _id: "Insurance", name: "Insurance" },
//   { _id: "Credit Card", name: "Credit Card" },
//   { _id: "Debit Card", name: "Debit Card" },
//   { _id: "Bank Transfer", name: "Bank Transfer" },
//   { _id: "Cash", name: "Cash" },
//   { _id: "PayPal", name: "PayPal" },
// ];

const validationSchema = Yup.object().shape({
  // TreatmentTypesId: Yup.string().required("Service Type source is required"),
  TreatmentTypesId: Yup.array()
    .of(Yup.string().required("A Service Type  must be selected"))
    .min(1, "At least one Service Type  is required")
    .required("Service Type  is required"),

  PaymentSourcesId: Yup.string().required("Payment source is required"),

  agencyId: Yup.string().required("Associated With is required"),
  password: Yup.string()
    .required("Password is required")
    .min(12, "Password must be at least 12 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*_,]/,
      "Password must contain at least one special character (!, @, #, $, %, ^, &, *, _)"
    )
    .notOneOf(
      ["password", "12345", "qwerty"],
      "Password is too common or easy to guess"
    )
    .test(
      "no-repeated-characters",
      "Password should not contain repeated characters like 'aaaa' or '1111'",
      (value) => {
        return !/(.)\1{3,}/.test(value);
      }
    )
    .test(
      "no-simple-patterns",
      "Password should not contain common patterns like 'abcd' or '1234'",
      (value) => {
        const patterns = ["abcd", "1234", "qwerty"];
        return !patterns.some((pattern) => value.includes(pattern));
      }
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  policyNo: Yup.string().when("PaymentSourcesId", {
    is: "Insurance",
    then: (schema) => schema.required("Policy/Member Number is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  groupNumber: Yup.string().when("PaymentSourcesId", {
    is: "Insurance",
    then: (schema) => schema.required("Group Number is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  subscriptionName: Yup.string().when("PaymentSourcesId", {
    is: "Insurance",
    then: (schema) => schema.required("Subscription Name is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  // insuranceId:Yup.string().required("Insurance is required")
  // .when("PaymentSourcesId", {
  //   is: "Insurance",
  //   then: (schema) => schema.required("Insurance is required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  insuranceId: Yup.string().when("PaymentSourcesId", {
    is: "Insurance",
    then: (schema) => schema.required("Insurance is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
const PatientSignupTwo = ({ data, setShowPatientSignup }) => {
  useEffect(() => {
    console.log("Data received in DoctorSignupScreen===>>>>:", data);
  }, [data]);
  const [formSubmit, setFormSubmit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef(null);
  const [cliniclistdata, setcliniclistdata] = useState(null);
  const [isAgencySelected, setIsAgencySelected] = useState(false);
  const [Insurance, setInsurance] = useState([]);

  const [imagePreview, setImagePreview] = useState(null);
  const [imaeg, setImages] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [servicedata, setservicedata] = useState([]);
  const [paymentSourcedata, setpaymentSource] = useState([]);
  const [selectedPaymentSource, setSelectedPaymentSource] = useState("");
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const InsuranceType = async (id) => {
    // console.log(id, "id========");
    try {
      const response = await GetInsurance(`?id=${id}`);
      // console.log(response?.data?.data, "GetInsurance=====");
      let resData = response?.data?.data
      resData = resData?.map((e)=>e.adminInsurenceId ? {...e, name:e.adminInsurenceId.PayerName}:e)
      console.log(resData,'====>>>resData')
      setInsurance(resData);

    } catch (error) {
      console.log(error, "===e");
    }
  };
  const FetchDatalist = async (id) => {
    try {
      const response = await GetAgencyBooking(`?id=${id}`);
      // console.log(response?.data?.data, "GetAgencyBooking======");
      setservicedata(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const FetchPaymentSource = async (id) => {
    try {
      const response = await GetPaymentSources(`?id=${id}`);
      console.log(response?.data?.data, "GetPaymentSources======");
      setpaymentSource(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePaymentSourceChange = (selectedOption) => {
    console.log("Payment Source changed to:",selectedOption);
    setSelectedPaymentSource(selectedOption.name);
    setValue("PaymentSourcesId", selectedOption._id);
    clearErrors("PaymentSourcesId");
  };

  const Fetchclinic = async () => {
    try {
      const response = await Clinicrequestlist();
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.companyName} / ${e.uniqueId}`,
          ...e,
        };
      });
      setcliniclistdata(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Fetchclinic();
  }, []);
  const onSubmit = async (formData) => {
    const mergedData = { ...data, ...formData };

 
    
    function formatDOB(dateString) {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0]; 
        return formattedDate;
    }
    mergedData.DOB = formatDOB(mergedData.DOB);
    // console.log(mergedData,"datatppp----0-0djd---")
    // return
    if (selectedPaymentSource !== "Insurance") {
      delete mergedData.insurance;
      delete mergedData.groupNumber;
      delete mergedData.subscriptionName;
    }
    const savedata = clearEmptyData(mergedData);
    // console.log(savedata,"savedatasavedata=========")
    await handleSignUp(savedata);
  };
  const handleSignUp = async (data) => {
    formSubmit ? submitData(data) : setShowModal(true);
  };
  const handleCloseModal = async (data) => {
    if (data == "Upload") {
      if (!imagePreview) {
        return ShowToast("Please Select Image", "error");
      }
    }
    setFormSubmit(data);
    setShowModal(false);
  };
  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const submitData = async (data) => {
    if (imaeg) {
      data.image = imaeg;
    }
    try {
      console.log(data, "saveData==");
      // return
      const resposne = await PatientSignup(data);
      console.log(resposne?.data?.data,"respo-------")
      ShowToast(resposne.data.message, "success");
      Store.dispatch(setHeaderToken(resposne?.data?.data));
      dispatch(setUserDetails(resposne.data.data));

      if (data.agencyId) {
        let saveToData = {
          patientId: resposne?.data?.data?._id,
          agencyId: data.agencyId,
          status: "pending",
          ...data,
        };
        await createAgencyPatient(saveToData);
      }

      Store.dispatch(setLoginType("Patient"));
      navigate("/Dashboradpatient");
    } catch (error) {
      console.log(error);
    }
  };
  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };
  // const  selectInsurance =(selectedOptions)=>{
  //   let ids = selectedOptions?.map((e) => e?._id);
  //   setValue("insuranceId", ids, { shouldValidate: true });
  // }
  const selectInsurance = (selectedOption) => {
    console.log("Payment Source changed to:", selectedOption.name);

    setValue("PaymentSourcesId", selectedOption._id);
    clearErrors("PaymentSourcesId");
  };
  const Selectservice = (selectedOptions) => {
    let ids = selectedOptions.map((e) => e?._id);
    setValue("TreatmentTypesId", ids, { shouldValidate: true });
  };

  return (
    <div style={{ width: "100%", display: "flex", height: "100vh" }}>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#041C32",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../../assest/Images/mindwellnewlogo.png")}
          alt="MindWell Icon"
          style={{ height: "50%", width: "50%" }}
        />
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          Your trusted platform for mental health services.
        </div>
      </div>

      <div className="signup-container" style={{ padding: 50 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <h2>New Patient Registration</h2>
          <div className="Account_txttttt" style={{ textAlign: "left" }}>
            Account Information
          </div>
          <div className="input-row">
            <DropDownManuCustom
              label="Associated With"
              placeholder="Select option"
              options={cliniclistdata}
              name="agencyId"
              dropdownicon
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={(selectedOption) => {
                console.log(selectedOption);
                FetchPaymentSource(selectedOption?._id);
                // InsuranceType(true);
                InsuranceType(selectedOption?._id);
                FetchDatalist(selectedOption?._id);
             
              }}
            />
          </div>
          {errors.agencyId && (
            <div className="errorMessage">{errors.agencyId.message}</div>
          )}
          <div className="Account_txttttt" style={{ textAlign: "left" }}>
            Service Type(s)
          </div>
          <div className="input-row">
            <DropDownManuCustomMulti
              label="Select Service(s)"
              placeholder="Select option"
              options={servicedata}
              name="TreatmentTypesId"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={Selectservice}
            />
          </div>
          {errors.TreatmentTypesId && (
            <div className="errorMessage">{errors.TreatmentTypesId.message}</div>
          )}
          <div className="Account_txttttt" style={{ textAlign: "left" }}>
            Payment Type(s)
          </div>
          <div className="input-row">
            <DropDownManuCustom
              label="Payment Source"
              placeholder="Select option"
              options={paymentSourcedata}
              name="PaymentSourcesId"
              register={register}
              clearErrors={clearErrors}
              onSelect={handlePaymentSourceChange}
              dropdownicon
              id="_id"
            />
          </div>
          {errors.PaymentSourcesId && (
            <div className="errorMessage">
              {errors.PaymentSourcesId.message}
            </div>
          )}
          {selectedPaymentSource === "Insurance" && (
            <>
              <div className="Account_txttttt" style={{ textAlign: "left" }}>
                Insurance Details
              </div>
              <div className="form_div" style={{ marginTop: 10 }}>
                {/* <DropDownManuCustomMulti
              label="Insurance Name"
              placeholder="Select option"
              options={Insurance}
              name="insuranceId"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={selectInsurance}
            /> */}
                <DropDownManuCustom
                  label="Insurance Name"
                  placeholder="Select option"
                  options={Insurance}
                  name="insuranceId"
                  register={register}
                  clearErrors={clearErrors}
                  onSelect={selectInsurance}
                  dropdownicon
                  id="_id"
                />
                {errors.insuranceId && (
                  <div className="errorMessage">
                    {errors.insuranceId.message}
                  </div>
                )}
              </div>
              <div className="input-row">
                <Inputbox
                  label="Policy/Member Number"
                  type="text"
                  placeholder="Enter Policy/Member Number"
                  name="policyNo"
                  register={register}
                />
              </div>
              {errors.policyNo && (
                <div className="errorMessage">{errors.policyNo.message}</div>
              )}

              <div className="input-row">
                <Inputbox
                  label="Group Number"
                  type="text"
                  placeholder="Enter Group Number"
                  name="groupNumber"
                  register={register}
                />
              </div>
              {errors.groupNumber && (
                <div className="errorMessage">{errors.groupNumber.message}</div>
              )}

              <div className="input-row">
                <Inputbox
                  label="Subscriber Name"
                  type="text"
                  placeholder="Enter Subscriber Name"
                  name="subscriptionName"
                  register={register}
                />
              </div>
              {errors.subscriptionName && (
                <div className="errorMessage">
                  {errors.subscriptionName.message}
                </div>
              )}
            </>
          )}

          <div className="input-row">
            <div className="form_div">
              <Inputbox
                label="Password"
                type="password"
                placeholder="Enter Password"
                name="password"
                register={register}
                isPassword
              />
              {errors.password && (
                <div className="errorMessage">{errors.password.message}</div>
              )}
            </div>

            <div className="form_div">
              <Inputbox
                label="Confirm Password"
                type="password"
                placeholder="Enter Confirm Password"
                name="confirmPassword"
                register={register}
                isPassword
              />
              {errors.confirmPassword && (
                <div className="errorMessage">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>
          </div>

          <button className="login-button" type="submit">
            Sign up
          </button>
        </form>
      </div>
      {showModal && (
        <div className="modal_div">
          <div className="logout-modal" style={{ backgroundColor: "#D9D9D9" }}>
            <div
              className="logout_txt"
              style={{ color: "#011434", borderColor: "#011434" }}
            >
              Upload Profile Picture
            </div>
            <div
              onClick={clickImageData}
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ height: 150, width: 150, borderRadius: 80 }}
                />
              ) : (
                <img
                  src={require("../../assest//Images/Cameraprofile.png")}
                  alt="Upload"
                />
              )}

              <input
                type="file"
                ref={fileInputRef}
                onChange={imagePicker}
                style={{ display: "none" }}
              />
            </div>

            <button
              className="confirm-button"
              onClick={() => handleCloseModal("Upload")}
              style={{ width: 200, color: "white", backgroundColor: "#BC9C22" }}
            >
              Upload
            </button>
            <div
              style={{ marginTop: 20, cursor: "pointer" }}
              onClick={() => handleCloseModal("Skip")}
            >
              Skip for now
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientSignupTwo;
{
  /* <DropDownManuCustomMulti
          label="Select Service(s)"
          placeholder="Select option"
          options={serviceTypedata}
          name="serviceType"
          register={register}
          id="_id"
          clearErrors={clearErrors}
          onSelect={selectSpecialties}
        /> */
}
