import React, { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/cookiesReducer";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showloagout, setshowloagout] = useState(false);
  const menuItems = [
    {
      title: "Dashboard",
      icon: require("../assest/Images/dashboradimg.png"),
      path: "/dashboard",
      handler: () => navigate("/dashboard"),
    },
    {
      title: "Patients",
      icon: require("../assest/Images/patientimgicon.png"),
      path: "/patients",
      handler: () => navigate("/patients"),
    },
    {
      title: "Appointments",
      icon: require("../assest/Images/Appointments.png"),
      path: "/appointments",
      handler: () => navigate("/appointments"),
    },
    {
      title: "Financial",
      icon: require("../assest/Images/Financial.png"),
      path: "/financial",
      handler: () => navigate("/financial"),
    },
    {
      title: "Messages",
      icon: require("../assest/Images/messageimg.png"),
      path: "/messages",
      handler: () => navigate("/messages"),
    },
    {
      title: "Reports",
      icon: require("../assest/Images/reporticon.png"),
      path: "/report",
      handler: () => navigate("/report"),
    },
    {
      title: "My Profile",
      icon: require("../assest/Images/usericon.png"),
      path: "/profile",
      handler: () => navigate("/profile"),
  
    },
    {
      title: "Settings",
      icon: require("../assest/Images/SettingIcon.png"),
      path: "/setting",
      handler: () => navigate("/setting"),
    },
    {
      title: "Help & Support",
      icon: require("../assest/Images/Helpicon.png"),
      path: "/helpSupport",
      handler: () => navigate("/helpSupport"),
    
    },
  ];

  const handleLogoutYes = () => {
    dispatch(setUserDetails(null));
    navigate("/");
  };

  const handleLogout = () => {
    setshowloagout(true);
  };
  const handleClose = () => {
    setshowloagout(false);
  };
  return (
    <div
      style={{
        width: 300,
        paddingTop: 30,
        borderRightWidth: 1,
        borderRightColor: "rgba(1, 20, 52, 1)", 
        borderStyle: "solid",
        borderTopWidth: 0,
        borderBottomWidth: 0,
        height: "100vh",
        borderLeftWidth: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flexDirection: "row",
        display: "flex",
        alignItems: "center",
        // paddingBottom: 30,
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderBottomColor: "rgba(1, 20, 52, 1)",
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        marginBottom: 15,
        paddingLeft: 30,
        }}
      >
        <img
        style={{ height: 120, width: 120,}}
          src={require("../assest/Images/newmindwelllogo.png")}
          alt="Logo"
          className="logo"
        />

      </div>
      <div
        style={{
          flex: 1, // This allows the section to grow and fill available space
          overflowY: "auto", // Enables vertical scrolling
          paddingLeft: 30,
        }}
      >
        {menuItems.map((item, index) => {
          const isActive = location.pathname === item.path;
          return (
            <NavLink
              key={index}
              to={item.path}
              style={({ isActive }) => ({
                display: "flex",
                alignItems: "center",
                marginBottom: 15,
                marginTop: item.marginTop || 0,
                color: isActive ? "#BC9C22" : "inherit",
                cursor: "pointer",
                textDecoration: "none",
              })}
            >
              <img
                src={item.icon}
                style={{
                  height: 25,
                  width: 25,
                  marginRight: 10,
                  filter:
                    location.pathname === item.path
                      ? "brightness(0) saturate(100%) invert(55%) sepia(66%) saturate(2477%) hue-rotate(1deg) brightness(99%) contrast(101%)"
                      : "none",
                }}
              />
              <span
                style={{
                  color:
                    location.pathname === item.path ? "#BC9C22" : "inherit",
                  fontSize: 20,
                }}
              >
                {item.title}
              </span>
            </NavLink>
          );
        })}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 70,
            color: "rgba(239, 69, 47, 1)",
            cursor: "pointer",
            fontSize: 20
          }}
          onClick={handleLogout}
        >
          <img
            src={require("../assest/Images/logouticon.png")}
            style={{ height: 25, width: 25, marginRight: 10,  }}
          />
          Log Out
        </div>
      </div>
      {showloagout && (
        <Modal
          open={true}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="logout-modal">
              <div className="logout_txt">Logout</div>
              <div className="logout_sure" style={{ marginTop: 20 }}>
                Are you sure to logout
              </div>
              <div className="logout_sure" style={{ marginBottom: 20 }}>
                your account?
              </div>
              <div className="logout-buttons">
                <button className="cancel-button" onClick={handleClose}>
                  No
                </button>
                <button className="confirm-button" onClick={handleLogoutYes}>
                  Yes
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default Sidebar;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};
