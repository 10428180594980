export function categorizeScore(category) {
  if (!category?.score) return "Unspecified";

  switch (category.abbreviation) {
    case "PHQ":
      return category.score <= 4
        ? "Minimal"
        : category.score <= 9
        ? "Mild"
        : category.score <= 14
        ? "Moderate"
        : category.score <= 19
        ? "Moderately Severe"
        : "Severe";

    case "GAD":
      return category.score <= 4
        ? "Minimal"
        : category.score <= 9
        ? "Mild"
        : category.score <= 14
        ? "Moderate"
        : "Severe";

    case category?.scoringRange?.abbreviation:
      for (const element of category.scoringRange.ranges) {
        if (
          category.score >= Number(element.rangeStart) &&
          category.score < Number(element.rangeEnd)
        ) {
          return element.interpretation;
        }
      }
      break;

    default:
      return "Unspecified";
  }
}

export function customColor(category) {
  if (category?.scoringRange?.ranges) {
    for (const element of category.scoringRange.ranges) {
      if (
        category.score >= Number(element.rangeStart) &&
        category.score < Number(element.rangeEnd)
      ) {
        return element.color || "#000";
      }
    }
  }
  return "#000";
}

export function severityColor(severity) {
  const severityColors = {
    Minimal: "#D1FAE5",
    "Good Quality": "#D1FAE5",
    Normal: "#D1FAE5",
    "Low Risk": "#D1FAE5",
    Low: "#D1FAE5",
    "No Problems Reported": "#D1FAE5",

    Mild: "#FEF9C3", 
    "Moderate Disturbance": "#FEF9C3",

    Moderate: "#FDE68A", 
    "Moderate Risk": "#FDE68A",
    "Mild Impairment": "#FDE68A",

    "Moderately Severe": "#FEE2E2",
    Substantial: "#FEE2E2",
    "High Risk": "#FEE2E2",
    Severe: "#FEE2E2",
    "Poor Quality": "#FEE2E2",
    "Severe Impairment": "#FEE2E2",

    default: "#F3F4F6", 
  };

  return severityColors[severity] || severityColors.default;
}
  export function interpretCompositeScore(score) {
    if (score <= 20) return "Overall minimal concern.";
    if (score <= 40) return "Mild overall concerns.";
    if (score <= 60) return "Moderate overall concerns.";
    if (score <= 80) return "Moderately severe overall concerns.";
    return "Severe overall concerns, consider immediate follow-up.";
  }