import React, { useEffect, useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import { useLocation } from "react-router-dom";
import Clientinfomartion from "./Patientinfoeditscreens/Clientinfomartion";
import Addresses from "./Patientinfoeditscreens/Addresses";
import ClientTeam from "./Patientinfoeditscreens/ClientTeam";
import Consents from "./Patientinfoeditscreens/Consents";
import ClientHistory from "./Patientinfoeditscreens/ClientHistory";
import ClientIdentification from "./Patientinfoeditscreens/ClientIdentification";
import EmergencyContacts from "./Patientinfoeditscreens/EmergencyContacts";
import ClientPayment from "./Patientinfoeditscreens/ClientPayment";
import Notification from "./Patientinfoeditscreens/Notification";
import { Getagencypatientdetails } from "../../../api/helper";
import AddAllergy from "./AddProblemInformationpopups/AddAllergy";
import PatientDocuments from "./AddProblemInformationpopups/PatientDocuments";
import AddLabOrder from "./AddProblemInformationpopups/AddLabOrder";
import NotesTabScreen from "./Patientinfoeditscreens/NotesTabScreen";
import MeasuresTabScreen from "./Patientinfoeditscreens/MeasuresTabScreen";
import ProblemsScreen from "./Patientinfoeditscreens/ProblemsScreen";
import Medicationsagency from "./AddProblemInformationpopups/Medicationsagency";
import Vitals from "./AddProblemInformationpopups/Vitals";
import { setPatinetDetails } from "../../../redux/cookiesReducer";
import { useDispatch } from "react-redux";

const ClientEditbyagency = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  const [info, setInfo] = useState(location.state.data);
  const [activeTab, setActiveTab] = useState("info");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getDteails = async () => {
    const response = await Getagencypatientdetails(info._id);
    setInfo(response.data.data);
    dispatch(setPatinetDetails(response.data.data));
  };
  useEffect(() => {
    getDteails();
  }, [location.state.data]);
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="doctor-details-container">
            <div className="Doctor_Details_txtt">Patient Details</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: 20,
              }}
            >
              <div className="doctor-header" style={{ width: "90%" }}>
                <img
                  className="doctor-image"
                  src={
                    info && info?.patientId?.image
                      ? info?.image
                      : "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                  }
                  alt="Doctor"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "90%",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        textAlign: "left",
                        fontWeight: "900",
                        fontSize: 30,
                      }}
                    >
                      {info &&
                        info?.patientId?.firstName +
                        " " +
                        info?.patientId?.lastName}
                    </h3>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>Client ID :</div>
                      <div style={{ marginLeft: 5 }}>
                        {info?.patientId?.uniqueId}
                      </div>
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>Gender :</div>
                      <div style={{ marginLeft: 5 }}>
                        {info?.patientId?.gender}
                      </div>
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>
                        Emergency Contact : {info?.EmergencyContactNumber}
                      </div>
                      {/* <div style={{ marginLeft: 5 }}>
                        {info?.EmergencyContacts?.PhoneNumber}
                      </div> */}
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>Address :</div>
                      <div style={{ marginLeft: 5 }}>
                        {info?.patientId?.address?.street +
                          "," +
                          info?.patientId?.address?.city +
                          "," +
                          info?.patientId?.address?.state +
                          "," +
                          info?.patientId?.address?.country}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>Dob | Age :</div>
                      <div style={{ marginLeft: 5 }}>
                        {(() => {
                          const dobString = info?.DOB || "";
                          if (!dobString) return "";
                          const dobDate = new Date(dobString.split("T")[0]);
                          const today = new Date();
                          let age = today.getFullYear() - dobDate.getFullYear();
                          const monthDiff =
                            today.getMonth() - dobDate.getMonth();
                          const dayDiff = today.getDate() - dobDate.getDate();
                          if (
                            monthDiff < 0 ||
                            (monthDiff === 0 && dayDiff < 0)
                          ) {
                            age--;
                          }
                          return `${dobDate.toISOString().split("T")[0]
                            } | ${age} years`;
                        })()}
                      </div>
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>Primary Conatct:</div>
                      <div style={{ marginLeft: 5 }}>
                        {info?.patientId?.phone}
                      </div>
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>Payment Method :</div>
                      <div style={{ marginLeft: 5 }}>
                        {info?.PaymentSourcesId?.name}
                      </div>
                    </div>
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ fontWeight: "700" }}>Email Address :</div>
                      <div style={{ marginLeft: 5 }}>
                        {info?.patientId?.email || info?.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabs-container__two">
              <button
                className={`tab-button__two ${activeTab === "info" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("info")}
              >
                Information
              </button>
              <button
                className={`tab-button__two ${activeTab === "Addresses" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Addresses")}
              >
                Addresses
              </button>
              <button
                className={`tab-button__two ${activeTab === "Payment" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Payment")}
              >
                Payment
              </button>
              <button
                className={`tab-button__two ${activeTab === "Notification" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Notification")}
              >
                Notification
              </button>
              <button
                className={`tab-button__two ${activeTab === "EmergencyContacts" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("EmergencyContacts")}
              >
                Emergency Contacts
              </button>
              <button
                className={`tab-button__two ${activeTab === "Identification" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Identification")}
              >
                Identification
              </button>
              <button
                className={`tab-button__two ${activeTab === "History" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("History")}
              >
                History
              </button>
              <button
                className={`tab-button__two ${activeTab === "Consents" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Consents")}
              >
                Consents
              </button>
              <button
                className={`tab-button__two ${activeTab === "Team" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Team")}
              >
                Team
              </button>
              <button
                className={`tab-button__two ${activeTab === "AddAllergy" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("AddAllergy")}
              >
                Allergy
              </button>
              <button
                className={`tab-button__two ${activeTab === "PatientDocuments" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("PatientDocuments")}
              >
                Patient Documents
              </button>
              <button
                className={`tab-button__two ${activeTab === "AddLabOrder" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("AddLabOrder")}
              >
                Lab Order
              </button>
              <button
                className={`tab-button__two ${activeTab === "Medications" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Medications")}
              >
                Medications
              </button>
              <button
                className={`tab-button__two ${activeTab === "Notes" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Notes")}
              >
                Notes
              </button>
              <button
                className={`tab-button__two ${activeTab === "MeasuresTabScreen" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("MeasuresTabScreen")}
              >
                Measures
              </button>
              {/*  */}
              <button
                className={`tab-button__two ${activeTab === "Problems" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Problems")}
              >
                Problems
              </button>
              <button
                className={`tab-button__two ${activeTab === "Vitals" ? "active-tab__two" : ""
                  }`}
                onClick={() => handleTabClick("Vitals")}
              >
                Vitals
              </button>
            </div>
            {activeTab === "info" && (
              <Clientinfomartion info={info} getDteails={getDteails} />
            )}
            {activeTab === "Addresses" && (
              <Addresses info={info} getDteails={getDteails} />
            )}
            {activeTab === "Payment" && (
              <ClientPayment info={info} getDteails={getDteails} />
            )}
            {activeTab === "Notification" && (
              <Notification info={info} getDteails={getDteails} />
            )}
            {activeTab === "EmergencyContacts" && (
              <EmergencyContacts info={info} getDteails={getDteails} />
            )}
            {activeTab === "Identification" && (
              <ClientIdentification info={info} getDteails={getDteails} />
            )}
            {activeTab === "History" && (
              <ClientHistory info={info} getDteails={getDteails} />
            )}
            {activeTab === "Consents" && (
              <Consents info={info} getDteails={getDteails} />
            )}
            {activeTab === "Team" && (
              <ClientTeam info={info} getDteails={getDteails} />
            )}
            {activeTab === "AddAllergy" && <AddAllergy info={info} />}
            {activeTab === "PatientDocuments" && (
              <PatientDocuments info={info} />
            )}
            {activeTab === "Notes" && <NotesTabScreen info={info} />}
            {activeTab === "MeasuresTabScreen" && (
              <MeasuresTabScreen info={info} />
            )}
            {activeTab === "Problems" && <ProblemsScreen info={info} />}
            {activeTab === "AddLabOrder" && <AddLabOrder info={info} />}
            {activeTab === "Medications" && <Medicationsagency info={info} />}
            {activeTab === "Vitals" && <Vitals info={info} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientEditbyagency;
