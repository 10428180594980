import React from 'react'
import './Billing.css'

function Billing() {
    return (
        <div className="container_NotesTabScreen" style={{ width: "100%" }}>
            <h1>Connect Stripe</h1>
            <div className="table-container">
                <div className="container">
                    <p className="description">
                        <strong>
                            EnnVital partners with Stripe for secure payments and financial services.
                        </strong>
                    </p>
                    <h2>Connect Your Stripe Account</h2>
                    <p className="description">
                        Click the button below to securely connect your Stripe account to our EHR
                        system.
                    </p>
                    <a
                        href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_RkRPgRUMiL40OVg4U43sUTnn44823jZG&scope=read_write"
                        className="button"
                    >
                        Connect with Stripe
                    </a>
                    <p className="description">
                        By connecting, you allow our EHR to process payments on your behalf.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Billing