// import React from 'react'

// function PatinetConsents({info}) {
//     return (
//         <div className="client-history">
//         <h2 className="client-history__title">PatinetConsents</h2>
//         <table className="client-history__table">
//           <thead>
//             <tr>
//               <th className="client-history__header">Consent Type	</th>
//               <th className="client-history__header">Status</th>
//               <th className="client-history__header">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td className="client-history__cell">Consent for Treatment	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//             <tr>
//               <td className="client-history__cell">HIPAA Consent	</td>
//               <td className="client-history__cell">
//               Pending
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//             <tr>
//               <td className="client-history__cell">Telehealth Consent	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>

//             <tr>
//               <td className="client-history__cell"> Consent for Release of Information 	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//             <tr>
//               <td className="client-history__cell">Emergency Treatment Consent	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//             <tr>
//               <td className="client-history__cell">Consent for SMS/Email communication	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//             <tr>
//               <td className="client-history__cell">Emergency Treatment Consent	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//             <tr>
//               <td className="client-history__cell">Medication Management Consent 	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//             <tr>
//               <td className="client-history__cell">Financial Responsibility Consent	</td>
//               <td className="client-history__cell">
//               Granted
//               </td>
//               <td className="client-history__cell client-history__actions">
//                 <a  className="client-history__link">View</a>
//                 <span> | </span>
//                 <a  className="client-history__link">Edit</a>
//               </td>
//             </tr>
//           </tbody>
//         </table>
//         <div className="client-history__buttons">
//           <button className="client-history__button client-history__button--cancel">
//             Cancel
//           </button>
//           <button className="client-history__button client-history__button--save">
//             Save Changes
//           </button>
//         </div>
//       </div>
//       )
// }

// export default PatinetConsents
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import moment from "moment/moment";
import { ShowToast } from "../../../../helpers/ToastService";
import {
  AddPatientSign,
  GetPatientSign,
  GetconsentManagement,
  UpdatePatientSign,
} from "../../../../api/helper";

const PatinetConsents = ({ info }) => {
  const [consentdata, setconsentdata] = useState([]);
  const [ismodal, setIsModal] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState(null);
  const [signedBy, setSignedBy] = useState("");
  const [checkingbox, setCheckingbox] = useState(false);
  const [signdata, setsigndata] = useState([]);
  const [signmodal, setsignmodal] = useState(false);
  const [selectedTitle, setselectedTitle] = useState("");
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const openModal = (item) => {
    console.log(item?._id);
    getSigned(item?._id);
    setSelectedConsent(item);
    setIsModal(true);
  };

  const closeModal = () => {
    setCheckingbox(false);
    setSignedBy("");
    setsigndata([]);
    setIsModal(false);
    setSelectedConsent(null);
  };
  // console.log(info?.agencyIds[0]?._id, "inf======");
  const FetchConsentManagement = async () => {
    try {
      const respo = await GetconsentManagement(info?.agencyIds[0]?._id);
      console.log(respo?.data?.data?.data, "dataget========");
      setconsentdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (info?.agencyIds?.[0]?._id) {
      FetchConsentManagement();
    }
  }, [info?.agencyIds]);

  // console.log(selectedConsent, "selectedConsent====");
  const dob = new Date(info?.patientId?.DOB);
  const day = String(dob.getDate()).padStart(2, "0");
  const month = String(dob.getMonth() + 1).padStart(2, "0");
  const year = dob.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const openSignModal = (item) => {
    const hasMatchingConsent = item?.informedConsent.find(
      (consent) =>
        consent.agencyId === info?.agencyIds[0]?._id &&
        consent.patientId === profiledata?._id
    );

    console.log(item, "item=====");
    setSelectedConsent(hasMatchingConsent);
    setselectedTitle(item);
    setsignmodal(true);
  };
  const closeSIgnModal = () => {
    setsignmodal(false);
    setCheckingbox(false);
    setSignedBy("");
    setsigndata([]);
    setselectedTitle("");
    setSelectedConsent(null);
  };
  const AddSignofpatient = async () => {
    if (!checkingbox) {
      ShowToast("Please check the consent box before signing.");
      return;
    }
    if (signedBy === "") {
      ShowToast("Please Add Sign");
      return;
    }

    const data = {
      consentManagementId: selectedConsent?.consentManagementId,
      agencyId: info?.agencyIds[0]?._id,
      patientId: profiledata?._id,
      signedDate: new Date().toISOString(),
      Signedby: signedBy,
      checkingbox: true,
    };
    console.log(data, "data======--d-d-d=");
    // return
    try {
      const respo = await UpdatePatientSign(data, selectedConsent?._id);
      console.log(respo?.data?.data, "respopost======");
      ShowToast(respo?.data?.message);
      closeSIgnModal();
      FetchConsentManagement();
    } catch (error) {
      console.log(error);
    }
  };
  const getSigned = async (id) => {
    try {
      const respo = await GetPatientSign(profiledata?._id, id);
      setsigndata(respo?.data?.data?.data);
      console.log(respo?.data?.data?.data, "getrespo-=====");
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(info?.agencyIds[0], "agencyIds====");
  // console.log(profiledata, "patientid=====");
  return (
    <>
      <div className="container_NotesTabScreen">
        <h1>PatinetConsents</h1>
        <table>
          <thead>
            <tr>
              <th>Consent Title</th>
              <th>Status</th>
              <th>Sign Status</th>
              <th>Actions</th>
            
            </tr>
          </thead>
          <tbody>
            {consentdata.length === 0 ? (
              <tr>
                <td colSpan="10">No data found</td>
              </tr>
            ) : (
              consentdata.map((item, index) => {
                const hasMatchingConsent = item?.informedConsent.find(
                  (consent) =>
                    consent.agencyId === info?.agencyIds[0]?._id &&
                    consent.patientId === profiledata?._id
                );
                // console.log(hasMatchingConsent, "hasMatchingConsent======");
                return (
                  <tr key={item._id || index}>
                    <td>{item.Title}</td>
                    <td
                      className={
                        item.status === "active"
                          ? "status-signed"
                          : "status-sent"
                      }
                    >
                      {item.status}
                    </td>
                    <td>
                      {hasMatchingConsent ? (
                        hasMatchingConsent.Signedby ? (
                          <span style={{}}>Signed</span>
                        ) : (
                          <span style={{}}>Not Signed</span>
                        )
                      ) : (
                        <span style={{}}>Not Signed</span>
                      )}
                    </td>
                    <td>
                      <button
                        style={{
                          width: 100,
                        }}
                        className="view-btn"
                        onClick={() => openModal(item)}
                      >
                        View
                      </button>
                      {item.status === "active" && (
                        <button
                          className="download-btn"
                          style={{ marginLeft: 10 }}
                        >
                          Download PDF
                        </button>
                      )}
                      {hasMatchingConsent && !hasMatchingConsent.Signedby && (
                        <button
                          style={{ marginLeft: 10, backgroundColor: "green" }}
                          className="save-button_Vitals"
                          onClick={() => {
                            openSignModal(item);
                          }}
                        >
                          View Signature Request
                        </button>
                      )}
                    </td>
                  
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {ismodal && selectedConsent && (
          // <div className="modal_PatientDocuments">
          //   <div>
          // <div
          //   style={{
          //     fontSize: "20px",
          //     cursor: "pointer",
          //     width: 50,
          //     position: "absolute",
          //     right: 18,
          //     top: 27,
          //   }}
          // >
          //   <span className="close" onClick={closeModal}>
          //     &times;
          //   </span>
          // </div>
          //     <h3 style={{ textAlign: "left", marginTop: 20 }}>
          //       {selectedConsent.Title}
          //     </h3>
          //     <p style={{ textAlign: "left" }}>
          //       <strong>Category:</strong> {selectedConsent.Category}
          //     </p>

          //     <p style={{ textAlign: "left" }}>
          //       <strong>Client Name:</strong>{" "}
          //       {info?.patientId?.firstName + " " + info.patientId?.lastName}
          //     </p>
          //     <p style={{ textAlign: "left" }}>
          //       <strong>Agency Name:</strong>{" "}
          // {selectedConsent.agencyId?.firstName +
          //   " " +
          //   selectedConsent.agencyId?.lastName}
          //     </p>
          //     <p style={{ textAlign: "left" }}>
          //       <strong>Signed Date:</strong> {selectedConsent.SignedDate}
          //     </p>
          //     <p style={{ textAlign: "left" }}>
          //       <strong>Consent Details:</strong>{" "}
          // <span
          //   dangerouslySetInnerHTML={{ __html: selectedConsent.Content }}
          // />
          //     </p>
          //     <p style={{ textAlign: "left" }}>
          //       <strong>Signature:</strong> {selectedConsent.Signature}
          //     </p>
          //   </div>
          // </div>
          <div className="modal_Consent">
            {isLoading && (
              <div className="modal_Consent_loader">
                {/* <div className="modal-content"> */}
                <div className="spinner"></div>
                {/* </div> */}
              </div>
            )}

            <div
              style={{
                fontSize: "20px",
                cursor: "pointer",
                width: 50,
                position: "absolute",
                right: 1,
                top: 2,
              }}
            >
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            </div>
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h1>Informed Consent for Treatment</h1>
              <p>
                <strong>Category:</strong> {selectedConsent.Title}
              </p>
              <p>
                <strong>Consent Type:</strong> {selectedConsent.Category}
              </p>
              <p>
                <strong>Client Name:</strong>{" "}
                {info?.firstName + " " + info?.lastName}
              </p>
              <p>
                <strong>Agency Name:</strong>{" "}
                {selectedConsent.agencyId?.firstName +
                  " " +
                  selectedConsent.agencyId?.lastName}
              </p>
              <p>
                <strong>Signed Date:</strong> <span id="signedDate" />
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>Consent Details:</strong>{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: selectedConsent.Content }}
                />
              </p>
              {/* <label className="checkbox_Consent">
                <input
                  type="checkbox"
                  checked={
                    signdata.length > 0 && signdata[0].checkingbox
                      ? true
                      : checkingbox
                  }
                  onChange={(e) => setCheckingbox(e.target.checked)}
                  disabled={true}
                  // disabled={signdata.length > 0 && signdata[0].checkingbox}
                />{" "}
                By checking this box, I agree to use electronic records and
                signatures and acknowledge that I have read the related consumer
                disclosure.
              </label> */}
              <div className="signature-section">
                <h2 style={{ textAlign: "left" }}>Signature</h2>

                <p style={{ textAlign: "left" }}>
                  {/* {signdata.length > 0 && !signdata[0].Signedby ? (
                    <input
                      type="text"
                      value={signedBy}
                      onChange={(e) => setSignedBy(e.target.value)}
                    />
                  ) : null} */}

                  {signdata.length > 0 && signdata[0].Signedby ? (
                    <>
                      <strong>Signed by:</strong>
                      <span>{signdata[0].Signedby}</span>
                    </>
                  ) : null}

                  {/* <div
                    className="modal-footer_Vitals"
                    style={{ display: "flex", marginTop: -10 }}
                  >
                    {signdata.length > 0 && !signdata[0].Signedby ? (
                      <button
                        style={{ alignSelf: "flex-start" }}
                        className="save-button_Vitals"
                        onClick={AddSignofpatient}
                      >
                        Sign
                      </button>
                    ) : null}
                  </div> */}
                </p>

                {signdata.length > 0 && signdata[0].Signedby ? (
                  <p style={{ textAlign: "left" }}>
                    <strong>Date and Time:</strong>{" "}
                    <span>
                      {signdata[0].signedDate
                        ? moment(signdata[0].signedDate).format(
                            "DD-MM-YYYY / hh:mm A"
                          )
                        : "Date not available"}
                    </span>
                  </p>
                ) : null}

                <p style={{ textAlign: "left" }}>
                  <strong>Patient Name:</strong>{" "}
                  {profiledata?.firstName + " " + profiledata?.lastName}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong>Patient DOB:</strong> {formattedDate}
                </p>
              </div>
              <button className="button_Consent" onclick="window.print()">
                Print Consent
              </button>
            </div>
          </div>
        )}
      </div>
      {signmodal && (
        <div className="modal_Consent">
          <div className="modal-content__two">
            <button className="close_consent_modal" onClick={closeSIgnModal}>
              ×
            </button>
            <h3 style={{ textAlign: "left" }}>{selectedTitle?.Title}</h3>
            <p style={{ textAlign: "left" }}>
              <strong>Agency Name:</strong> {info?.agencyIds[0]?.companyName}
            </p>
            <p style={{ textAlign: "left" }}>
              <strong>Patient Name:</strong>{" "}
              {profiledata?.firstName + " " + profiledata?.lastName}
            </p>
            <p style={{ textAlign: "left" }}>
              <strong>Provider Name:</strong>{" "}
              {info?.agencyIds[0]?.firstName +
                " " +
                info?.agencyIds[0]?.lastName}
            </p>
            <hr />

            <div className="consent-checkbox">
              <label>
                <input
                  type="checkbox"
                  style={{ marginRight: 10 }}
                  checked={checkingbox}
                  onChange={() => setCheckingbox(!checkingbox)}
                />
                By checking this box, I agree to use electronic records and
                signatures and I acknowledge that I have read the related
                consumer disclosure.
              </label>
            </div>
            <div className="signature-block">
              <label className="signature-label" htmlFor="sig-officePolicies">
                Signature
              </label>
              <input
                type="text"
                value={signedBy}
                onChange={(e) => setSignedBy(e.target.value)}
                placeholder="Type your full name to sign..."
              />
              <button
                className="sign-btn"
                onClick={() => {
                  AddSignofpatient();
                }}
              >
                Sign Consent
              </button>
              {/* <div id="sigStatus-officePolicies" className="signature-status" /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PatinetConsents;
