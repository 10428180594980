import React, { useEffect, useState } from "react";
import { DropDownManuCustom } from "./DropDownButton";
import Inputbox, { Inputboxphone } from "./Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AddFacilityTwo,
  EditFacilityExternalTeam,
  FetchFacility,
  GetProviderType,
} from "../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../helpers/ToastService";
import InputMask from "react-input-mask";

const validationSchema = Yup.object().shape({
  facilityId: Yup.string().required("Facility Selection is required"),
  fullName: Yup.string().required("Full Name is required"),
  providerType: Yup.string().required("Provider Type is required"),
  NPINumber: Yup.string().matches(/^\d{10}$/, "NPI Number must be 10 digits"),
  status: Yup.string().required("Status is required"),
});
const FacilitySelectionmodal = ({ close, GetFacility, selectedData, editData, editView }) => {
  // console.log(selectedData,'===>selectedData')
  // console.log("editData===>>", editData)
  // console.log("editView===>>", editView)
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (editData) {
      reset(editData);
    }
  }, [editData, reset]);

  const [providertype, setprovidertype] = useState([]);
  const [paracticedata, setparacticedata] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [disInput , setDisInput ]= useState(editView=="View"?true:false)
  const pageSize = 1000;
  const [phone, setPhone] = useState(editData?.PhoneNumber);
  const [defId, setDefId] = useState(selectedData?._id || editData?.facilityId?._id)
  const Fetchproviderlist = async () => {
    try {
      const response = await GetProviderType(`?id=${profiledata?._id}`);
      // console.log(response?.data?.data, "GetProviderType======");
      setprovidertype(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const GetFacilitySelection = async (page = 1) => {
    try {
      const Respo = await FetchFacility(profiledata?._id, pageSize, page);
      // console.log(Respo?.data.data.facilities, "FetchFacility==========");

      const data = Respo?.data?.data?.facilities?.map((e) => {
        return {
          name: `${e.facilityName}`,
          ...e,
        };
      });
      setparacticedata(data || []);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetFacilitySelection();
    Fetchproviderlist();
  }, []);

  const onSubmit = async (data) => {
    data.agencyId = profiledata?._id;
    data.PhoneNumber = phone;

    // console.log("====>>",data)

    // if(editView=="Edit"){
    //   console.log('====',data,'====jjj')
    // }


    try {
      // const respo = await AddFacilityTwo(data);
      // console.log(respo?.data, "respo====two====");
      let respo 
       editView == "Edit" ? respo = await EditFacilityExternalTeam(data, editData._id) : respo = await AddFacilityTwo(data);

      ShowToast(respo?.data?.message);
      close();
      GetFacility();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="modal_PatientDocuments" id="addDocumentModal">
      <div
        style={{
          fontSize: "20px",
          cursor: "pointer",
          width: 50,
          position: "absolute",
          right: 18,
          top: 27,
        }}
        onClick={close}
      >
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
          style={{ height: 20, width: 20 }}
        />
      </div>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            alignItems: "center",
            justifyItems: "center",
            marginBottom: 20,
          }}
        >
          <h1 style={{ textAlign: "left" }}> {editView ? `${editView} Member` : "Add Member"} </h1>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <>
              <div className="form-group">
                <DropDownManuCustom
                  label="Facility Selection"
                  placeholder="Select option"
                  options={paracticedata}
                  name="facilityId"
                  dropdownicon
                  register={register}
                  id="_id"
                  disabled={disInput}
                  clearErrors={clearErrors}
                  divstyle={{ marginTop: -5 }}
                  defaultValue={defId}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.facilityId?.message}
                </p>
              </div>
              <Inputbox
                label="Full Name"
                type="text"
                placeholder="Enter Full Name"
                name="fullName"
                className="Custom_input"
                register={register}
                containerstyle={{ marginTop: -10 }}
                disabled={disInput}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.fullName?.message}
              </p>
              <div className="form-group">
                <DropDownManuCustom
                  label="Provider Type"
                  placeholder="Select option"
                  options={providertype}
                  name="providerType"
                  dropdownicon
                  register={register}
                  id="providerType"
                  clearErrors={clearErrors}
                  divstyle={{ marginTop: -10 }}
                  defaultValue={editData?.providerType}
                  disabled={disInput}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.providerType?.message}
                </p>
              </div>
              <Inputbox
                label="NPI Number"
                type="text"
                placeholder="Enter NPI Number"
                name="NPINumber"
                className="Custom_input"
                register={register}
                containerstyle={{ marginTop: -10 }}
                disabled={disInput}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.NPINumber?.message}
              </p>

              <Inputboxphone
                label="Phone Number"
                name="phone"
                value={phone}
                onChange={setPhone}
                containerstyle={{ marginTop: -10 }}
                disabled={disInput}
              />
              <div className="form-group">
                <DropDownManuCustom
                  label="Status"
                  placeholder="Select option"
                  options={[
                    { id: "1", name: "active" },
                    { id: "2", name: "Inactive" },
                    { id: "3", name: "pending" },
                  ]}
                  name="status"
                  dropdownicon
                  register={register}
                  id="status"
                  clearErrors={clearErrors}
                  divstyle={{ marginTop: -5 }}
                  defaultValue={editData?.status}
                  disabled={disInput}
                />
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.status?.message}
                </p>
              </div>
            </>

            {editView == "View" ? "" : <button
              type="submit"
              style={{
                marginBottom: 20,
                marginTop: 20,
                alignSelf: "center",
                width: "100%",
                height: "50px",
              }}
              className="Save_buttonnn"
            >
              {editView == "Edit" ? "Update" : "Save"}
            </button>}


          </div>
        </form>
      </div>
    </div>
  );
};

export default FacilitySelectionmodal;
