import React, { useEffect, useState } from "react";
import {
  FetchQuestionnaireList,
  Uploadcategoriescreate,
  UploadQuestionnaireList,
} from "../../../api/helper";
import { CATEGORIES_CREATE } from "../../../api/url";
import { ShowToast } from "../../../helpers/ToastService";

import { Fetchcategoriescreate, updatePreData } from "../../../api/helper";
import AddNewQuestionnaire from "./AddNewQuestionnaire";
import AddQuestion from "./AddQuestion";
import CategoryList from "./EnnVitalManagement/CategoryList";
import QuestionList from "./EnnVitalManagement/QuestionList";
import { useSelector } from "react-redux";
const AddCategory = () => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [form, setForm] = useState({
    id: "",
    label: "",
    description: "",
    name: "",
    abbreviation: "",
    agencyId:profiledata._id
  });
  const [ranges, setRanges] = useState([
    {
      id: 1,
      rangeStart: "",
      rangeEnd: "",
      interpretation: "",
      color: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [questionnaires, setquestionnaires] = useState([]);
  const [categories, setcategories] = useState([]);
  const [activeTab, setActiveTab] = useState("AddCategory");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [ismodal, setIsModal] = useState(false);
  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    setIsModal(false);
  };
  const GetFetchcategoriescreate = async () => {
    try {
      // const response = await Fetchcategoriescreate({agencyId:profiledata._id});
      
      const response = await Fetchcategoriescreate(`agencyId=${profiledata._id}`);
      setcategories(response?.data?.categories);
    } catch (error) {
      console.log(error);
    }
  };

  const GetQuestionnairelist = async () => {
    try {
      const response = await FetchQuestionnaireList();
      setquestionnaires(response?.data?.questionnaires);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (answers.length === 0) {
    //   ShowToast("Please ensure all answers are filled in.");
    //   return;
    // }
    // if (form.name === "" || form.abbreviation === "") {
    //   ShowToast("Please fill in all fields.");
    //   return;
    // }
    const data = {
      ...form,
      ranges:JSON.stringify(ranges)
    };
    setLoading(true);
    try {
      const response = await UploadQuestionnaireList(data);
      if (response) {
        ShowToast("Category created successfully");
        GetFetchcategoriescreate();
        CloseModal();
      }
    } catch (error) {
      CloseModal();
      console.error("Error creating category:", error);
      ShowToast(
        error?.response?.data?.message ||
        "Something went wrong ! Please check console"
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    GetQuestionnairelist();
    GetFetchcategoriescreate();
  }, []);
  return (
    <>
      {ismodal && (
        <div className="questionnaire-container">
          <div
            style={{
              fontSize: "20px",
              cursor: "pointer",
              width: 50,
              position: "absolute",
              right: 18,
              top: 27,
            }}
          >
            <span className="close" onClick={CloseModal}>
              &times;
            </span>
          </div>

          <div style={{ display: "flex", marginBottom: 20, width: "100%" }}>
            <div
              className={
                activeTab === "AddCategory"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("AddCategory")}
            >
              Add Category
            </div>
            <div
              className={
                activeTab === "AddQuestion"
                  ? "General_Settings"
                  : "Clinical_Settings"
              }
              onClick={() => handleTabClick("AddQuestion")}
            >
              Add Question
            </div>
          </div>
          {activeTab === "AddCategory" && (
            <div className="forscrolll">
              <h1 className="add-question-title">Add New Category</h1>
              <form onSubmit={handleSubmit} className="add-question-form">
                <div className="add-form-group">
                  <label className="add-form-label">Category Name</label>
                  <input
                    type="text"
                    name="categoryTitle"
                    className="add-form-input"
                    // placeholder="Category name"
                    onChange={(e) => setForm({
                      ...form,
                      name: e.target.value,
                      label: e.target.value,

                    })}
                  />
                </div>

                <div className="add-form-group">
                  <label className="add-form-label">
                    Category Abbreviation
                  </label>
                  <input
                    type="text"
                    name="categoryTitle"
                    className="add-form-input"
                    placeholder="PHQ, GAD, AUDIT, DAST, CSSRS, PTSD, SLEEP, MDQ, FMMSE, PRIME"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        abbreviation: e.target.value,
                        id: e.target.value,
                      })
                    }
                  />

                  {/* <select
                    onChange={(e) =>
                      setForm({
                        ...form,
                        abbreviation: e.target.value,
                        id: e.target.value,
                      })
                    }
                    className="add-form-select"
                  >
                    <option value="">Select Abbreviation</option>
                    {questionnaires.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.id}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium mb-2"
                  >
                    Description
                  </label>
                  <textarea
                    type="text"
                    required
                    name="description"
                    className="w-full p-2 border rounded"
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                  ></textarea>
                </div>
                <AddNewQuestionnaire
                  setForm={setForm}
                  form={form}
                  ranges={ranges}
                  setRanges={setRanges}
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="add-submit-btn"
                >
                  {loading ? "Creating..." : "Add Question"}
                </button>
              </form>
            </div>
          )}
          {activeTab === "AddQuestion" && <AddQuestion />}
        </div>
      )}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1> {activeTab === "AddCategory"? "Questions List":"Saved Categories"}</h1>
        <div
        className="tabs-container__two"
        style={{ justifyContent: "flex-start" }}
      >
        <button
          className={`tab-button__two ${
            activeTab === "AddCategory" ? "active-tab__two" : ""
          }`}
          onClick={() => handleTabClick("AddCategory")}
        >
          Question
        </button>
        <button
          className={`tab-button__two ${
            activeTab === "CatrgoryList" ? "active-tab__two" : ""
          }`}
          onClick={() => handleTabClick("CatrgoryList")}
        >
          Categories
        </button>
      </div>

        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add New Question
        </button>
      </div>

{activeTab === "AddCategory"  ?<QuestionList categories={categories} />:<CategoryList categories={categories} /> }
    </>
  );
};

export default AddCategory;
