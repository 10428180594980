import React, { useEffect, useState } from "react";
import {
  Fetchcategoriescreate,
  Patchaddquestion,
} from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
import { useSelector } from "react-redux";

const AddQuestion = ({setActiveTab}) => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [form, setForm] = useState({
    _id: "",
    question: "",
    type: "",
    options: "",
    agencyId:profiledata._id
  });
  const [questionnaires, setquestionnaires] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetQuestionnairelist = async () => {
    try {
      const response = await Fetchcategoriescreate();
      setquestionnaires(response?.data?.categories);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetQuestionnairelist();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form.question === "") {
      ShowToast("Please fill in all fields.");
      return;
    }
    setLoading(true);
    try {
      const response = await Patchaddquestion(form)
      if (response.status === 200) {
        ShowToast("Question added successfully","success");
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      ShowToast(
        error?.response?.data?.message ||
          "Something went wrong ! Please check console"
      );
      setLoading(false);
    }
  };
  return (
    <div className="form-container_AddQuestion">
      <h1 className="form-title_AddQuestion">Add New Question</h1>

      <form onSubmit={handleSubmit} className="form_AddQuestion">
        <div className="form-group_AddQuestion">
          <label className="form-label_AddQuestion">Question Category</label>
          <select
            className="form-input_AddQuestion"
            required
            onChange={(e) => setForm({ ...form, _id: e.target.value })}
          >
            <option value="">Select a question category</option>
            {questionnaires.map((category, index) => (
              <option key={index} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group_AddQuestion">
          <label className="form-label_AddQuestion">Question Text</label>
          <input
            onChange={(e) => setForm({ ...form, question: e.target.value })}
            type="text"
            required
            className="form-input_AddQuestion"
          />
        </div>

        <select
          onChange={(e) => setForm({ ...form, type: e.target.value })}
          className="form-input_AddQuestion"
        >
          <option value="multiple_choice">Multiple Choice</option>
          <option value="checkbox">Checkbox</option>
          <option value="slider">Slider</option>
          <option value="text">Text</option>
        </select>

      

        <button type="submit" className="submit-btn_AddQuestion">
          Add Question
        </button>
      </form>
    </div>
  );
};

export default AddQuestion;
