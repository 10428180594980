export const ScoreCircle = ({ score  ,size = 140,
  strokeWidth = 15, }) => {
    const radius = 45; 
    const circumference = 2 * Math.PI * radius;
    const redProgress = (score / 100) * circumference; 
    const greyProgress = circumference - redProgress; 
    const progress = ((100 - score) / 100) * circumference;
  
    return (
      <div className="flex flex-col items-center p-3">
      <div className="relative" style={{ width: size, height: size }}>
        {/* Background circle */}
        <svg className="w-full h-full -rotate-90">
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke="#E5E7EB"
            strokeWidth={strokeWidth}
          />
          {/* Progress circle */}
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill="none"
            stroke="#EF4444"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={progress}
            className="transition-all duration-500 ease-in-out"
          />
        </svg>
        {/* Center value */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex items-center text-4xl font-bold text-gray-800">
            {score}
            {/* <ArrowUpIcon className="ml-1 w-6 h-6 text-red-500" /> */}
          </div>
        </div>
      </div>
      {/* Info text */}
      <div className="mt-4 text-center text-black space-y-1">
        {/* <p>
          Last generated: <strong>{lastGenerated}</strong>
        </p>
        <p>
          Model Version: <strong>Ennvital 1.0</strong>
        </p> */}
      </div>
    </div>
    );
  };
  