import React, { useState, useEffect } from "react";
import { AddPaymentConditions, FetchCondition, UpdateCondition } from "../../../../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../../../helpers/ToastService";

const ConditionsScreen = ({ info, activeTab }) => {
  const [events, setEvents] = useState([{ id: 1, dateType: "", eventDate: "" }]);
  const [errors, setErrors] = useState([]);
  const [conditionId, setConditionId] = useState(null);
  const [conditions, setConditions] = useState({
    none: false,
    autoAccident: false,
    employment: false,
    otherAccident: false,
    Pregnancy: false,
    EPSDT: false,
    familyPlanning: false,
    Homebound: false,
    anEmergency: false,
  });

  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const addEvent = () => {
    setEvents([...events, { id: Date.now(), dateType: "", eventDate: "" }]);
  };

  const removeEvent = (id) => {
    setEvents(events.filter((event) => event.id !== id));
    setErrors(errors.filter((error) => error.id !== id));
  };

  const handleEventChange = (id, field, value) => {
    setEvents(
      events.map((event) => (event.id === id ? { ...event, [field]: value } : event))
    );
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setConditions((prev) => ({
      ...prev,
      [value]: checked,
    }));
  };

  const validateEvents = () => {
    let isValid = true;

    events.forEach((event) => {
      if (!event.dateType && !event.eventDate) {
        ShowToast("Please Select Date Type and Add Date");
        isValid = false;
      } else if (!event.dateType) {
        ShowToast("Please Select Date Type");
        isValid = false;
      } else if (!event.eventDate) {
        ShowToast("Please Add Date");
        isValid = false;
      }
    });

    return isValid;
  };

  const Postcondition = async () => {
    if (!validateEvents()) return;

    const data = {
      ...conditions,
      eventsCondition: events.map((event) => ({
        events: event.dateType,
        date: event.eventDate ? new Date(event.eventDate).toISOString() : null,
      })),
      patientId: info?.patientId?._id,
      agencyId: profiledata?._id,
    };
    try {
      let respo;
      if (conditionId) {
        // Update condition if data exists
        respo = await UpdateCondition(data, conditionId);
      } else {
        // Add new condition if no existing data
        respo = await AddPaymentConditions(data);
      }
console.log(respo,'respo=======')
      ShowToast(respo?.data?.message || "Condition data saved successfully");
      GetConditionData();
    }  catch (error) {
      console.log(error);
      ShowToast("Failed to save condition data");
    }
  };

  const GetConditionData = async () => {
    try {
      const respo = await FetchCondition(info?.patientId?._id, profiledata?._id);
      if (respo?.data?.data) {
        const fetchedData = respo.data.data;
        setConditionId(fetchedData._id);

        // Update conditions state
        setConditions({
          none: fetchedData.none,
          autoAccident: fetchedData.autoAccident,
          employment: fetchedData.employment,
          otherAccident: fetchedData.otherAccident,
          Pregnancy: fetchedData.Pregnancy,
          EPSDT: fetchedData.EPSDT,
          familyPlanning: fetchedData.familyPlanning,
          Homebound: fetchedData.Homebound,
          anEmergency: fetchedData.anEmergency,
        });

        // Update events state
        const formattedEvents = fetchedData.eventsCondition.map((event) => ({
          id: event._id,
          dateType: event.events,
          eventDate: event.date ? event.date.split("T")[0] : "",
        }));
        setEvents(formattedEvents);
      } else {
        setConditionId(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeTab === "conditions") {
      GetConditionData();
    }
  }, [activeTab]);

  return (
    <div className="tabcontent">
      <h3 className="title">Conditions of Admission</h3>

      <div className="checkbox-group">
        <label>The patient condition is related to[Box 10a-c]:</label>
        <div className="checkbox-options">
          {["none", "autoAccident", "employment", "otherAccident"].map((key) => (
            <label key={key}>
              <input
                type="checkbox"
                value={key}
                checked={conditions[key]}
                onChange={handleCheckboxChange}
              />{" "}
              {key.replace(/([A-Z])/g, " $1")}
            </label>
          ))}
        </div>
      </div>

      <div className="checkbox-group">
        <label>The service provided is related to:</label>
        <div className="checkbox-options">
          {["Pregnancy", "EPSDT", "familyPlanning", "Homebound", "anEmergency"].map((key) => (
            <label key={key}>
              <input
                type="checkbox"
                value={key}
                checked={conditions[key]}
                onChange={handleCheckboxChange}
              />{" "}
              {key.replace(/([A-Z])/g, " $1")}
            </label>
          ))}
        </div>
      </div>

      {/* Events Section */}
      <div className="events-container">
        <label>Add events related to condition:</label>
        {events.map((event) => (
          <div key={event.id} className="event-row">
            <select
              className="select-box"
              value={event.dateType}
              onChange={(e) => handleEventChange(event.id, "dateType", e.target.value)}
            >
              <option value="">Select date type</option>
              <option value="initial-treatment">Initial Treatment Date</option>
              <option value="onset-symptoms">Onset of Current Symptoms</option>
              <option value="similar-illness">Date of Same or Similar Illness</option>
              <option value="unable-work">Unable to Work in Current Occupation</option>
            </select>
            <input
              type="date"
              className="date-picker"
              value={event.eventDate}
              onChange={(e) => handleEventChange(event.id, "eventDate", e.target.value)}
              style={{marginBottom:-0.01}}
            />
            <button type="button" className="delete-btn_two" onClick={() => removeEvent(event.id)}>
              Delete
            </button>
          </div>
        ))}
        <button type="button" className="add-event" onClick={addEvent}>
          Add event
        </button>
      </div>

      <button className="submit-payment" onClick={Postcondition}>
      Save
      </button>
    </div>
  );
};

export default ConditionsScreen;
