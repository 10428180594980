export default function QuestionForms({ question, answers, value, onChange }) {
  return (
    <div className="question" aria-live="polite">
    <p className="mb-4 text-[#041c32] font-medium">{question}</p>
    <div className="flex flex-col space-y-2">
      {answers?.map((answer, index) => (
        <div key={index} className="relative">
          <input
            type="radio"
            id={`q${index}`}
            name="question"
            value={answer.value}
            checked={value === answer.value}
            onChange={() => onChange(answer.value)}
            className="hidden"
          />
          <label
            htmlFor={`q${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              minHeight: "50px",
              padding: "1rem",
              backgroundColor: value === answer.value ? "rgb(1 20 52)" : "rgb(249 250 251)",
              color: value === answer.value ? "white" : "black",
              border: `1px solid ${value === answer.value ? "rgb(1 20 52)" : "rgb(229 231 235)"}`,
              borderRadius: "8px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              textAlign: "center",
              fontWeight: "500",
            }}
            onMouseEnter={(e) => {
              if (value !== answer.value) e.target.style.backgroundColor = "rgb(243 244 246)";
            }}
            onMouseLeave={(e) => {
              if (value !== answer.value) e.target.style.backgroundColor = "rgb(249 250 251)";
            }}
          >
            {answer.name}
          </label>
        </div>
      ))}
    </div>
  </div>
  
  );
}
