import { categorizeScore } from "../../../../../utils/main";

export const categoryInfoData = (categories) => {
  let categoryInfo = [];
  categories.forEach((cat) => {
    const description = categorizeScore(cat);
    const catInfo = {
      score: cat.score,
      abbreviation: cat.abbreviation,
      description: description,
    };
    categoryInfo.push(catInfo);
  });
  return categoryInfo;
};
