
import React, { useState } from 'react';

import PatientSignupTwo from './PatientSignupTwo';
import PatientSignupfirst from './PatientSignupfirst';

const SignupPatient = () => {
  const [showDoctorSignup, setShowDoctorSignup] = useState(false);
  const [signupData, setSignupData] = useState(null);

  const handleOnSubmit = (data) => {
    setSignupData(data);          
    setShowDoctorSignup(true);     
    console.log(data, "Received data in SignUp");
  };

  return (
    <div>
      {!showDoctorSignup ? (
        <PatientSignupfirst onSubmitt={handleOnSubmit} />
      ) : (
        <PatientSignupTwo
         data={signupData} setShowDoctorSignup={setShowDoctorSignup}
          />
      )}
    </div>
  );
};

export default SignupPatient;
