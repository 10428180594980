import React, { useEffect, useState } from 'react'
import SendQuestionsnaireMain from './SendQuestionsnaire/SendQuestionsnaireMain'
import MentalHealthMetricsCard from '../../MentalHealthMetricsCard'
import { Fetchservays } from '../../../../../api/helper';
import { useSelector } from 'react-redux';

function MentalHealthMetricsMain({categoriesState,currentQuestion,categories}) {
    const [latestCategories, setLatestCategories] = useState(categories);
    const profiledata = useSelector((state) => state?.cookies?.userDetails);
    const patientDetails = useSelector((state) => state?.cookies?.patinetDetails)

    useEffect(()=>{
        const Getsurveys = async () => {
            try {
              const response = await Fetchservays(`agencyId=${profiledata._id}&patientId=${patientDetails._id}`);
              if (response?.data?.surveys) {
                const survery =
                  response?.data?.surveys[response?.data?.surveys?.length - 1];
                  setLatestCategories(survery?.categoryInfo);
              }
            } catch (error) {
              console.log(error);
            }
          };
          Getsurveys()
    },[])

    useEffect(() => {
        if (currentQuestion > 0) {
          setLatestCategories(categoriesState);
        }
      }, [currentQuestion]);


  return (
    <>
    <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 10,
          }}
        >
          <h3 className="section-title_two">KEY MENTAL HEALTH INDICATOR</h3>
          <SendQuestionsnaireMain />
        </div>
        <div className="divider"></div>
        <div className="custom_KEY_MENTAL_box_style">
          <div className="custom-grid">
            {!latestCategories ? (
              <p>Loading.....</p>
            ) : (
              latestCategories?.map((category, index) => (
                <MentalHealthMetricsCard category={category} key={index} categories={categories} />
              ))
            )}
          </div>
        </div>
    </>
  )
}

export default MentalHealthMetricsMain