import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Deleteinsurance,
  FetchEligibilityCheck,
  FetchEligibilityToken,
  GETinsurencedetails,
  Postinsurencedetails,
} from "../../../../../api/helper";
import { ShowToast } from "../../../../../helpers/ToastService";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const InsuranceDetails = ({ info }) => {
  const [relationship, setRelationship] = useState("self");
  const [insurancedata, setinsurancedata] = useState([]);
  const [modalopen, setmodalopen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  // console.log(insurancedata,"profiledata-----")

  const validationSchema = Yup.object().shape({
    PlanType: Yup.string().required("Plan Type is required"),
    PlanName: Yup.string().required("Plan Name is required"),
    PolicyNumber: Yup.string().required("Policy Number is required"),
    PhoneNumber: Yup.string().required("Phone Number is required"),

    GroupNumber: Yup.string()
      .matches(/^\d+$/, "Group Number should contain only numbers")
      .notRequired(),
    CopayAmount: Yup.string()
      .matches(/^\$?\d+(\.\d{2})?$/, "Invalid amount (e.g., $0.00 or 0.00)")
      .notRequired(),
    DeductibleAmount: Yup.string()
      .matches(/^\$?\d+(\.\d{2})?$/, "Invalid amount (e.g., $0.00 or 0.00)")
      .notRequired(),
    PatientRelationship: Yup.string()
      .required("Relationship is required")
      .notOneOf(["Select Option"], "Please select a valid relationship"),
    // insured_name: Yup.string().when('relationship', {
    //     is: (value) => value !== 'self' && value !== '',
    //     then: Yup.string().required('Field is required'),
    //     otherwise: Yup.string().notRequired(),
    //   }),
    // insured_id: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Insured ID is required"),
    // }),
    // ZipCode: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Zip Code is required"),
    // }),
    // StreetAddress: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Street Address is required"),
    // }),
    // Apt: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Apt is required"),
    // }),
    // City: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("City is required"),
    // }),
    // dob: Yup.date().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.date().required("DOB is required"),
    // }),
    // ssn: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("SSN is required"),
    // }),
    // gender: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Gender is required"),
    // }),
    // Telephone: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("Telephone is required"),
    // }),
    // state: Yup.string().when([], {
    //   is: () => relationship !== "self" && relationship !== "",
    //   then: Yup.string().required("State is required"),
    // }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    // console.log("Form Data:", data);
    data.agencyId = profiledata?._id;
    data.patientId = info?.patientId?._id;
    try {
      const response = await Postinsurencedetails(data);
      ShowToast(response?.data?.message);
      console.log(response?.data?.data);
      getinsurencedetails();
      setmodalopen(false);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const getinsurencedetails = async () => {
    try {
      const response = await GETinsurencedetails(profiledata?._id);
      // console.log(response?.data?.data?.data, "hello=====");
      setinsurancedata(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getinsurencedetails();
  }, []);
  const handleRemove = async (id) => {
    if (window.confirm("Are you sure you want to remove this insurance?")) {
      console.log("Removed insurance with ID:", id);
      try {
        const response = await Deleteinsurance(id);
        ShowToast(response?.data?.message);
        getinsurencedetails();
      } catch (error) {
        console.log(error);
      }
    }
  };
  // const getheadertoken = async () => {
  //   // console.log("hit------");
  //   const data = {
  //     client_id: "RrjZ0fpjL0DCX71YIupKPGxEM0LFm3Aw",
  //     client_secret: "2zXhOLbVnwh4YOFA",
  //     grant_type: "client_credentials",
  //   };
  //   try {
  //     const respo = await FetchEligibilityToken(data);
  //     console.log(respo?.data, "respo======");
  //   } catch (error) {
  //     console.log(error, "error======");
  //   }
  // };
  // const

  function formatDOB(dob) {
    if (!dob) return null;
    return moment(dob).format("YYYYMMDD");
  }

  function formatGender(gender) {
    if (!gender) return "M";
    return gender.toUpperCase() === "M" || gender.toUpperCase() === "F"
      ? gender.toUpperCase()
      : "M";
  }
  // console.log(profiledata,"profiledata====")
  const handleEligibilityCheck = async () => {
    console.log("Eligibility Check Started...");
    // const data = {
      // patientId: info?.patientId?._id,
      // agencyId: profiledata?._id,
    //   data: {
    //     controlNumber: "123456789",
    //     tradingPartnerServiceId: "CMSMED",
    //     provider: {
    //       organizationName:
    //         profiledata?.companyName,
    //       npi: profiledata?.NPI2,
    //       serviceProviderNumber: "54321",
    //       providerCode: "PC",
    //       referenceIdentification: "54321g",
    //     },
    //     subscriber: {
    //       memberId: info?.patientId?.uniqueId,
    //       firstName: info?.patientId?.firstName || "DefaultFirstName",
    //       lastName: info?.patientId?.lastName || "DefaultLastName",
    //       gender: formatGender(info?.gender || info?.patientId?.gender),
    //       dateOfBirth: formatDOB(info?.DOB || info?.patientId?.DOB),
    //       ssn: info?.SSN,
    //       idCard: "card123",
    //     },
    //     dependents: [
    //       {
    //         firstName: profiledata?.firstName,
    //         lastName: profiledata?.lastName,
    //         gender: "F",
    //         dateOfBirth: "18160421",
    //         groupNumber: "1111111111",
    //       },
    //     ],
    //     encounter: {
    //       beginningDateOfService: "20100101",
    //       endDateOfService: "20100102",
    //       serviceTypeCodes: ["98"],
    //     },
    //   },
    // };
const data ={
  patientId: info?.patientId?._id,
  agencyId: profiledata?._id,
  data :{
    "controlNumber":"123456789",
    "tradingPartnerServiceId": "CMSMED",
    "provider":
    {
      "organizationName": "provider_name",
      "npi": "0123456789",
      "serviceProviderNumber": "54321",
      "providerCode": "AD",
      "referenceIdentification": "54321g"
    },
    "subscriber": {
      "memberId": "0000000000",
      "firstName": "johnOne",
      "lastName": "doeOne",
      "gender": "M",
      "dateOfBirth": "18800102",
      "ssn": "555443333",
      "idCard": "card123"
    },
    "dependents": [
      {
        "firstName":"janeOne",
        "lastName":"doeone",
        "gender":"F",
        "dateOfBirth":"18160421",
        "groupNumber": "1111111111"
      }
    ],
    "encounter": {
      "beginningDateOfService": "20100101",
      "endDateOfService": "20100102",
      "serviceTypeCodes": [
        "98"
      ]
    }
  }
  
}
    console.log(data, "datat==-=-=-=-");
    try {
      const response = await FetchEligibilityCheck(data);
      console.log(response, "Eligibility Check Response======");

      if (response.data?.data?.data?.errors?.length > 0) {
        const errors = response.data.data.data.errors.map(
          (error) => error.description
        );
        setErrorMessages(errors);
        setModalVisible(true);
      } else {
        setErrorMessages([]);
        setModalVisible(false);
        alert("Your Form has been submitted, please wait for a while");
      }
    } catch (error) {
      console.log(error, "Eligibility Check Error======");
    }
  };
  return (
    <>
      <button
        className="add-button_insuranceDetails"
        onClick={() => setmodalopen(true)}
      >
        Add New Insurance
      </button>
      {modalopen && (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            position: "fixed",
            zIndex: 1000,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal_insuranceDetails">
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  cursor: "pointer",
                  width: 50,
                }}
                onClick={() => setmodalopen(false)}
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                  style={{ height: 20, width: 20 }}
                />
              </div>
            </div>
            <h2>Insurance Details</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row_insuranceDetails">
                <div className="form-column_insuranceDetails">
                  <label htmlFor="plan-type">Plan Type [Box 1]*</label>
                  <select id="plan-type" required="" {...register("PlanType")}>
                    <option value="">Select Option</option>
                    <option value="HMO">HMO</option>
                    <option value="PPO">PPO</option>
                    <option value="EPO">EPO</option>
                  </select>
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.PlanType?.message}
                  </p>
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="plan-name">Plan Name [Box 11c]*</label>
                  <select id="plan-name" required="" {...register("PlanName")}>
                    <option value="">Select Option</option>
                    <option value="Blue Cross">Blue Cross</option>
                    <option value="United Health">United Health</option>
                  </select>
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.PlanName?.message}
                  </p>
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="policy-number">Policy Number [Box 1a]*</label>
                  <input
                    type="text"
                    id="PolicyNumber"
                    placeholder="Enter Policy Number"
                    required=""
                    {...register("PolicyNumber")}
                  />
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.PolicyNumber?.message}
                  </p>
                </div>
              </div>
              <div className="form-row_insuranceDetails">
                <div className="form-column">
                  <label htmlFor="phone-number">Phone Number</label>
                  <input
                    type="text"
                    id="phone-number"
                    placeholder="(000) 000-0000"
                    {...register("PhoneNumber")}
                  />
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.PhoneNumber?.message}
                  </p>
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="group-number">Group Number [Box 11]</label>
                  <input
                    type="text"
                    id="group-number"
                    placeholder="Enter Group Number"
                    {...register("GroupNumber")}
                  />
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.GroupNumber?.message}
                  </p>
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="copay-amount">Copay Amount</label>
                  <input
                    type="text"
                    id="copay-amount"
                    placeholder="$0.00"
                    {...register("CopayAmount")}
                  />
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.CopayAmount?.message}
                  </p>
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="deductible-amount">Deductible Amount</label>
                  <input
                    type="text"
                    id="deductible-amount"
                    placeholder="$0.00"
                    {...register("DeductibleAmount")}
                  />
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.DeductibleAmount?.message}
                  </p>
                </div>
              </div>
              <div className="form-row_insuranceDetails">
                <div
                  className="form-column_insuranceDetails"
                  style={{ width: "100%", marginTop: 10 }}
                >
                  <label htmlFor="relationship">
                    Patient's Relationship [Box 6]
                  </label>
                  <select
                    id="relationship"
                    onchange="togglePolicyHolderInfo()"
                    required=""
                    style={{ width: "100%" }}
                    {...register("PatientRelationship")}
                    onChange={(e) => setRelationship(e.target.value)}
                  >
                    <option value="">Select Option</option>
                    <option value="Self">Self</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Child">Child</option>
                    <option value="Other">Other</option>
                  </select>
                  <p style={{ color: "red", textAlign: "left" }}>
                    {errors.PatientRelationship?.message}
                  </p>
                </div>
              </div>
              <div
                className="policy-holder_insuranceDetails"
                id="policy-holder-info"
                style={{
                  display:
                    relationship !== "Self" && relationship !== ""
                      ? "block"
                      : "none",
                }}
              >
                <h3>Policy Holder Information</h3>
                <div className="form-row_insuranceDetails">
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="insured-name">Insured Name [Box 4]</label>
                    <input
                      type="text"
                      id="insured-name"
                      placeholder="Full Name"
                      {...register("insured_name")}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.insured_name?.message}
                    </p>
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="insured-id">
                      Insured ID Number [Box 1a]
                    </label>
                    <input
                      type="text"
                      id="insured-id"
                      placeholder="ID Number"
                      {...register("insured_id")}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.insured_id?.message}
                    </p>
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="street-address">
                      Street Address [Box 7]
                    </label>
                    <input
                      type="text"
                      id="street-address"
                      placeholder="123 Main St"
                      {...register("StreetAddress")}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.StreetAddress?.message}
                    </p>
                  </div>
                </div>
                <div className="form-row_insuranceDetails">
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="city">City [Box 7]</label>
                    <input
                      type="text"
                      id="city"
                      placeholder="City"
                      {...register("City")}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.City?.message}
                    </p>
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="state">State [Box 7]</label>
                    <input
                      type="text"
                      id="state"
                      placeholder="State"
                      {...register("state")}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.state?.message}
                    </p>
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="zip-code">Zip Code [Box 7]</label>
                    <input
                      type="text"
                      id="zip-code"
                      placeholder={12345}
                      {...register("ZipCode")}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.ZipCode?.message}
                    </p>
                  </div>
                  <div className="form-column_insuranceDetails">
                    <label htmlFor="dob">Date of Birth [Box 3]</label>
                    <input type="date" id="dob" {...register("dob")} />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.dob?.message}
                    </p>
                  </div>
                </div>
              </div>
              <button type="submit">Save</button>
              <button className="check-eligibility">Check Eligibility</button>
            </form>
          </div>
        </div>
      )}

      <div class="insurance-list">
        <h2>Insurance List</h2>
        <table>
          <thead>
            <tr>
              <th>Plan Type</th>
              <th>Plan Name</th>
              <th>Policy Number</th>
              <th>Phone Number</th>
              <th>Group Number</th>
              <th>Copay</th>
              <th>Deductible</th>
              <th>Relationship</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody id="insurance-table-body">
            {insurancedata.map((item) => (
              <tr key={item._id}>
                <td>{item.PlanType}</td>
                <td>{item.PlanName}</td>
                <td>{item.PolicyNumber}</td>
                <td>{item.PhoneNumber}</td>
                <td>{item.GroupNumber}</td>
                <td>{item.CopayAmount}</td>
                <td>{item.DeductibleAmount}</td>
                <td>{item.PatientRelationship}</td>
                <td>
                  <button
                    className="check-eligibility_insuranceDetails"
                    onClick={handleEligibilityCheck}
                  >
                    Check Eligibility
                  </button>
                  <button
                    style={{ marginLeft: 10 }}
                    onClick={() => handleRemove(item._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modalVisible && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <h3>Errors</h3>
            <ul>
              {errorMessages.map((error, index) => (
                <li key={index} style={{ color: "red", textAlign: "left" }}>
                  {error}
                </li>
              ))}
            </ul>
            <button
              onClick={() => setModalVisible(false)}
              style={{ backgroundColor: "red", padding: 5, borderRadius: 10 }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};
const modalStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  // backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  textAlign: "center",
};
export default InsuranceDetails;
