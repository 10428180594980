import React from 'react'
import { Trash2 } from "lucide-react";

function QuestionList({categories}) {
  return (
   <>
    <div className="question-category-container">
        <h1 className="question-category-title">Question Category List</h1>
        <div className="question-category-button-container"></div>
        <ul className="question-category-list">
          {categories.map((item, index) => (
            <React.Fragment key={index}>
              <li className="question-category-list-item">
                {item.name}
                <Trash2 className="question-category-trash-icon" />
              </li>

              {item.questions && (
                <ul className="question-category-sublist">
                  {item.questions.map((question, index) => (
                    <li key={index} className="question-category-subitem">
                      {question}
                    </li>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
   </>
  )
}

export default QuestionList