import React from "react";
import { Plus, Trash2 } from "lucide-react";

const AddNewQuestionnaire = ({ setForm, form, ranges, setRanges }) => {
  const addNewRange = () => {
    setRanges([
      ...ranges,
      {
        id: ranges.length + 1,
        rangeStart: "",
        rangeEnd: "",
        interpretation: "",
      },
    ]);
  };

  const removeRange = (id) => {
    if (ranges.length === 1) return; // Prevent removing the last item
    setRanges(ranges.filter((range) => range.id !== id));
  };

  const updateRange = (id, field, value) => {
    setRanges(
      ranges.map((range) =>
        range.id === id ? { ...range, [field]: value } : range
      )
    );
  };
  return (
    <>
      <div>
        <label className="font-bold block mt-2.5 text-gray-700">
          Options (comma-separated)
        </label>
        <input
          onChange={(e) => setForm({ ...form, options: e.target.value })}
          type="text"
          placeholder="e.g., Never, Sometimes, Often"
          className="w-full p-2.5 mt-1 mb-4 rounded border border-gray-300 text-sm"
        />

        <label className="font-bold block mt-2.5 text-gray-700">
          Score Mapping (comma-separated)
        </label>
        <input
          onChange={(e) => setForm({ ...form, score: e.target.value })}
          type="text"
          placeholder="e.g., Never:0, Sometimes:1, Often:2"
          className="w-full p-2.5 mt-1 mb-4 rounded border border-gray-300 text-sm"
        />
      </div>
      <div id="scoringContainer" className="mt-6">
        <div
          style={{ fontWeight: 700, marginBottom: "16px", textAlign: "left" }}
        >
          Scoring Ranges
        </div>
        <div className="section">
          {ranges.map((range) => (
            <div
              key={range.id}
              style={{
               display:'flex',
              alignItems:'center',gap:10,
                width:'100%',
               
              }}
            >
              <div style={{ gridColumn: 'span 2 / span 2' }}>
                <label className="font-bold block mt-2.5 text-gray-700">
                  Range Start
                </label>
                <input
                  required
                  type="number"
                  value={range.rangeStart}
                  onChange={(e) =>
                    updateRange(range.id, "rangeStart", e.target.value)
                  }
                  placeholder="Enter start of range"
                  style={{
                    width: '100%',
                    padding: '10px',
                    marginTop: '4px',
                    marginBottom: '16px',
                    borderRadius: '4px',
                    border: '1px solid #D1D5DB',
                    fontSize: '14px'
                  }}                 />
              </div>
              <div style={{ gridColumn: 'span 2 / span 2' }}>
                <label className="font-bold block mt-2.5 text-gray-700">
                  Range End
                </label>
                <input
                  required
                  type="number"
                  value={range.rangeEnd}
                  onChange={(e) =>
                    updateRange(range.id, "rangeEnd", e.target.value)
                  }
                  placeholder="Enter end of range"
                  style={{
                    width: '100%',
                    padding: '10px',
                    marginTop: '4px',
                    marginBottom: '16px',
                    borderRadius: '4px',
                    border: '1px solid #D1D5DB',
                    fontSize: '14px'
                  }}                 />
              </div>
              <div className="col-span-4">
                <label className="font-bold block mt-2.5 text-gray-700">
                  Interpretation
                </label>
                <input
                  required
                  type="text"
                  value={range.interpretation}
                  onChange={(e) =>
                    updateRange(range.id, "interpretation", e.target.value)
                  }
                  placeholder="Enter interpretation"
                  style={{ 
                    width: '100%', 
                    padding: '10px', 
                    marginTop: '4px', 
                    marginBottom: '16px', 
                    borderRadius: '4px', 
                    border: '1px solid #d1d5db', 
                    fontSize: '14px' 
                  }} 
                />
              </div>
              <div className=" col-span-1">
                <label className="font-bold block mt-2.5 text-gray-700">
                  Color
                </label>
                <select
                 style={{ 
                  width: '100%', 
                  padding: '10px', 
                  marginTop: '4px', 
                  marginBottom: '16px', 
                  borderRadius: '4px', 
                  border: '1px solid #d1d5db', 
                  fontSize: '14px' 
                }} 
                  required
                  value={range.color}
                  onChange={(e) =>
                    updateRange(range.id, "color", e.target.value)
                  }
                >
                  <option value="">Select</option>
                  <option value="bg-green-100">Green</option>
                  <option value="bg-yellow-100">Yellow</option>
                  <option value="bg-orange-100">Orange</option>
                  <option value="bg-red-100">Red</option>
                  <option value="bg-gray-100">Gray</option>
                </select>
              </div>
              <div className="custom-flex">
                <button
                  type="button"
                  onClick={addNewRange}
                  className="focus:outline-none"
                  aria-label="Add new range"
                >
                  <Plus className="custom-button_pluss" />
                </button>
                <button
                  onClick={() => removeRange(range.id)}
                  className="focus:outline-none"
                  aria-label="Remove range"
                  disabled={ranges.length === 1}
                  type="button"
                >
                  <Trash2
                   className={`custom-button ${ranges.length === 1 ? "bg-gray" : "bg-red hover:bg-red"}`}
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddNewQuestionnaire;
