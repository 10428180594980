import React, { useState } from 'react'
import BHIChart from './BHIChart';
import OtherScoresMain from './OtherScoresMain';

function TabOtherAndResults({ questionnaires }) {
  const [activeTab, setActiveTab] = useState("treatment");


  return (
    <>
      <div className="metrics-container">
        <div className="treatment-insights">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "50%" }}>
              <button
                className={`section-title_two ${activeTab === "treatment" ? "highlight" : ""
                  }`}
                onClick={() => setActiveTab("treatment")}
              >
                TREATMENT INSIGHTS
              </button>
              <div
                className={`divider_two ${activeTab === "treatment" ? "highlight" : ""
                  }`}
              >

              </div>
            </div>
            <div style={{ width: "50%" }}>
              <button
                className={`section-title_two ${activeTab === "comprehensive" ? "highlight" : ""
                  }`}
                onClick={() => setActiveTab("comprehensive")}
              >
                Comprehensive Results
              </button>
              <div
                className={`divider_two ${activeTab === "comprehensive" ? "highlight" : ""
                  }`}
              ></div>
            </div>
          </div>
          {activeTab === "treatment" && <BHIChart/> }
          {/* {activeTab === "treatment" && (
              <div className="graph-container">
                <p className="graph-title">BHI Treatment Progress</p>
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart
                    data={data}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="week"
                      label={{
                        value: "Weeks",
                        position: "insideBottom",
                        offset: -5,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "BHI Score",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="score"
                      stroke="#e63946"
                      strokeWidth={2}
                      dot={{ r: 4 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )} */}



          {/* {activeTab === "comprehensive" && (
            <div style={{ padding: 10 }}>
              <select name="" id="" className="custom-select_two">
                <option value="">Select a question for see graph</option>
                {questionnaires?.map((item, index) => {
                  if (selectedQuestions?.some((question) => question === item.id)) {
                    return (
                      <option key={index} value={item.id}>
                        {item.label}
                      </option>
                    );
                  }
                  // return (
                  //   <option key={index} value={item.id}>
                  //     {item.label}
                  //   </option>
                  // );
                })}
              </select>
            </div>
          )} */}

{activeTab === "comprehensive" && <OtherScoresMain questionnaires={questionnaires} /> }



        </div>
      </div>

    </>
  )
}

export default TabOtherAndResults