import React, { useEffect, useState } from "react";
import { Getmyclinicpatient } from "../../../api/helper";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AllPatientsagencydata = () => {
  const [showCreateNewMenu, setShowCreateNewMenu] = useState(null);
  const [showActionsMenu, setShowActionsMenu] = useState(null);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const navigation = useNavigate();
  const [doctordata, setdoctordata] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const Getrequest = async (page) => {
    try {
      let data = {
        limit: 50,
        page: 1,
        agencyId: profiledata?._id,
        status: "approved",
      };
      const response = await Getmyclinicpatient(data);
      setdoctordata(response?.data.data?.response);
      setTotalPages(response?.data?.data?.totalPages);
    } catch (error) {
      console.log(error, "error=====");
    }
  };
  useEffect(() => {
    Getrequest();
  }, []);
  const getAge = (birthDate) => {
    const birthMoment = moment(birthDate);
    const now = moment();
    const years = now.diff(birthMoment, "Years");
    if (years < 1) {
      const months = now.diff(birthMoment, "Months");
      return `${months} Month${months !== 1 ? "s" : ""}`;
    } else if (years === 1) {
      const days = now.diff(birthMoment.add(1, "Year"), "Days");
      return `${days} Day${days !== 1 ? "s" : ""}`;
    } else {
      return `${years} Year${years !== 1 ? "s" : ""}`;
    }
  };
  const handleCreateNewClick = (index) => {
    setShowCreateNewMenu(showCreateNewMenu === index ? null : index);
    setShowActionsMenu(null); // close actions menu when create new is clicked
  };

  const handleActionsClick = (index) => {
    setShowActionsMenu(showActionsMenu === index ? null : index);
  };

  return (
    <div className="user-table-container">
      <table className="user-table">
        <thead style={{ backgroundColor: "white" }}>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Age</th>
            <th>Next Appointment</th>
            <th>Pay Type</th>
            <th>Notes</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* {doctordata && doctordata.length > 0 ? (
            doctordata.map((clinic, index) => ( */}
          {doctordata && doctordata.length > 0 ? (
            doctordata
              .slice()
              .reverse()
              .map((clinic, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={
                        clinic?.patientId?.image ||
                        "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      }
                      alt={clinic?.patientId?.companyName || "Not Available"}
                      className="clinic-image"
                    />
                  </td>
                  <td>
                    {clinic?.patientId?.firstName && clinic?.patientId?.lastName
                      ? clinic?.patientId?.firstName +
                        " " +
                        clinic?.patientId?.lastName
                      : "Not Available"}
                  </td>
                  <td>
                    {clinic?.patientId?.gender
                      ? clinic?.patientId?.gender
                      : "Not Available"}
                  </td>
                  <td>
                    {clinic?.patientId?.DOB
                      ? getAge(clinic?.patientId?.DOB)
                      : "Not Available"}
                  </td>
                  <td>
                    {clinic?.nextAppointment
                      ? clinic?.nextAppointment
                      : "Not Available"}
                  </td>
                  <td>{clinic?.payType ? clinic?.payType : "Not Available"}</td>
                  <td>
                    <span
                      className="user-table-view-notes"
                      onClick={() => {
                        navigation("/PatientsDetails", { state: { clinic } });
                      }}
                    >
                      {"View Notes"}
                    </span>

                    <button
                      className="user-table-create-new-button"
                      onClick={() => handleCreateNewClick(index)}
                    >
                      Create New
                    </button>

                    {showCreateNewMenu === index && clinic?.notes && (
                      <div className="user-table-popup-menu">
                        <ul>
                          <li>Initial Assessment</li>
                          <li>Progress Notes</li>
                          <li>Non-Billable Notes</li>
                          <li>Discharge Summary Note</li>
                        </ul>
                      </div>
                    )}
                  </td>
                  <td>
                    <button
                      className="view-button"
                      style={{ fontSize: 14 }}
                      onClick={() => handleActionsClick(index)}
                    >
                      Actions
                    </button>
                    {showActionsMenu === index && (
                      <div
                        className="user-table-popup-menu"
                        // Attach ref for outside click detection
                      >
                        <ul>
                          <li
                            onClick={() =>
                              navigation("/ClientEditbyagency", {
                                state: { data: clinic },
                              })
                            }
                          >
                            View Info.
                          </li>
                          <li>Message</li>
                          <li>Call</li>
                          <li>Assign Patients</li>
                          <li>History</li>
                        </ul>
                      </div>
                    )}
                  </td>
                  {/* <td>
        <button className="user-table-actions-button" onClick={() => handleActionsClick(index)}>
          { 'Actions'}
        </button>
        {showActionsMenu === index && clinic?.actions && (
          <div className="user-table-popup-menu">
            <ul>
              <li>View Info</li>
              <li>Message</li>
              <li>Call</li>
              <li>Delete</li>
            </ul>
          </div>
        )}
      </td> */}
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan="8">No Patient available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllPatientsagencydata;
