import React, { useEffect, useState } from 'react'
import { ShowToast } from '../../../../helpers/ToastService';
import WeightFactor from './WeightFactor';
import { updateCategory } from '../../../../api/helper';

function CategoryList({ categories }) {

    const [loading, setLoading] = useState(false)
    const [totalWeight, setTotalWeight] = useState(1)


    const handleUpdateWeight = async (value, cateId) => {
        try {
            await updateCategory(cateId, { weight: value });
            ShowToast("Weight factor updated successfully", "success")
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (categories) {
            let total = 0;
            categories?.map((item) => {
                const weight = item?.weight || 0;
                return (total += weight);
            });
            setTotalWeight(Number.parseFloat(total.toFixed(2)));
        }
    }, [categories]);

    return (
        <>
            <div className="  bg-white w-full p-10 mx-auto">
                <div className="section">
                    {/* <div className="text-xl font-bold">Saved Categories</div> */}
                    <table className="table" id="questionnaireTable">
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                <th>Short Name</th>
                                <th>Weight Factor</th>
                                <th>Questions</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.name}</td>
                                    <td>{item?.abbreviation}</td>
                                    <WeightFactor
                                        initialValue={item?.weight || 0}
                                        onUpdate={handleUpdateWeight}
                                        cateId={item?._id}
                                    />
                                    <td>
                                    {/* {item?.questions?.length} */}
                                        <button className="w-full">{item?.questions?.length}</button>
                                    </td>
                                    <td>{item?.status || "Active"}</td>
                                    <td>


                                        <button
                                            disabled={item?.isPreData ? true : false}
                                            className={`${item?.isPreData && "cursor-not-allowed opacity-50"
                                                } action-button bg-yellow-400 text-black`}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            // onClick={() => deleteACategory(item)}
                                            disabled={item?.isPreData ? true : false}
                                            className={`${item?.isPreData && "cursor-not-allowed opacity-50"
                                                } action-button bg-red-500`}
                                        >
                                            {loading ? "Deleting..." : "Delete"}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <p className="mt-2">
                        {totalWeight < 1.0 && totalWeight !== 0 ? (
                            <span className="text-orange-500 font-semibold">
                                Total weight must add up to 1.0. You have{" "}
                                <span className={`font-bold text-green-500`}>
                                    {(1 - totalWeight).toFixed(3)}
                                </span>{" "}
                                to allocate. Please adjust the weights.
                            </span>
                        ) : (
                            totalWeight > 1.0 && (
                                <span className="text-orange-500 font-semibold">
                                    Total weight must add up to 1.0. You weight allocation is over
                                    the limit by{" "}
                                    <span className={`font-bold text-red-500`}>
                                        {(1 - totalWeight).toFixed(3)}
                                    </span>{" "}
                                    . Please adjust the weights.
                                </span>
                            )
                        )}
                    </p>

                </div>
            </div>
        </>
    )
}

export default CategoryList