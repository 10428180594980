import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import {
  FetchDropdownFacility,
  FetchFacility,
  PatientAddTeam,
  PatientgetTeam,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  practice: Yup.string().required("Practice is required"),
  providerType: Yup.string().required("Provider Type is required"),
  teamMember: Yup.string().required("Team Member is required"),
  fromDate: Yup.date()
    .typeError("Please select a valid From Date")
    .required("From Date is required"),
  toDate: Yup.date()
    .typeError("Please select a valid To Date")
    .min(Yup.ref("fromDate"), "To Date cannot be before From Date")
    .required("To Date is required"),
  lastSeenDate: Yup.date()
    .typeError("Please select a valid Last Seen Date")
    .required("Last Seen Date is required"),
});
const ExternalTeam = ({ info, closeModal, Fetchteammember }) => {
    const profiledata = useSelector((state) => state?.cookies?.userDetails);
    const pageSize = 10000;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    data.patientId = info?._id;
    data.memberType = "facility";
    console.log("Submitted Data:====>>", data);
    try {
      const response = await PatientAddTeam(data);
      ShowToast(response?.data?.message);
      reset();
      Fetchteammember();
      closeModal();
    } catch (error) {
      console.log(error);
      closeModal();
    }
    closeModal();
  };
 
  return (
    <div id="external" className="tab-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="facility-search-external">Practice</label>
          <input
            type="search"
            id="facility-search-external"
            placeholder="Search Practice"
            {...register("practice")}
          />
          {errors.practice && (
            <p className="error-message">{errors.practice.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="provider-type-search-external">Provider Type</label>
          <input
            type="search"
            id="provider-type-search-external"
            placeholder="Search Provider Type"
            {...register("providerType")}
          />
          {errors.providerType && (
            <p className="error-message">{errors.providerType.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="team-member-search-external">Team Member</label>
          <input
            type="search"
            id="team-member-search-external"
            placeholder="Search Team Member"
            {...register("teamMember")}
          />
          {errors.teamMember && (
            <p className="error-message">{errors.teamMember.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="from-date-external">From Date</label>
          <input
            type="date"
            id="from-date-external"
            {...register("fromDate")}
          />
          {errors.fromDate && (
            <p className="error-message">{errors.fromDate.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="to-date-external">To Date</label>
          <input type="date" id="to-date-external" {...register("toDate")} />
          {errors.toDate && (
            <p className="error-message">{errors.toDate.message}</p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="lastseen-date-external">Last Seen Date</label>
          <input
            type="date"
            id="lastseen-date-external"
            {...register("lastSeenDate")}
          />
          {errors.lastSeenDate && (
            <p className="error-message">{errors.lastSeenDate.message}</p>
          )}
        </div>
        <div className="form-buttons">
          <button className="cancel" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="save" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ExternalTeam;
