import React, { useState } from 'react'
import SendQuestionnaireModal from './SendQuestionnaireModal';

function SendQuestionsnaireMain() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // console.log(isModalOpen,'==>isModalOpen')
  const handleModalSubmit = (selectedItems) => { };
  return (
    <>
      {/* <button
        onClick={() => setIsModalOpen(true)}
        className="bg-primaryBlue text-white px-4 py-2 rounded-lg"
      >
        Send Questionsnairessss
      </button> */}
      <button
        className="send-questionnaire"
        onClick={() => setIsModalOpen(true)}
      >Send Questionnaire</button>
      <SendQuestionnaireModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      // onSubmit={handleModalSubmit}
      />
    </>
  )
}

export default SendQuestionsnaireMain