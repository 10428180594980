import React, { useEffect, useState } from 'react'
import { ScoreCircle } from '../../../../../utils/ScoreCircle'
import { Fetchactual } from '../../../../../api/helper';
import { format } from "date-fns";
import { useSelector } from 'react-redux';

function MH_ScoreMain({ compositeScore }) {

    const profiledata = useSelector((state) => state?.cookies?.userDetails);
    const patientDetails = useSelector((state) => state?.cookies?.patinetDetails)

    const [score, setScore] = useState(0);
    useEffect(() => {
        getScore();
    }, []);

    async function getScore() {
        const response = await Fetchactual(`agencyId=${profiledata._id}&patientId=${patientDetails._id}`);
        const data = response.data
        if (data?.totalScore) {
            setScore(data.totalScore[data.totalScore?.length - 1]);
        }
    }

    return (
        <>
            <div className="mental-wellness-index">
                <h3 className="section-title_two">MENTAL WELLNESS INDEX</h3>
                <div className="divider"></div>
                <div style={{ height: 10 }} />
                <p className="  text-md font-semibold text-primaryBlue">
                    EnnVital MH Score:{" "}
                    {compositeScore ? compositeScore : score?.totalScore}
                </p>
                <ScoreCircle score={compositeScore ? compositeScore : score?.totalScore} />
                <p>
                    <strong>Last generated:</strong>{" "}
                    <span className="not-generated">
                        {score?.date
                            ? format(score?.date, "yyyy-MM-dd")
                            : "Not generated yet"}
                    </span>
                </p>
                <p>
                    <strong>Model Version:</strong>{" "}
                    <span className="bold">EnnVital 1.0</span>
                </p>
            </div>
        </>
    )
}

export default MH_ScoreMain