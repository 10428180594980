import React, { useEffect, useState } from 'react'
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Fetchactual } from '../../../../../api/helper';
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function BHIChart() {
    const [isActualScore, setIsActualScore] = useState([]);
    const [initialBHI, setInitialBHI] = useState(75);
    const [startDate, setStartDate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const profiledata = useSelector((state) => state?.cookies?.userDetails);
    const patientDetails = useSelector((state) => state?.cookies?.patinetDetails)

    const TOTAL_WEEKS = 25; // Total number of weeks
    const IMPROVEMENT_RATE = 0.045; // Rate of improvement
    const BASELINE_BHI = 20; // Plateau value for BHI

    // Generate weeks dynamically
    const weeks = Array.from({ length: TOTAL_WEEKS }, (_, i) => i);

    // Generate predicted BHI using realistic exponential decay
    const predictedBHI = weeks.map(
        (week) =>
            BASELINE_BHI +
            (initialBHI - BASELINE_BHI) * Math.exp(-IMPROVEMENT_RATE * week)
    );

    useEffect(() => {
        getScore();
    }, []);

    async function getScore() {
        try {
            const response = await Fetchactual(`agencyId=${profiledata._id}&patientId=${patientDetails._id}`);
            const data = response.data
            if (data?.totalScore) {
                setIsActualScore(data.totalScore);
                setInitialBHI(data.totalScore[0].totalScore);
                setStartDate(new Date(data.totalScore[0].date));
                setIsLoading(false)
            }
        } catch (error) {

        }
    }

    const actualData = isActualScore.map((item) => {
        const itemDate = new Date(item.date);
        const weekSinceStart = Math.floor(
            (itemDate - startDate) / (7 * 24 * 60 * 60 * 1000)
        ); // Calculate weeks
        return { week: weekSinceStart, score: item.totalScore };
    });

    // Prepare data for the chart
    const data = {
        labels: weeks, // X-axis labels (weeks)
        datasets: [
            {
                label: "Expected",
                data: predictedBHI,
                borderColor: "red",
                borderWidth: 2,
                fill: false,
                tension: 0.3, // Smooth line
            },
            {
                label: "Actual",
                data: actualData.map((point) => ({
                    x: point.week, // Use the calculated week
                    y: point.score, // Actual score
                })),
                borderColor: "blue",
                backgroundColor: "blue",
                borderWidth: 1.5,
                pointStyle: "circle",
                pointRadius: 4,
                fill: false,
                tension: 0.3, // Smooth line
                showLine: false, // Only show points for actual data
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "BHI Treatment Progress",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Weeks Since Initial Assessment",
                },
                ticks: {
                    stepSize: 1, // Show one week at a time
                },
            },
            y: {
                title: {
                    display: true,
                    text: "BHI Score",
                },
                min: BASELINE_BHI - 10, // Add buffer below baseline
                max: 100, // Adjust as needed
                ticks: {
                    stepSize: 10,
                },
            },
        },
    };

    if (isLoading) {
        return <p>Loading chart...</p>;
    }
    return <Line data={data} options={options} />;
}

export default BHIChart