import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { FetchEligibility } from "../../../../../api/helper";
import moment from "moment";

const EligibilityChecks = ({ info,activeTab }) => {
  const [showModal, setShowModal] = useState(false);
  const [eligibilitydata, setEligibilitydata] =useState({
      data: [],
      pagination: { totalPages: 0 },
    });
  const pageSize = 10;
  const [currentlabspage, setcurrentlabspage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const getEligibilitydata = async (page = 1) => {
    try {
      const respo = await FetchEligibility(
        profiledata?._id,
        info?.patientId?._id,
        pageSize,
        page
      );
      setEligibilitydata({
        data: respo.data.data?.data,
        pagination: respo.data.data.pagination || { totalPages: 0 }, 
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeTab === "EligibilityChecks") {
      getEligibilitydata();
    }
  }, [activeTab]);
  const handlelabsPageChange = (page) => {
    setcurrentlabspage(page);
    getEligibilitydata(page);
  };
  // console.log(eligibilitydata?.data,"eligibilitydata======")
  return (
    <>
      <div className="container-custom">
        <div className="card-custom">
          <div className="card-header_two-custom">
            <h4>Eligibility Checks History</h4>
          </div>
          <div className="card-body-custom">
            <div className="table-responsive-custom">
              <table className="table-custom">
                <thead>
                  <tr>
                    <th>Date Run</th>
                    <th>Eligibility Status</th>
                    <th>In-Network Status</th>
                    <th style={{ width: 150 }}>Action</th>
                  </tr>
                </thead>
               
                <tbody>
                {eligibilitydata?.data.length > 0 ? (
                  eligibilitydata?.data.map((item, index) => {
                    const dateRun = moment(item.createdAt).format("MM/DD/YYYY");
                    const hasErrors = item.data?.errors?.length > 0;
                    const eligibilityStatus = hasErrors ? "Not Eligible" : "Eligible";
                    const networkStatus = hasErrors ? "Out of Network" : "In-Network";

                    return (
                      <tr key={index}>
                        <td>{dateRun}</td>
                        <td>{eligibilityStatus}</td>
                        <td>{networkStatus}</td>
                        <td className="action-links">
                          <a className="no-print" onClick={() => handleOpenModal(item)}>
                            Download
                          </a>
                          &nbsp;|&nbsp;
                          <a className="no-print" onClick={() => handleOpenModal(item)}>
                            Print
                          </a>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>No Data Available</td>
                  </tr>
                )}
              </tbody>
              </table>
              <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Previous Button */}
              <button
                onClick={() => handlelabsPageChange(currentlabspage - 1)}
                disabled={currentlabspage === 1}
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: currentlabspage === 1 ? "#ccc" : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor: currentlabspage === 1 ? "not-allowed" : "pointer",
                }}
              >
                Previous
              </button>

              <span
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #007BFF",
                }}
              >
                {currentlabspage} of {eligibilitydata?.pagination?.totalPages}
              </span>

              <button
                onClick={() => handlelabsPageChange(currentPage + 1)}
                disabled={
                  currentlabspage === eligibilitydata?.pagination?.totalPages
                }
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor:
                    currentlabspage === eligibilitydata?.pagination?.totalPages
                      ? "#ccc"
                      : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor:
                    currentlabspage === eligibilitydata?.pagination?.totalPages
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Next
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && selectedItem && (
        <div className="custom-modal">
          <div className="custom-modal-dialog">
            <div className="custom-modal-content">
              <div className="custom-modal-header">
                <h5 className="modal-title" id="eligibilityModalLabel">
                  Member Eligibility Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                />
              </div>
              <div className="custom-modal-body" id="modalDetail">
                <div className="custom-card">
                  <div className="card-header_two">
                    <h6 className="custom-card-title">Member Information</h6>
                  </div>
                  <div class="custom-card-body">
                    <table class="Eligibilitytable">
                      <tbody>
                        <tr>
                          <th scope="row">Name</th>
                          <td>{`${selectedItem.data.subscriber.firstName} ${selectedItem.data.subscriber.lastName}`}</td>
                          <th scope="row">Member ID</th>
                          <td>{selectedItem.data.subscriber.memberId}</td>
                        </tr>
                        <tr>
                          <th scope="row">Date of Birth</th>
                          <td>{moment(selectedItem.data.subscriber.dateOfBirth, "YYYYMMDD").format("MM/DD/YYYY")}</td>
                          <th scope="row">Gender</th>
                          <td>{selectedItem.data.subscriber.gender}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address</th>
                          <td colspan="3">
                          {`${selectedItem.data.subscriber.address.address1}, ${selectedItem.data.subscriber.address.city}, ${selectedItem.data.subscriber.address.state} ${selectedItem.data.subscriber.address.postalCode}`}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Relationship to Subscriber</th>
                          <td colspan="3" id="relationshipCell">
                          {selectedItem.data.subscriber.relationToSubscriber}

                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="custom-card">
                  <div className="card-header_two">
                    <h6 className="mb-0">Plan &amp; Eligibility Info</h6>
                  </div>
                  <div className="card-body">
                    <table className="Eligibilitytable table-bordered mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Plan Name</th>
                          <td>{selectedItem.data.planStatus[0]?.status}</td>
                          <th scope="row">Group Number</th>
                          <td>{selectedItem.data.tradingPartnerServiceId}</td>
                        </tr>
                        <tr>
                          <th scope="row">Plan Coverage</th>
                          <td>{selectedItem.data.planDateInformation.eligibility}</td>
                          <th scope="row">Eligibility Status</th>
                          <td>{selectedItem.data.planStatus[0]?.status}</td>
                        </tr>
                        <tr>
                          <th scope="row">Eligibility Begin</th>
                          <td>{selectedItem.data.planDateInformation.eligibility.split('-')[0]}</td>
                          <th scope="row">Eligibility End</th>
                          <td>{selectedItem.data.planDateInformation.eligibility.split('-')[1]}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="custom-card">
                  <div className="card-header_two">
                    <h6 className="mb-0">Information Receiver</h6>
                  </div>
                  <div className="card-body">
                    <table className="Eligibilitytable table-bordered mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Type</th>
                          <td>Provider, Non-Person</td>
                          <th scope="row">Name</th>
                          <td>General Health Clinic</td>
                        </tr>
                        <tr>
                          <th scope="row">NPI</th>
                          <td colSpan={3}>1234567890</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="custom-card">
                  <div className="card-header_two">
                    <h6 className="mb-0">Member Benefit Details</h6>
                  </div>
                  <div className="card-body">
                    <div className="accordion" id="benefitsAccordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="generalHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#generalCollapse"
                            aria-expanded="false"
                            aria-controls="generalCollapse"
                          >
                            General
                          </button>
                        </h2>
                        <div
                          id="generalCollapse"
                          className="accordion-collapse collapse"
                          aria-labelledby="generalHeading"
                          data-bs-parent="#benefitsAccordion"
                        >
                          <div className="accordion-body">
                            <p style={{ textAlign: "left" }}>
                              <strong>Coverage Type:</strong> Health Benefit
                              Plan Coverage
                            </p>
                            <p style={{ textAlign: "left" }}>
                              <strong>Insurance Type:</strong> PPO
                            </p>
                            <p style={{ textAlign: "left" }}>
                              <strong>Description:</strong> Standard plan
                              coverage details for general benefits.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="waitingHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#waitingCollapse"
                            aria-expanded="false"
                            aria-controls="waitingCollapse"
                          >
                            Plan Waiting Period
                          </button>
                        </h2>
                        <div
                          id="waitingCollapse"
                          className="accordion-collapse collapse"
                          aria-labelledby="waitingHeading"
                          data-bs-parent="#benefitsAccordion"
                        >
                          <div className="accordion-body">
                            <p>
                              No waiting period applies for pre-existing
                              conditions. Coverage is effective immediately on
                              eligibility date.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="inpatientHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#inpatientCollapse"
                            aria-expanded="false"
                            aria-controls="inpatientCollapse"
                          >
                            Hospital - Inpatient
                          </button>
                        </h2>
                        <div
                          id="inpatientCollapse"
                          className="accordion-collapse collapse"
                          aria-labelledby="inpatientHeading"
                          data-bs-parent="#benefitsAccordion"
                        >
                          <div className="accordion-body">
                            <p>
                              <strong>Eligibility or Benefit:</strong>{" "}
                              Co-Insurance (20%)
                            </p>
                            <p>
                              <strong>
                                Required Authorization or Certification:
                              </strong>{" "}
                              Yes
                            </p>
                            <p>
                              <strong>In-Network:</strong> Yes
                            </p>
                            <p>
                              <strong>Message:</strong> Required for
                              non-emergent musculoskeletal procedures.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="outpatientHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#outpatientCollapse"
                            aria-expanded="false"
                            aria-controls="outpatientCollapse"
                          >
                            Hospital - Outpatient
                          </button>
                        </h2>
                        <div
                          id="outpatientCollapse"
                          className="accordion-collapse collapse"
                          aria-labelledby="outpatientHeading"
                          data-bs-parent="#benefitsAccordion"
                        >
                          <div className="accordion-body">
                            <p>
                              <strong>Eligibility or Benefit:</strong>{" "}
                              Co-Insurance (20%)
                            </p>
                            <p>
                              <strong>
                                Required Authorization or Certification:
                              </strong>{" "}
                              Yes
                            </p>
                            <p>
                              <strong>In-Network:</strong> Yes
                            </p>
                            <p>
                              <strong>Message:</strong> Surgical and
                              non-surgical outpatient visits require
                              pre-certification.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="pharmacyHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#pharmacyCollapse"
                            aria-expanded="false"
                            aria-controls="pharmacyCollapse"
                          >
                            Pharmacy
                          </button>
                        </h2>
                        <div
                          id="pharmacyCollapse"
                          className="accordion-collapse collapse"
                          aria-labelledby="pharmacyHeading"
                          data-bs-parent="#benefitsAccordion"
                        >
                          <div className="accordion-body">
                            <p>
                              <strong>Eligibility or Benefit:</strong>{" "}
                              Co-Payment
                            </p>
                            <p>
                              <strong>Amount:</strong> $10 (Generic), $25
                              (Brand)
                            </p>
                            <p>
                              <strong>In-Network:</strong> Yes
                            </p>
                            <p>
                              <strong>Message:</strong> Retail and mail-order
                              prescription drugs. Specialty prescriptions may
                              differ.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="otherHeading">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#otherCollapse"
                            aria-expanded="false"
                            aria-controls="otherCollapse"
                          >
                            Other Medical
                          </button>
                        </h2>
                        <div
                          id="otherCollapse"
                          className="accordion-collapse collapse"
                          aria-labelledby="otherHeading"
                          data-bs-parent="#benefitsAccordion"
                        >
                          <div className="accordion-body">
                            <p>
                              <strong>Eligibility or Benefit:</strong> Out of
                              Pocket (Stop Loss)
                            </p>
                            <p>
                              <strong>Coverage:</strong> Individual / Family
                            </p>
                            <p>
                              <strong>Amount:</strong> $2,000 (Individual) /
                              $4,000 (Family)
                            </p>
                            <p>
                              <strong>In-Network:</strong> Yes
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer_two">
                <button
                  type="button"
                  className="btn_close_print btn_close_print-secondary no-print"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn_close_print btn_close_print-secondary no-print"
                  style={{ marginLeft: 10 }}
                >
                  Print Detail
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EligibilityChecks;
