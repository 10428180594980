import React from 'react';

const Loader = () => {
  return (
  
    <div className="modal-overlay">
      {/* <div className=""> */}
        <div className="spinner"></div>
      {/* </div> */}
    </div>
    
  );
};

export default Loader;