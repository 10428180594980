import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Addproblems,
  DeleteProblems,
  FetchCurrentDiagnosis,
  GetProblems,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";
import Inputbox from "../../../Components/Inputbox";

const validationSchema = Yup.object().shape({
  Problem: Yup.string().required("Problem or Diagnosis is required"),
  Code: Yup.string().required("Code is required"),
  StartDate: Yup.date().required("Start Date is required"),
  EndDate: Yup.date().required("End Date is required"),
  ClinicalStatus: Yup.string().required("Clinical Status is required"),
  VerificationStatus: Yup.string().required("Verification Status is required"),
  CarePlan: Yup.string().required("Care Plan or Comments  is required"),
});
const ProblemsScreen = ({ info }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [ismodal, setIsModal] = useState(false);
  const [data, setdata] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selecteddiagosis, setselecteddiagosis] = useState(null);
  const [CurrentDiagnosisdata, setCurrentDiagnosisdata] = useState({
    data: [],
    pagination: { totalPages: 0 },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;
  const [modal, setmodal] = useState(false);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    reset();
    setIsModal(false);
  };
  const onSubmit = async (data) => {
    if (!selecteddiagosis?.sortDescription) {
      setValue("Problem", "");
      trigger("Problem");
      return;
    }
    const payload = {
      ...data,
      Problem: selecteddiagosis?.sortDescription,
      Code:selecteddiagosis?.code,
      patientId: info?.patientId?._id,
      agencyId: profileData?._id,
    };
    console.log("Submitted Data:", payload);
    try {
      const respo = await Addproblems(payload);
      ShowToast(respo?.data?.message);
      Getdata();
      CloseModal();
      setselecteddiagosis(null);
    } catch (error) {
      console.log(error);
      CloseModal();
    }
  };
  const Getdata = async () => {
    try {
      const respo = await GetProblems(info?.patientId?._id);
      // console.log(respo?.data?.data?.data, "respo======");
      setdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Getdata();
  }, []);
  const handleRemove = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Problem?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const respo = await DeleteProblems(id);
      ShowToast(respo?.data?.message);
      Getdata();
    } catch (error) {
      console.log(error);
    }
  };
  const GetCurrentDiagnosis = async (search = "", page = 1) => {
    try {
      const response = await FetchCurrentDiagnosis(search, page, pageSize);
      if (response?.data?.data) {
        setCurrentDiagnosisdata({
          data: response.data.data,
          pagination: response.data.data.pagination || { totalPages: 0 }, // Safeguard
        });
      } else {
        console.error("Unexpected API response structure");
        setCurrentDiagnosisdata({ data: [], pagination: { totalPages: 0 } });
      }
    } catch (error) {
      console.error("Error fetching admin insurance data", error);
      setCurrentDiagnosisdata({ data: [], pagination: { totalPages: 0 } });
    }
  };
  useEffect(() => {
    GetCurrentDiagnosis(searchTerm, currentPage);
  }, [searchTerm, currentPage]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    GetCurrentDiagnosis(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    GetCurrentDiagnosis(searchTerm, page);
  };
  const handelChange = (diagnosis) => {
    // setselecteddiagosis(data?.code);
    setselecteddiagosis(diagnosis);
    setValue("Problem", diagnosis?.sortDescription);
    trigger("Problem");
    setValue("Code", diagnosis?.code);
    trigger("Code");
    setmodal(false);
    setCurrentPage(1);
    setSearchTerm("");
  };
  // console.log(selecteddiagosis,"selecteddiagosis=====")
  return (
    <>
      <div className="container_NotesTabScreen">
        <h1>Problems</h1>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add Problem
        </button>
        <div
          className="tabs-container__three"
          style={{ display: "flex", width: "100%", justifyContent: "left" }}
        />
        {ismodal && (
          <div className="modal_PatientDocuments">
            <h2>Add Problem</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row_ProblemsScreen">
                <div className="form-group_ProblemsScreen">
                  <div
                    onClick={() => {
                      setmodal(true);
                    }}
                  >
                    <label>{"Problem or Diagnosis"}</label>
                    <div
                      style={{
                        border: "1px solid grey",
                        width: "100%",
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {selecteddiagosis?.sortDescription ||
                        "Select Current Diagnosis"}
                    </div>
                    <input
                      type="hidden"
                      {...register("Problem")}
                      value={selecteddiagosis?.sortDescription}
                    />
                    <p className="error-message">{errors.Problem?.message}</p>
                  </div>
                </div>
                {/* <div className="form-group_ProblemsScreen">
                  <label>Code</label>
                  <input
                    type="text"
                    placeholder="Enter Code"
                    {...register("Code")}
                  />
                  <p className="error-message">{errors.Code?.message}</p>
                </div> */}
                <div className="form-group_ProblemsScreen">
                  <div
                    onClick={() => {
                      setmodal(true);
                    }}
                  >
                    <label>{"Code"}</label>
                    <div
                      style={{
                        border: "1px solid grey",
                        width: "100%",
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {selecteddiagosis?.code || "Select Code"}
                    </div>
                    <input
                      type="hidden"
                      {...register("Code")}
                      value={selecteddiagosis?.code}
                    />
                    <p className="error-message">{errors.Code?.message}</p>
                  </div>
                </div>
                <div className="form-group_ProblemsScreen">
                  <label>Start Date</label>
                  <input type="date" {...register("StartDate")} />
                  <p className="error-message">{errors.StartDate?.message}</p>
                </div>
                <div className="form-group_ProblemsScreen">
                  <label>End Date</label>
                  <input type="date" {...register("EndDate")} />
                  <p className="error-message">{errors.EndDate?.message}</p>
                </div>
                <div className="form-group_ProblemsScreen">
                  <label>Clinical Status</label>
                  <select {...register("ClinicalStatus")}>
                    <option value="">Select clinical status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  <p className="error-message">
                    {errors.ClinicalStatus?.message}
                  </p>
                </div>
                <div className="form-group_ProblemsScreen">
                  <label>Verification Status</label>
                  <select {...register("VerificationStatus")}>
                    <option value="">Select verification status</option>
                    <option value="Unconfirmed">Unconfirmed</option>
                    <option value="Confirmed">Confirmed</option>
                  </select>
                  <p className="error-message">
                    {errors.VerificationStatus?.message}
                  </p>
                </div>
                <div className="form-group_ProblemsScreen">
                  <label>Care Plan or Comments</label>
                  <textarea
                    placeholder="Add patient instructions"
                    {...register("CarePlan")}
                    rows={2}
                  />
                    <p className="error-message">
                    {errors.CarePlan?.message}
                  </p>
                </div>
              </div>
              <div className="modal-footer_ProblemsScreen">
                <button
                  type="button"
                  onClick={CloseModal}
                  className="cancel-button_ProblemsScreen"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button_ProblemsScreen"
                  style={{ marginLeft: 10 }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        )}
        <div>
          {/* <h2>Problem List</h2> */}
          <table>
            <thead>
              <tr>
                <th>Problem</th>
                <th>Code</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Clinical Status</th>
                <th>Verification Status</th>
                <th>Care Comments</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan="10">No data found</td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={item._id || index}>
                    <td>{item.Problem}</td>
                    <td>{item.Code}</td>
                    <td>{new Date(item.StartDate).toLocaleDateString()}</td>
                    <td>{new Date(item.EndDate).toLocaleDateString()}</td>
                    <td>{item.ClinicalStatus}</td>
                    <td>{item.VerificationStatus}</td>
                    <td>{item.CarePlan}</td>
                    <td>
                      <button
                        className="btn"
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => handleRemove(item._id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={modal}
        onClose={() => {
          setmodal(false);
        }}
        aria-labelledby="modal-title"
      >
        <Box sx={modalinsuranceStyle}>
          <div>
            <Inputbox
              label={"Search Diagnosis"}
              placeholder={"Type to search..."}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <div style={{ marginTop: 20 }}>
              {CurrentDiagnosisdata?.data?.data?.length > 0 ? (
                CurrentDiagnosisdata?.data?.data?.map((insurance) => (
                  <div
                    key={insurance._id}
                    className="diagnosis-item"
                    onClick={() => handelChange(insurance)}
                  >
                    <div style={{ fontSize: 20 }}>{insurance.PayerName}</div>
                    <div>
                      <strong>Code:</strong> {insurance.code}
                    </div>
                    <div>
                      <strong>Name:</strong> {insurance.sortDescription}
                    </div>
                  </div>
                ))
              ) : (
                <p>No Diagnosis data available.</p>
              )}
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Previous Button */}
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: currentPage === 1 ? "#ccc" : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                }}
              >
                Previous
              </button>

              <span
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #007BFF",
                }}
              >
                {currentPage} of {CurrentDiagnosisdata?.data?.totalPages}
              </span>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage === CurrentDiagnosisdata?.pagination?.totalPages
                }
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor:
                    currentPage === CurrentDiagnosisdata?.pagination?.totalPages
                      ? "#ccc"
                      : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor:
                    currentPage === CurrentDiagnosisdata?.pagination?.totalPages
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProblemsScreen;
const modalinsuranceStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto",
};
