import './questionary.css'
import { useEffect, useState } from "react";
import { FetchQuestionnaireList } from "../../../../../../api/helper";
import { useSelector } from 'react-redux';
const SendQuestionnaireModal = ({ isOpen, onClose, onSubmit }) => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [data, setData] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const patientDetails = useSelector((state) => state?.cookies?.patinetDetails)

  const getQuestionnaires = async()=>{
    try {
      const response = await FetchQuestionnaireList(`agencyId=${profiledata._id}&patientId=${patientDetails._id}`)
      setData(response.data.questionnaires)

    } catch (error) {
      console.log(error.message,'==>>response')
    }
  }

  useEffect(()=>{
    getQuestionnaires();
  },[])

  useEffect(() => {
    const storedQuestionnaires = localStorage.getItem("selectedQuestionnaires");
    if (storedQuestionnaires) {
      setSelectedQuestions(JSON.parse(storedQuestionnaires));
    }
  }, []);

  const toggleSelection = (id) => {
    setSelectedQuestions((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };
  const handleSubmit = () => {
    localStorage.setItem(
      "selectedQuestionnaires",
      JSON.stringify(selectedQuestions)
    );
    onClose();
  };
  if (!isOpen) return null;

  return (

<>

<div className={`modal_questionnaire ${isOpen ? "visible" : "hidden"}`} id="modal">
  <div className="modal-content_questionnaire">
    <div className="modal-header_questionnaire">
      <div className="modal-title_questionnaire">{"Send Questionnaires"}</div>
      <button className="close-btn_questionnaire" onClick={onClose}>
        &times;
      </button>
    </div>
    <div className="modal-body_questionnaire">
      {data &&
        data.map((item) => (
          <div key={item.id} className="questionnaire-item">
            <input
              type="checkbox"
              id={item.id}
              checked={selectedQuestions.includes(item.id)}
              onChange={() => toggleSelection(item.id)}
            />
            <label htmlFor={item.id}>{item.label}</label>
          </div>
        ))}
    </div>
    <div className="modal-footer_questionnaire">
      <button className="btn_cancel" onClick={onClose}>Cancel</button>
      <button className="btn_submit" onClick={handleSubmit}>Submit</button>
    </div>
  </div>
</div>
</>
  );
};

export default SendQuestionnaireModal;
