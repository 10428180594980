import React, { useEffect, useRef, useState } from "react";
import Inputbox from "../../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Deletecontact,
  DeleteIdentification,
  Fetchcardtype,
  Patientrequestupdates,
  uploadImage,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { DropDownManuCustom } from "../../../Components/DropDownButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const validationSchema = Yup.object().shape({
  TypeofID: Yup.string().required("Type of ID is required"),
  IdentificationNumber: Yup.string().required(
    "Identification Number is required"
  ),
  ExpirationDate: Yup.string().required("Expiration Date of ID is required"),
});

const ClientIdentification = ({ info, getDteails }) => {
  const [image, setImages] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [idtypedata, setidtypedata] = useState([]);
  const fileInputRef = useRef(null);
  const [ismodal, setismodal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleViewDocument = (imageUrl) => {
    setImageSrc(imageUrl);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setImageSrc("");
  };
  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const imagePicker = async (event) => {
    const file = event.target.files[0];
    console.log(file,'====>file')
    if (file) {
      // console.log(file,'------->>file')
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        console.log(response,'===>response')
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  console.log(info?.Identification, "Identification=======");
  const onSubmit = async (data) => {
    if (!image) {
      ShowToast("Please upload image");
      return;
    }

    data.id = info?._id;

    // New Identification Entry
    const newIdentification = {
      IdentificationNumber: data.IdentificationNumber,
      ExpirationDate: data.ExpirationDate,
      TypeofID: data.TypeofID,
      UploadCopy: image,
    };

    // Merging existing and new Identification data
    const formattedData = {
      id: data.id,
      Identification: [...(info?.Identification || []), newIdentification],
    };

    console.log(formattedData, "formattedData====");

    try {
      const response = await Patientrequestupdates(formattedData);
      ShowToast(response?.data?.message);
      setismodal(false);
      setImages(null);
      reset();
      getDteails();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const Getcardtype = async () => {
    const respo = await Fetchcardtype();
    // console.log(respo?.data?.data?.data,"datat====rewspo======")
    setidtypedata(respo?.data?.data?.data);
  };
  useEffect(() => {
    Getcardtype();
  }, []);
  const handleRemove = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Identification?"
    );
    if (!confirmed) {
      return;
    }
    const data = {
      identificationId: id,
      id: info?._id,
    };
    try {
      const respo = await DeleteIdentification(data);
      ShowToast(respo?.data?.message);
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container_NotesTabScreen">
      <h1>Identification</h1>

      <button
        className="add-button_NotesTabScreen"
        style={{ float: "right", marginBottom: 10 }}
        onClick={() => {
          setismodal(true);
        }}
      >
        Add Identification
      </button>
      <table className="table" id="member-table">
        <thead>
          <tr>
            <th>Identification Number </th>
            <th>Type of ID</th>
            <th>Expiration Date</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        {/* {info?.Identification ? (
          <tr>
            <td>{info?.Identification?.IdentificationNumber}</td>
            <td>{info?.Identification?.TypeofID}</td>
            <td>{info?.Identification?.ExpirationDate}</td>
            <td>{info?.Identification?.UploadCopy}</td>
            <td>
              <button
                className="btn"
                style={{ backgroundColor: "red", color: "white" }}
                // onClick={() => handleRemove(contact._id)}
              >
                Remove
              </button>
            </td>
          </tr>
        ) : (
          <p style={{ textAlign: "left", marginTop: 10 }}>No Contact Found</p>
        )} */}
        {info?.Identification && info?.Identification.length > 0 ? (
          info?.Identification?.map((contact) => (
            <tr key={contact._id}>
              <td>{contact.IdentificationNumber}</td>
              <td>{contact.TypeofID}</td>
              <td>{contact.ExpirationDate}</td>
              {/* <td>{contact.UploadCopy}</td> */}
              <td>
                <button
                  className="View_Document"
                  onClick={() => handleViewDocument(contact.UploadCopy)}
                >
                  View Document
                </button>
              </td>
              <td>
                <button
                  className="btn"
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => handleRemove(contact._id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))
        ) : (
          <p style={{ textAlign: "left", marginTop: 10 }}>
            No Identification Found
          </p>
        )}
      </table>
      {ismodal && (
        <div className="modal_twooo" id="add-member-modal">
          <h1>Identification</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="Identification Number"
                  type="text"
                  placeholder="Enter Id Number"
                  name="IdentificationNumber"
                  register={register}
                />
                {errors.IdentificationNumber && (
                  <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                    {errors.IdentificationNumber.message}
                  </p>
                )}
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <DropDownManuCustom
                  label="Type of ID"
                  placeholder="Select option"
                  options={idtypedata}
                  name="TypeofID"
                  dropdownicon
                  register={register}
                  clearErrors={clearErrors}
                />
                {errors.TypeofID && (
                  <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                    {errors.TypeofID.message}
                  </p>
                )}
              </div>
              <div style={{ width: "40px" }} />
              <div style={{ width: "100%" }}>
                <Inputbox
                  label="Expiration Date"
                  type="date"
                  placeholder=""
                  name="ExpirationDate"
                  register={register}
                />
                {errors.ExpirationDate && (
                  <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
                    {errors.ExpirationDate.message}
                  </p>
                )}
              </div>
              <div style={{ width: "40px" }} />

              <div style={{ width: "100%", marginTop: 10 }}>
                <label>{"Upload a Copy"}</label>
                <div
                  style={{
                    border: "1px solid grey",
                    width: "100%",
                    height: 46,
                    borderRadius: 5,
                    paddingLeft: 10,
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      clickImageData();
                    }}
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: 7,
                      color: "black",
                      fontSize: 14,
                      paddingLeft: 5,
                      paddingRight: 5,
                      cursor: "pointer",
                    }}
                  >
                    Choose File
                  </button>

                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={imagePicker}
                    style={{ display: "none" }}
                  />

                  <div style={{ marginLeft: 5 }}>
                    {image ? "Image Uploaded" : "No file chosen"}
                  </div>
                </div>
              </div>
            </div>
            <div className="client-history__buttons">
              <button
                className="client-history__button client-history__button--cancel"
                onClick={() => {
                  setismodal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="client-history__button client-history__button--save"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      )}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <img
            src={imageSrc}
            alt="Document"
            style={{ width: "100%", height: "70vh", objectFit: "contain" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClientIdentification;
