import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddPatientSign,
  GetconsentManagement,
  GetPatientSign,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import moment from "moment/moment";
import Loader from "../../Components/Loader";

const Consents = ({ info }) => {
  const [consentdata, setconsentdata] = useState([]);
  const [ismodal, setIsModal] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState(null);
  const [signdata, setsigndata] = useState([]);
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const [selectedTitle, setselectedTitle] = useState("");

  const openModal = (item) => {
    console.log(item?._id);
    getSigned(item?._id);
    setSelectedConsent(item);
    setIsModal(true);
  };

  const closeModal = () => {
    setsigndata([]);
    setIsModal(false);
    setSelectedConsent(null);
  };
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const FetchConsentManagement = async () => {
    // console.log(profiledata?._id,"profiledata?._id========")
    try {
      const respo = await GetconsentManagement(profiledata?._id);
      console.log(respo?.data?.data?.data, "respo?.data?.data=========");
      setconsentdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    FetchConsentManagement();
  }, []);
  const dob = new Date(info?.patientId?.DOB);
  const day = String(dob.getDate()).padStart(2, "0");
  const month = String(dob.getMonth() + 1).padStart(2, "0");
  const year = dob.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const AddSignofpatient = async (item) => {
    // console.log(item,"item---------")
    const data = {
      consentManagementId: item?._id,
      agencyId: profiledata?._id,
      patientId: info?.patientId?._id,
    };
    // console.log(data, "data--------");
    // return;
    try {
      const respo = await AddPatientSign(data);
      console.log(respo?.data?.data, "respopost======");
      ShowToast("Signature Request Sent Successfully");
      FetchConsentManagement();
    } catch (error) {
      console.log(error);
    }
  };
  const getSigned = async (id) => {
    try {
      const respo = await GetPatientSign(info?.patientId?._id, id);
      setsigndata(respo?.data?.data?.data);
      // console.log(respo?.data?.data, "getrespo-=====");
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(consentdata, "consentdata=======");
  // console.log(profiledata?._id, "agencyId====");
  // console.log(info?.patientId?._id, "patientid=====");
  return (
    <>
      <div className="container_NotesTabScreen">
        <h1>Consents</h1>
        <table>
          <thead>
            <tr>
              <th>Consent Title</th>
              <th>Status</th>
              <th>Sign Status</th>
              <th>Actions</th>
             
            </tr>
          </thead>
          <tbody>
            {consentdata.length === 0 ? (
              <tr>
                <td colSpan="10">No data found</td>
              </tr>
            ) : (
              consentdata.map((item, index) => {
                const hasMatchingConsent = item?.informedConsent.some(
                  (consent) =>
                    consent.agencyId === profiledata?._id &&
                    consent.patientId === info?.patientId?._id
                );
                // console.log(hasMatchingConsent, "hasMatchingConsent=======");
                return (
                  <tr key={item._id || index}>
                    <td>{item.Title}</td>
                    <td
                      className={
                        item.status === "active"
                          ? "status-signed"
                          : "status-sent"
                      }
                    >
                      {item.status}
                    </td>
                    <td>
                      {hasMatchingConsent ? (
                        item?.informedConsent
                          .filter(
                            (consent) =>
                              consent.agencyId === profiledata?._id &&
                              consent.patientId === info?.patientId?._id
                          )
                          .every((consent) => !consent.Signedby) ? (
                          <span>Not Signed</span>
                        ) : (
                          <span>Signed</span>
                        )
                      ) : (
                        <span>Not Signed</span>
                      )}
                    </td>
                    <td>
                      <button
                        style={{
                          width: 100,
                        }}
                        className="view-btn"
                        onClick={() => openModal(item)}
                      >
                        View
                      </button>
                      {item.status === "active" && (
                        <button
                          className="download-btn"
                          style={{ marginLeft: 10 }}
                        >
                          Download PDF
                        </button>
                      )}
                      {!hasMatchingConsent && (
                        <button
                          style={{ marginLeft: 10, backgroundColor: "green" }}
                          className="save-button_Vitals"
                          onClick={() => {
                            AddSignofpatient(item);
                          }}
                        >
                          Send Signature Request
                        </button>
                      )}
                    </td>
                  
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {ismodal && selectedConsent && (
          <div className="modal_Consent">
            {isLoading && (
              <div className="modal_Consent_loader">
                {/* <div className="modal-content"> */}
                <div className="spinner"></div>
                {/* </div> */}
              </div>
            )}

            <div
              style={{
                fontSize: "20px",
                cursor: "pointer",
                width: 50,
                position: "absolute",
                right: 5,
                top: 7,
              }}
            >
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            </div>
            <div
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h1>Informed Consent for Treatment</h1>
              <p>
                <strong>Category:</strong> {selectedConsent.Title}
              </p>
              <p>
                <strong>Consent Type:</strong> {selectedConsent.Category}
              </p>
              <p>
                <strong>Client Name:</strong>{" "}
                {info?.patientId?.firstName + " " + info.patientId?.lastName}
              </p>
              <p>
                <strong>Agency Name:</strong>{" "}
                {selectedConsent.agencyId?.firstName +
                  " " +
                  selectedConsent.agencyId?.lastName}
              </p>
              <p>
                <strong>Signed Date:</strong> <span id="signedDate" />
              </p>
              <p style={{ textAlign: "left" }}>
                <strong>Consent Details:</strong>{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: selectedConsent.Content }}
                />
              </p>
              <label className="checkbox_Consent">
                {/* <input
                  type="checkbox"
                  checked={
                    signdata.length > 0 && signdata[0].checkingbox
                      ? true
                      : checkingbox
                  }
                  onChange={(e) => setCheckingbox(e.target.checked)}
                  disabled={signdata.length > 0 && signdata[0].checkingbox}
                />{" "} */}
                {/* By checking this box, I agree to use electronic records and
                signatures and acknowledge that I have read the related consumer
                disclosure. */}
              </label>
              <div className="signature-section">
                <h2 style={{ textAlign: "left" }}>Signature</h2>
                {/* {console.log(signdata, "====>>signdata")} */}

                <p style={{ textAlign: "left" }}>
                  {signdata.length > 0 && signdata[0].Signedby ? (
                    <>
                      <strong>Signed by:</strong>

                      <span>{signdata[0].Signedby}</span>
                    </>
                  ) : null}
                  <div
                    className="modal-footer_Vitals"
                    style={{ display: "flex", marginTop: -10 }}
                  ></div>
                </p>

                {/* <p style={{ textAlign: "left" }}>
                  <strong>Date and Time:</strong>{" "}
                  <span id="signedDateDisplay" />
                </p> */}
                {signdata.length > 0 && signdata[0].Signedby ? (
                  <p style={{ textAlign: "left" }}>
                    <strong>Date and Time:</strong>{" "}
                    <span>
                      {moment(signdata[0].signedDate).format(
                        "DD-MM-YYYY / hh:mm A"
                      )}
                    </span>
                  </p>
                ) : null}
                <p style={{ textAlign: "left" }}>
                  <strong>Patient Name:</strong>{" "}
                  {info?.patientId?.firstName + " " + info.patientId?.lastName}
                </p>
                <p style={{ textAlign: "left" }}>
                  <strong>Patient DOB:</strong> {formattedDate}
                </p>
              </div>
              <button className="button_Consent" onClick={() => closeModal()}>
                Print Consent
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Consents;
