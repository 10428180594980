import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Inputbox from "../../Components/Inputbox";
import { DropDownManuCustom } from "../../Components/DropDownButton";
import {
  AddFacility,
  DeleteFacility,
  FetchDropdownFacility,
  FetchFacility,
  GetFacilityTwo,
} from "../../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";
import FacilitySelectionmodal from "../../Components/FacilitySelectionmodal";
import FacilitySelectionmodalTwo from "../../Components/FacilitySelectionmodalTwo";

const validationSchema = Yup.object().shape({
  // facilityName: Yup.string().required("Facility Name is required"),
  facilityType: Yup.string().required("Facility Type is required"),
  NPI: Yup.string()
    .matches(/^\d{10}$/, "NPI Number must be 10 digits")
    .required("Facility NPI Number is required"),
});

const Practice = () => {
  const [showModal, setShowModal] = useState(false);
  const [showfacilitymodal, setshowfacilitymodal] = useState(false);
  const [selectedData, setSeletedData] = useState();
  const [editData, setEditData] = useState(null)
  const [editView, setEditView] = useState(null)
  const [data, setdata] = useState({
    data: [],
    pagination: { totalPages: 0 },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [facilitiesData, setFacilityData] = useState();

  const pageSize = 10;
  const [currentlabspage, setcurrentlabspage] = useState(1);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  // console.log(profiledata,'==>profiledata')

  const [selectedFacility, setSelectedFacility] = useState("enterFacility");
  const handleChange = (event) => {
    setSelectedFacility(event.target.id);
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => setShowModal(false);
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const facilitiesSelect = (data) => {
    reset(data);
    setSeletedData(data);
    setshowfacilitymodal(true);
  };
  const onSubmit = async (data) => {
    console.log("Final Data Submitted:", data);

    if (data?.parentId?.trim()) {
      // console.log('===>here')
      return setshowfacilitymodal(true);
    } else {
      delete data.parentId;
    }
    // console.log(data,'===>data')
    data.agencyId = profiledata?._id;
    // data.patientId=
    try {
      const respo = await AddFacility(data);
      // console.log(respo.data.data,'==>respo')
      setSeletedData(respo.data.data);
      closeModal();
      setshowfacilitymodal(true);
      getFaclilityList();

      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const GetFacility = async (page = 1) => {
    try {
      const Respo = await GetFacilityTwo(profiledata?._id, pageSize, page);
      setdata({
        data: Respo?.data?.data?.facilities,
        pagination: Respo?.data?.data || { page: 0 },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getFaclilityList = async () => {
    try {
      const data = await FetchDropdownFacility(profiledata?._id, 1000);
      setFacilityData(
        data.data.data.facilities.map((e) => ({ name: e.facilityName, ...e }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFaclilityList();
    GetFacility();
  }, []);
  const handlelabsPageChange = (page) => {
    setcurrentlabspage(page);
    GetFacility(page);
  };

  const handleEdit = (data) => {
    // console.log(data,'===>>>data')
    setEditView("Edit")
    setEditData(data)
    setshowfacilitymodal(true)
  }



  const handleView = (data) => {
    // console.log(data,'===>>>data')
    setEditView("View")
    setEditData(data)
    setshowfacilitymodal(true)
  }

  const handleDelete = async (id) => {
    console.log("Deleted ID:", id);

    const confirmed = window.confirm("Are you sure you want to delete this?");
    if (!confirmed) {
      return;
    }
    try {
      const response = await DeleteFacility(id);

      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        GetFacility();
      } else {
        ShowToast("Error: Unable to delete .");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting  .");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Manage External & Facility Team Members</h1>
        <div className="add-button_NotesTabScreen" onClick={openModal}>
          Add Practice Member
        </div>
      </div>

      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>Facility Selection</th>
              <th>Full Name </th>
              <th>Provider Type </th>
              <th>NPI Number </th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.length === 0 ? (
              <tr>
                <td
                  colSpan="8"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  No Practice Member Found
                </td>
              </tr>
            ) : (
              data?.data.map((row) => (
                <tr key={row._id} style={tableRowStyle}>
                  <td style={tableCellStyle}>
                    {row.facilityId?.facilityName || "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row.fullName || "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row.providerType || "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row.NPINumber || "Not Available"}
                  </td>
                  <td style={tableCellStyle}>
                    {row.PhoneNumber || "Not Available"}
                  </td>

                  <td style={tableCellStyle}>
                    {row.status || "Not Available"}
                  </td>

                  <td style={tableCellStyle}>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <button
                        onClick={() => handleView(row)}
                        style={{
                          backgroundColor: "#17A2B8", // Info color
                          color: "white",
                          border: "none",
                          padding: "8px 12px",
                          borderRadius: "6px",
                          cursor: "pointer",
                          transition: "background 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.backgroundColor = "#138496")}
                        onMouseLeave={(e) => (e.target.style.backgroundColor = "#17A2B8")}
                      >
                        <i className="fa-solid fa-eye"></i>
                      </button>

                      <button
                        onClick={() => handleEdit(row)}
                        style={{
                          backgroundColor: "#007BFF", // Primary color
                          color: "white",
                          border: "none",
                          padding: "8px 12px",
                          borderRadius: "6px",
                          cursor: "pointer",
                          transition: "background 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.backgroundColor = "#0056b3")}
                        onMouseLeave={(e) => (e.target.style.backgroundColor = "#007BFF")}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>

                      <button
                        onClick={() => handleDelete(row._id)}
                        style={{
                          backgroundColor: "#DC3545", 
                          color: "white",
                          border: "none",
                          padding: "8px 12px",
                          borderRadius: "6px",
                          cursor: "pointer",
                          transition: "background 0.3s ease",
                        }}
                        onMouseEnter={(e) => (e.target.style.backgroundColor = "#B02A37")}
                        onMouseLeave={(e) => (e.target.style.backgroundColor = "#DC3545")}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>

                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Previous Button */}
          <button
            onClick={() => handlelabsPageChange(currentlabspage - 1)}
            disabled={currentlabspage === 1}
            style={{
              margin: "5px",
              padding: "10px 20px",
              backgroundColor: currentlabspage === 1 ? "#ccc" : "#007BFF",
              color: "#fff",
              border: "1px solid #007BFF",
              cursor: currentlabspage === 1 ? "not-allowed" : "pointer",
            }}
          >
            Previous
          </button>

          <span
            style={{
              margin: "5px",
              padding: "10px 20px",
              backgroundColor: "#fff",
              color: "#000",
              border: "1px solid #007BFF",
            }}
          >
            {currentlabspage} of {data?.pagination?.totalPages}
          </span>

          <button
            onClick={() => handlelabsPageChange(currentPage + 1)}
            disabled={currentlabspage === data?.pagination?.totalPages}
            style={{
              margin: "5px",
              padding: "10px 20px",
              backgroundColor:
                currentlabspage === data?.pagination?.totalPages
                  ? "#ccc"
                  : "#007BFF",
              color: "#fff",
              border: "1px solid #007BFF",
              cursor:
                currentlabspage === data?.pagination?.totalPages
                  ? "not-allowed"
                  : "pointer",
            }}
          >
            Next
          </button>
        </div>
      </div>
      {showModal && (
        <div className="modal_PatientDocuments" id="addDocumentModal">
          <div
            style={{
              fontSize: "20px",
              cursor: "pointer",
              width: 50,
              position: "absolute",
              right: 18,
              top: 27,
            }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
              style={{ height: 20, width: 20 }}
            />
          </div>
          <div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: "100%",
                alignItems: "center",
                justifyItems: "center",
                marginBottom: 20,
              }}
            >
              <h1 style={{ textAlign: "left" }}>Add Facility or Practice</h1>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "15px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="radio"
                        id="enterFacility"
                        name="facility"
                        checked={selectedFacility === "enterFacility"}
                        onChange={handleChange}
                        style={{ margin: "0.5px" }}
                      />
                      Add facility and then add member
                    </label>

                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="radio"
                        id="existingFacility"
                        name="facility"
                        checked={selectedFacility === "existingFacility"}
                        onChange={handleChange}
                        style={{ margin: "0.5px" }}
                      />
                      Add member into existing facility
                    </label>
                  </div>

                  {selectedFacility === "enterFacility" && (
                    <>
                      <Inputbox
                        label="Facility Name"
                        type="text"
                        placeholder="Enter Facility Name"
                        name="facilityName"
                        className="Custom_input"
                        register={register}
                      />
                      <div className="form-group">
                        <label htmlFor="noteType">Organization Type</label>
                        <select id="noteType" {...register("facilityType")}>
                          <option value="">Select organization type</option>
                          <optgroup label="Facility Types">
                            <option value="Hospital">Hospital</option>
                            <option value="Skilled Nursing Facility (SNF)">
                              Skilled Nursing Facility (SNF)
                            </option>
                            <option value="Acute Care Facility">
                              Acute Care Facility
                            </option>
                            <option value="Long-Term Care Facility">
                              Long-Term Care Facility
                            </option>
                            <option value="Inpatient Psychiatric Facility">
                              Inpatient Psychiatric Facility
                            </option>
                            <option value="Residential Treatment Center (RTC)">
                              Residential Treatment Center (RTC)
                            </option>
                            <option value="Rehabilitation Center">
                              Rehabilitation Center
                            </option>
                            <option value="Intermediate Care Facility (ICF)">
                              Intermediate Care Facility (ICF)
                            </option>
                            <option value="Assisted Living Facility (ALF)">
                              Assisted Living Facility (ALF)
                            </option>
                            <option value="Memory Care Facility">
                              Memory Care Facility
                            </option>
                            <option value="Detox Center">Detox Center</option>
                            <option value="Correctional Facility / Jail">
                              Correctional Facility / Jail
                            </option>
                            <option value="Veterans Health Facility">
                              Veterans Health Facility
                            </option>
                            <option value="Home Health Agency">
                              Home Health Agency
                            </option>
                            <option value="Hospice Facility">
                              Hospice Facility
                            </option>
                            <option value="Shelter or Transitional Housing with Medical Support">
                              Shelter or Transitional Housing with Medical
                              Support
                            </option>
                            <option value="Partial Hospitalization Program (PHP) Location">
                              Partial Hospitalization Program (PHP) Location
                            </option>
                            <option value="Intensive Outpatient Program (IOP) Facility">
                              Intensive Outpatient Program (IOP) Facility
                            </option>
                            <option value="Foster Care Medical Home">
                              Foster Care Medical Home
                            </option>
                            <option value="Community Mental Health Center (CMHC)">
                              Community Mental Health Center (CMHC)
                            </option>
                          </optgroup>

                          {/* Clinic Types */}
                          <optgroup label="Clinic Types">
                            <option value="Primary Care Clinic">
                              Primary Care Clinic
                            </option>
                            <option value="Family Medicine Clinic">
                              Family Medicine Clinic
                            </option>
                            <option value="Pediatric Clinic">
                              Pediatric Clinic
                            </option>
                            <option value="Women’s Health / OB-GYN Clinic">
                              Women’s Health / OB-GYN Clinic
                            </option>
                            <option value="Geriatric Clinic">
                              Geriatric Clinic
                            </option>
                            <option value="Internal Medicine Clinic">
                              Internal Medicine Clinic
                            </option>
                            <option value="Outpatient Behavioral Health Clinic">
                              Outpatient Behavioral Health Clinic
                            </option>
                            <option value="Psychiatric Medication Management Clinic">
                              Psychiatric Medication Management Clinic
                            </option>
                            <option value="Substance Use / Addiction Treatment Clinic">
                              Substance Use / Addiction Treatment Clinic
                            </option>
                            <option value="Telehealth Clinic">
                              Telehealth Clinic
                            </option>
                            <option value="Walk-In / Urgent Care Clinic">
                              Walk-In / Urgent Care Clinic
                            </option>
                            <option value="Integrative or Functional Medicine Clinic">
                              Integrative or Functional Medicine Clinic
                            </option>
                            <option value="HIV / Infectious Disease Clinic">
                              HIV / Infectious Disease Clinic
                            </option>
                            <option value="Pain Management Clinic">
                              Pain Management Clinic
                            </option>
                            <option value="Dermatology Clinic">
                              Dermatology Clinic
                            </option>
                            <option value="Neurology Clinic">
                              Neurology Clinic
                            </option>
                            <option value="Cardiology Clinic">
                              Cardiology Clinic
                            </option>
                            <option value="Oncology Clinic">
                              Oncology Clinic
                            </option>
                            <option value="Sleep Medicine Clinic">
                              Sleep Medicine Clinic
                            </option>
                            <option value="School-Based Health Clinic">
                              School-Based Health Clinic
                            </option>
                          </optgroup>

                          {/* Group Types */}
                          <optgroup label="Group Types / Practice Models">
                            <option value="Solo Practice">Solo Practice</option>
                            <option value="Group Practice – Single Specialty">
                              Group Practice – Single Specialty
                            </option>
                            <option value="Group Practice – Multi-Specialty">
                              Group Practice – Multi-Specialty
                            </option>
                            <option value="Integrated Health Group (MD + Behavioral Health + Other Services)">
                              Integrated Health Group (MD + Behavioral Health +
                              Other Services)
                            </option>
                            <option value="Private Mental Health Group">
                              Private Mental Health Group
                            </option>
                            <option value="Federally Qualified Health Center (FQHC)">
                              Federally Qualified Health Center (FQHC)
                            </option>
                            <option value="Rural Health Clinic (RHC)">
                              Rural Health Clinic (RHC)
                            </option>
                            <option value="Behavioral Health Organization (BHO)">
                              Behavioral Health Organization (BHO)
                            </option>
                            <option value="Accountable Care Organization (ACO)">
                              Accountable Care Organization (ACO)
                            </option>
                            <option value="Direct Primary Care Group">
                              Direct Primary Care Group
                            </option>
                            <option value="Concierge Medical Group">
                              Concierge Medical Group
                            </option>
                            <option value="Mobile Health Team">
                              Mobile Health Team
                            </option>
                            <option value="Telehealth Group Practice">
                              Telehealth Group Practice
                            </option>
                            <option value="Staffing Agency for Facilities">
                              Staffing Agency for Facilities
                            </option>
                            <option value="Clinic-as-a-Service Platform (for virtual providers)">
                              Clinic-as-a-Service Platform (for virtual
                              providers)
                            </option>
                          </optgroup>
                        </select>

                        {errors.noteType && (
                          <p className="error-message">
                            {errors.noteType.message}
                          </p>
                        )}
                      </div>
                      <Inputbox
                        label="Facility NPI Number"
                        type="text"
                        placeholder="Enter Facility NPI Number"
                        name="NPI"
                        className="Custom_input"
                        register={register}
                      />
                      <p style={{ color: "red", textAlign: "left" }}>
                        {errors.NPI?.message}
                      </p>
                      <Inputbox
                        label="Facility Contact Person"
                        type="text"
                        placeholder="Enter Facility Contact Person"
                        name="facilityContact"
                        className="Custom_input"
                        register={register}
                      />
                      <Inputbox
                        label="Facility Phone Number"
                        type="text"
                        placeholder="Enter Facility Phone Number"
                        name="facilityPhone"
                        className="Custom_input"
                        register={register}
                      />
                      <Inputbox
                        label="Facility Email"
                        type="text"
                        placeholder="Enter Facility Email"
                        name="facilityEmail"
                        className="Custom_input"
                        register={register}
                      />
                      <Inputbox
                        label="Facility Address"
                        type="text"
                        placeholder="Enter Facility Address"
                        name="facilityAddress"
                        className="Custom_input"
                        register={register}
                      />
                    </>
                  )}
                </>
                <button
                  type="submit"
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    alignSelf: "center",
                    width: "100%",
                    height: "50px",
                  }}
                  className="Save_buttonnn"
                >
                  Save & Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {selectedFacility === "existingFacility" && (
        <FacilitySelectionmodalTwo
          close={() => {
            setShowModal(false);
            setSelectedFacility("enterFacility");
          }}
          GetFacility={GetFacility}
        />
      )}
      {showfacilitymodal && (
        <FacilitySelectionmodal
          close={() => setshowfacilitymodal(false)}
          GetFacility={GetFacility}
          selectedData={selectedData}
          editData={editData}
          editView={editView}
        />
      )}
    </div>
  );
};

export default Practice;
const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};
const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};

const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
