import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  Addpatientlaborder,
  Deletepatientlaborder,
  FetchCurrentDiagnosis,
  FetchCurrentLabs,
  GetDoctorsRequest,
  Getpatientlaborder,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import { Modal, Box } from "@mui/material";
import Inputbox from "../../../Components/Inputbox";

const validationSchema = Yup.object().shape({
  OrderingProvider: Yup.string().required("Ordering Provider is required"),
  ResponsibleForProcessing: Yup.string().required(
    "Responsible for Processing is required"
  ),
  Stat: Yup.string().required("Stat selection is required"),
  NotesToOfficeStaff: Yup.string().optional(),
  Diagnoses: Yup.string().required("Diagnoses is required"),
  Labs: Yup.string().required("Labs field is required"),
});
const AddLabOrder = ({ info }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [ismodal, setIsModal] = useState(false);
  const [data, setdata] = useState([]);
  const [doctorlist, setdoctorlist] = useState([]);
  const profileData = useSelector((state) => state?.cookies?.userDetails);
  const [modal, setmodal] = useState(false);
  const [labmodal, setlabmodal] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [searchlabs, setsearchlabs] = useState("")
  const [selectedlab, setselectedlab] = useState("")
  const [selecteddiagosis, setselecteddiagosis] = useState(null);
    const [CurrentLabdata, setCurrentLabdata] = useState({
      data: [],
      pagination: { totalPages: 0 },
    });
  const [CurrentDiagnosisdata, setCurrentDiagnosisdata] = useState({
    data: [],
    pagination: { totalPages: 0 },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentlabspage, setcurrentlabspage] = useState(1)
  const pageSize = 50;
  const openModal = () => {
    setIsModal(true);
  };
  const CloseModal = () => {
    reset();
    setIsModal(false);
  };
  const FetchDoctorrequest = async () => {
    try {
      const response = await GetDoctorsRequest({ status: "approved" });
      // console.log(response?.data.data.data, "heloo-----");
      setdoctorlist(response?.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = async (data) => {
    const payload = {
      ...data,
      DiagnosisId: selecteddiagosis?._id,
      sampleLabId:selectedlab?._id,
      agencyIds: profileData?._id || "",
      patientId: info?.patientId?._id || "",
      Stat: data.Stat === "Yes",
    };

    console.log("Transformed Data:", payload);
    try {
      const respo = await Addpatientlaborder(payload);
      ShowToast(respo?.data?.message);
      Getdata();
      CloseModal();
    } catch (error) {
      console.log(error);
      CloseModal();
    }
  };
  const Getdata = async () => {
    try {
      const respo = await Getpatientlaborder(info?.patientId?._id);
      console.log(respo?.data?.data?.data, "respo======");
      setdata(respo?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Getdata();
    FetchDoctorrequest();
  }, []);
  const handleRemove = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this Lab Orders?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const respo = await Deletepatientlaborder(id);
      ShowToast(respo?.data?.message);
      Getdata();
    } catch (error) {
      console.log(error);
    }
  };
  const GetCurrentDiagnosis = async (search = "", page = 1) => {
    try {
      const response = await FetchCurrentDiagnosis(search, page, pageSize);
      if (response?.data?.data) {
        setCurrentDiagnosisdata({
          data: response.data.data,
          pagination: response.data.data.pagination || { totalPages: 0 }, // Safeguard
        });
      } else {
        console.error("Unexpected API response structure");
        setCurrentDiagnosisdata({ data: [], pagination: { totalPages: 0 } });
      }
    } catch (error) {
      console.error("Error fetching admin insurance data", error);
      setCurrentDiagnosisdata({ data: [], pagination: { totalPages: 0 } });
    }
  };

  useEffect(() => {
    GetCurrentDiagnosis(searchTerm, currentPage);
  }, [searchTerm, currentPage]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    GetCurrentDiagnosis(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    GetCurrentDiagnosis(searchTerm, page);
  };
  const handelChange = (diagnosis) => {
    setselecteddiagosis(diagnosis);
    setValue("Diagnoses", diagnosis?.code);
    trigger("Diagnoses");
    setmodal(false);
    setCurrentPage(1);
    setSearchTerm("");
  };
  const Getlabs = async (search = "", page = 1) => {
    try {
      const response = await FetchCurrentLabs(search, page, pageSize);
      if (response?.data?.data) {
        setCurrentLabdata({
          data: response.data.data,
          pagination: response.data.data.pagination || { totalPages: 0 },
        });
      } else {
        console.error("Unexpected API response structure");
        setCurrentLabdata({ data: [], pagination: { totalPages: 0 } });
      }
    } catch (error) {
      console.error("Error fetching admin insurance data", error);
      setCurrentLabdata({ data: [], pagination: { totalPages: 0 } });
    }
  };
  useEffect(() => {
    Getlabs(searchlabs, currentlabspage);
  }, [searchlabs, currentlabspage]);
  const handlelabsSearchChange = (e) => {
    setsearchlabs(e.target.value);
    Getlabs(e.target.value);
  };
  const handlelabsPageChange = (page) => {
    setcurrentlabspage(page);
    Getlabs(searchTerm, page);
  };
  const handellabsChange = (diagnosis) => {
    console.log(diagnosis,"hello=======")
    setselectedlab(diagnosis);
    setValue("Labs", diagnosis?.name);
    trigger("Labs");
    setlabmodal(false);
    setcurrentlabspage(1);
    setsearchlabs("");
  };
  return (
    <div>
      <div className="container_NotesTabScreen">
        <h1>Lab Orders</h1>
        <button
          className="add-button_NotesTabScreen"
          style={{ float: "right" }}
          onClick={openModal}
        >
          Add Lab Order
        </button>

        <div
          className="tabs-container__three"
          style={{ display: "flex", width: "100%", justifyContent: "left" }}
        />

        {ismodal && (
          <div className="modal_AddAllergy" id="addNoteModal">
            <h2>Add Lab Order</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row_AddAllergy">
                <div className="form-group_AddAllergy">
                  <label htmlFor="ordering-provider">Ordering Provider</label>
                  <select
                    id="ordering-provider"
                    {...register("OrderingProvider")}
                  >
                    <option value="">Select provider</option>
                    {doctorlist.map((doctor) => (
                      <option key={doctor._id} value={doctor?.doctorId._id}>
                        {doctor.firstName} {doctor.lastName}
                      </option>
                    ))}
                  </select>
                  <p className="error-message">
                    {errors.OrderingProvider?.message}
                  </p>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="processing-responsible">
                    Responsible for Processing
                  </label>
                  <select
                    id="processing-responsible"
                    {...register("ResponsibleForProcessing")}
                  >
                    <option value="">Select responsible</option>
                    {doctorlist.map((doctor) => (
                      <option key={doctor._id} value={doctor?.doctorId._id}>
                        {doctor.firstName} {doctor.lastName}
                      </option>
                    ))}
                  </select>
                  <p className="error-message">
                    {errors.ResponsibleForProcessing?.message}
                  </p>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="Stat">Stat</label>
                  <select id="Stat" {...register("Stat")}>
                    <option value="">Select</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <p className="error-message">{errors.Stat?.message}</p>
                </div>

                <div className="form-group_AddAllergy">
                  <label htmlFor="NotesToOfficeStaff">
                    Notes to Office Staff
                  </label>
                  <textarea
                    id="NotesToOfficeStaff"
                    placeholder="Enter NotesToOfficeStaff"
                    rows={2}
                    {...register("NotesToOfficeStaff")}
                  />
                </div>
                <div className="form-group_ProblemsScreen">
                  <div
                    onClick={() => {
                      setmodal(true);
                    }}
                  >
                    <label>{"Diagnosis"}</label>
                    <div
                      style={{
                        border: "1px solid grey",
                        width: "100%",
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {selecteddiagosis?.code || "Select Current Diagnosis"}
                    </div>
                    <input
                      type="hidden"
                      {...register("Diagnoses")}
                      value={selecteddiagosis?.code}
                    />
                    <p className="error-message">{errors.Diagnoses?.message}</p>
                  </div>
                </div>
                <div className="form-group_ProblemsScreen">
                  <div
                    onClick={() => {
                      setlabmodal(true);
                    }}
                  >
                    <label>{"Labs"}</label>
                    <div
                      style={{
                        border: "1px solid grey",
                        width: "100%",
                        height: 40,
                        borderRadius: 5,
                        paddingLeft: 10,
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {selectedlab?.name || "Select Current Labs"}
                    </div>
                    <input
                      type="hidden"
                      {...register("Labs")}
                      value={selectedlab?.name}
                    />
                    <p className="error-message">{errors.Labs?.message}</p>
                  </div>
                </div>
                {/* <div className="form-group_AddAllergy">
                  <label htmlFor="Labs">Labs</label>
                  <input
                    type="text"
                    id="Labs"
                    placeholder="Search Labs"
                    {...register("Labs")}
                  />
                  <p className="error-message">{errors.Labs?.message}</p>
                </div> */}
              </div>

              <div className="modal-footer_AddAllergy">
                <button
                  type="button"
                  className="cancel-button_AddAllergy"
                  onClick={CloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button_AddAllergy"
                  style={{ marginLeft: 10 }}
                >
                  Create Lab Order
                </button>
              </div>
            </form>
          </div>
        )}

        <div className="notes_style">
          {/* <thead>
              <tr>
                <th>Test</th>
                <th>Status</th>
                <th>Result</th>
                <th>Prior Results</th>
                <th>Ordering Provider</th>
                <th>Processing Responsible</th>
                <th>Stat</th>
                <th>Action</th>
              </tr>
            </thead> */}
          <table>
            <thead>
              <tr>
                <th>Diagnoses</th>
                <th>Labs</th>
                <th>Notes to Office Staff</th>
                {/* <th>Prior Results</th> */}
                <th>Ordering Provider</th>
                <th>Processing Responsible</th>
                <th>Stat</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan="10">No data found</td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <tr key={item._id || index}>
                    <td>
                      Name: {item.DiagnosisId?.sortDescription}<br/>
                      Code: {item?.DiagnosisId?.code}
                    </td>
                    <td>{item?.sampleLabId?.name}</td>
                    <td>{item.NotesToOfficeStaff}</td>

                    <td>
                      {item.OrderingProvider?.firstName +
                        " " +
                        item.OrderingProvider?.lastName}
                    </td>
                    <td>
                      {item.ResponsibleForProcessing?.firstName +
                        " " +
                        item.ResponsibleForProcessing?.lastName}
                    </td>
                    <td>{item.Stat === true ? "Yes" : "No"}</td>
                    <td>
                      <button
                        className="btn"
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => handleRemove(item._id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={modal}
        onClose={() => {
          setmodal(false);
        }}
        aria-labelledby="modal-title"
      >
        <Box sx={modalinsuranceStyle}>
          <div>
            <Inputbox
              label={"Search Diagnosis"}
              placeholder={"Type to search..."}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div style={{ marginTop: 20 }}>
              {CurrentDiagnosisdata?.data?.data?.length > 0 ? (
                CurrentDiagnosisdata?.data?.data?.map((insurance) => (
                  <div
                    key={insurance._id}
                    className="diagnosis-item"
                    onClick={() => handelChange(insurance)}
                  >
                    <div style={{ fontSize: 20 }}>{insurance.PayerName}</div>
                    <div>
                      <strong>Code:</strong> {insurance.code}
                    </div>
                    <div>
                      <strong>Name:</strong> {insurance.sortDescription}
                    </div>
                  </div>
                ))
              ) : (
                <p>No Diagnosis data available.</p>
              )}
            </div>

            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Previous Button */}
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: currentPage === 1 ? "#ccc" : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor: currentPage === 1 ? "not-allowed" : "pointer",
                }}
              >
                Previous
              </button>

              <span
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #007BFF",
                }}
              >
                {currentPage} of {CurrentDiagnosisdata?.data?.totalPages}
              </span>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage === CurrentDiagnosisdata?.pagination?.totalPages
                }
                style={{
                  margin: "5px",
                  padding: "10px 20px",
                  backgroundColor:
                    currentPage === CurrentDiagnosisdata?.pagination?.totalPages
                      ? "#ccc"
                      : "#007BFF",
                  color: "#fff",
                  border: "1px solid #007BFF",
                  cursor:
                    currentPage === CurrentDiagnosisdata?.pagination?.totalPages
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
          open={labmodal}
          onClose={() => {
            setlabmodal(false);
          }}
          aria-labelledby="modal-title"
        >
          <Box sx={modalinsuranceStyle}>
            <div>
              <Inputbox
                label={"Search Lab"}
                placeholder={"Type to search..."}
                value={searchlabs}
                onChange={handlelabsSearchChange}
              />

              <div style={{ marginTop: 20 }}>
                {CurrentLabdata?.data?.data?.length > 0 ? (
                  CurrentLabdata?.data?.data?.map((insurance) => (
                    <div
                      key={insurance._id}
                      className="diagnosis-item"
                      onClick={() => handellabsChange(insurance)}
                    >
                      <div style={{ fontSize: 20 }}>{insurance.PayerName}</div>
                      <div>
                        <strong>Code:</strong> {insurance.code}
                      </div>
                      <div>
                        <strong>Name:</strong> {insurance.name}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No Lab data available.</p>
                )}
              </div>

              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Previous Button */}
                <button
                  onClick={() => handlelabsPageChange(currentlabspage - 1)}
                  disabled={currentlabspage === 1}
                  style={{
                    margin: "5px",
                    padding: "10px 20px",
                    backgroundColor: currentlabspage === 1 ? "#ccc" : "#007BFF",
                    color: "#fff",
                    border: "1px solid #007BFF",
                    cursor: currentlabspage === 1 ? "not-allowed" : "pointer",
                  }}
                >
                  Previous
                </button>

                <span
                  style={{
                    margin: "5px",
                    padding: "10px 20px",
                    backgroundColor: "#fff",
                    color: "#000",
                    border: "1px solid #007BFF",
                  }}
                >
                  {currentlabspage} of {CurrentLabdata?.data?.totalPages}
                </span>

                <button
                  onClick={() => handlelabsPageChange(currentPage + 1)}
                  disabled={
                    currentlabspage ===
                    CurrentLabdata?.pagination?.totalPages
                  }
                  style={{
                    margin: "5px",
                    padding: "10px 20px",
                    backgroundColor:
                    currentlabspage ===
                      CurrentLabdata?.pagination?.totalPages
                        ? "#ccc"
                        : "#007BFF",
                    color: "#fff",
                    border: "1px solid #007BFF",
                    cursor:
                    currentlabspage ===
                      CurrentLabdata?.pagination?.totalPages
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </Box>
        </Modal>
    </div>
  );
};

export default AddLabOrder;
const modalinsuranceStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto",
};
