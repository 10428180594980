import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  DeleteAuthorizations,
  GETAuthorizations,
  PatchAuthorizations,
  PostAuthorizations,
} from "../../../../../api/helper";
import { ShowToast } from "../../../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  Auth: Yup.string().required("Authorization number is required."),
  ServiceCode: Yup.string().required("Service code is required."),
  ServiceDescription: Yup.string().required("Service description is required."),
  AuthorizationStatus: Yup.string().required(
    "Authorization status is required."
  ),
  DateApproved: Yup.string().required("Date approved is required."),
  EffectiveStart: Yup.string().required("Effective start date is required."),
  EffectiveEnd: Yup.date()
    .nullable()
    .required("Effective end date is required."),
  UnitsPer: Yup.number()
    .positive("Units per must be a positive number.")
    .required("Units per is required."),
  UnitOfMeasure: Yup.string().required("Unit of measure is required."),
  UnitRate: Yup.string().required("Unit rate is required."),
  MaxUnits: Yup.number()
    .positive("Max units must be a positive number.")
    .required("Max units is required."),
  AmountApproved: Yup.string().required("Amount approved is required."),
});

const AuthorizationsScreen = ({ info }) => {
  const [authorization, setauthorization] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [modalopen, setmodalopen] = useState(false);
  const [selectedAuthorization, setSelectedAuthorization] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  // console.log(authorization,"authorization===")
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (modalopen) {
      if (selectedAuthorization) {
        reset(selectedAuthorization);
      } else {
        reset({});
      }
    }
  }, [modalopen, selectedAuthorization]);
  const handleEdit = (authData) => {
    setSelectedAuthorization(authData);
    setIsEditMode(true);
    setmodalopen(true);
  };

const handleAddNew = () => {
  setSelectedAuthorization(null);
  setIsEditMode(false);
  setmodalopen(true);
  
  setTimeout(() => {
    reset({});
  }, 0);
};

const handleCloseModal = () => {
  setmodalopen(false);
  reset({}); // Ensure form resets on close
};

  const onSubmit = async (data) => {
    // console.log("Form Data:", data);
    data.agencyId = profiledata?._id;
    data.patientId = info?.patientId?._id;
    console.log(data, "data======");
    try {
      let response;
      if (isEditMode && selectedAuthorization) {
        response = await PatchAuthorizations(selectedAuthorization._id, data);
      } else {
        console.log("hitpost====");
        response = await PostAuthorizations(data);
      }

      ShowToast(response?.data?.message);
      console.log(response?.data?.data);
      getinsurencedetails();
      setmodalopen(false);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const getinsurencedetails = async () => {
    try {
      const response = await GETAuthorizations(profiledata?._id);
      // console.log(response?.data?.data?.data, "hello=====");
      setauthorization(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getinsurencedetails();
  }, []);
  const handleRemove = async (id) => {
    if (window.confirm("Are you sure you want to remove?")) {
      console.log("Removed insurance with ID:", id);
      try {
        const response = await DeleteAuthorizations(id);
        ShowToast(response?.data?.message);
        getinsurencedetails();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <button className="add-button_insuranceDetails" onClick={handleAddNew}>
        Add New Authorization
      </button>
      {modalopen && (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            position: "fixed",
            zIndex: 1000,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="modal_insuranceDetails">
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: 30,
                  cursor: "pointer",
                }}
              onClick={handleCloseModal}
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                  style={{ height: 20, width: 20 }}
                />
              </div>
            </div>
            <h2>
              {isEditMode
                ? "Edit Authorization Details"
                : "Authorization Details"}
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row_insuranceDetails">
                <div className="form-column_insuranceDetails">
                  <label htmlFor="auth-number">Auth # [Box 23]</label>
                  <input
                    type="text"
                    id="auth-number"
                    placeholder="Enter authorization number"
                    {...register("Auth")}
                  />
                  {errors.Auth && (
                    <p className="error">{errors.Auth.message}</p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="service-code">Service Code</label>
                  <input
                    type="text"
                    id="service-code"
                    placeholder="Enter service code"
                    {...register("ServiceCode")}
                  />
                  {errors.ServiceCode && (
                    <p className="error">{errors.ServiceCode.message}</p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="service-description">
                    Service Description
                  </label>
                  <input
                    type="text"
                    id="service-description"
                    placeholder="Enter service description"
                    {...register("ServiceDescription")}
                  />
                  {errors.ServiceDescription && (
                    <p className="error">{errors.ServiceDescription.message}</p>
                  )}
                </div>
              </div>
              <div className="form-row_insuranceDetails">
                <div className="form-column_insuranceDetails">
                  <label htmlFor="authorization-status">
                    Authorization Status
                  </label>
                  <select
                    id="authorization-status"
                    {...register("AuthorizationStatus")}
                  >
                    <option value="Approved">Approved</option>
                    <option value="Pending">Pending</option>
                    <option value="Denied">Denied</option>
                  </select>
                  {errors.AuthorizationStatus && (
                    <p className="error">
                      {errors.AuthorizationStatus.message}
                    </p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="date-approved">Date Approved</label>
                  <input
                    type="date"
                    id="date-approved"
                    {...register("DateApproved")}
                  />
                  {errors.DateApproved && (
                    <p className="error">{errors.DateApproved.message}</p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="effective-start">Effective Start</label>
                  <input
                    type="date"
                    id="effective-start"
                    {...register("EffectiveStart")}
                  />
                  {errors.EffectiveStart && (
                    <p className="error">{errors.EffectiveStart.message}</p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="effective-end">Effective End</label>
                  <input
                    type="date"
                    id="effective-end"
                    {...register("EffectiveEnd")}
                  />
                  {errors.EffectiveEnd && (
                    <p className="error">{errors.EffectiveEnd.message}</p>
                  )}
                </div>
              </div>
              <div className="form-row_insuranceDetails">
                <div className="form-column_insuranceDetails">
                  <label htmlFor="units-per">Units Per</label>
                  <input
                    type="text"
                    id="units-per"
                    placeholder="e.g., 1.00"
                    {...register("UnitsPer")}
                  />
                  {errors.UnitsPer && (
                    <p className="error">{errors.UnitsPer.message}</p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="unit-of-measure">Unit of Measure</label>
                  <select id="unit-of-measure" {...register("UnitOfMeasure")}>
                    <option value="Quarter-Hour">Quarter-Hour</option>
                    <option value="Hour">Hour</option>
                    <option value="Session">Session</option>
                  </select>
                  {errors.UnitOfMeasure && (
                    <p className="error">{errors.UnitOfMeasure.message}</p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="unit-rate">Unit Rate</label>
                  <input
                    type="text"
                    id="unit-rate"
                    placeholder="e.g., $2,440.65"
                    {...register("UnitRate")}
                  />
                  {errors.UnitRate && (
                    <p className="error">{errors.UnitRate.message}</p>
                  )}
                </div>
              </div>
              <div className="form-row_insuranceDetails">
                <div className="form-column_insuranceDetails">
                  <label htmlFor="max-units">Max Units</label>
                  <input
                    type="text"
                    id="max-units"
                    placeholder="e.g., 12"
                    {...register("MaxUnits")}
                  />
                  {errors.MaxUnits && (
                    <p className="error">{errors.MaxUnits.message}</p>
                  )}
                </div>
                <div className="form-column_insuranceDetails">
                  <label htmlFor="amount-approved">Amount Approved</label>
                  <input
                    type="text"
                    id="amount-approved"
                    placeholder="e.g., $29,287.80"
                    {...register("AmountApproved")}
                  />
                  {errors.AmountApproved && (
                    <p className="error">{errors.AmountApproved.message}</p>
                  )}
                </div>
              </div>
              <button type="submit" onclick="saveAuthorization()">
                Save Authorization
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="authorization-list">
        <h2>Authorization List</h2>
        <table>
          <thead>
            <tr>
              <th>Auth #</th>
              <th>Service Code</th>
              <th>Status</th>
              <th>Effective Start</th>
              <th>Effective End</th>
              <th>Units Per</th>
              <th>Unit Rate</th>
              <th>Max Units</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody id="insurance-table-body">
            {authorization.map((item) => (
              <tr key={item._id}>
                <td>{item.Auth}</td>
                <td>{item.ServiceCode}</td>
                <td>{item.AuthorizationStatus}</td>
                <td>{item.EffectiveStart}</td>
                <td>{item.EffectiveEnd}</td>
                <td>{item.UnitsPer}</td>
                <td>{item.UnitRate}</td>
                <td>{item.MaxUnits}</td>
                <td>
                  <button
                    className="check-eligibility_insuranceDetails"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </button>
                  <button
                    style={{ marginLeft: 10 }}
                    onClick={() => handleRemove(item._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    fontFamily: "'Arial', sans-serif",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "100%",
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
    color: "#555",
    marginBottom: "5px",
  },
  removeButton: {
    backgroundColor: "#ff4d4f",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "10px 15px",
    cursor: "pointer",
    fontWeight: "bold",
  },
};

export default AuthorizationsScreen;
