import React, { useEffect, useState } from 'react'
import { FetchQuestionnaireList } from '../../../../../api/helper';
import QuestionaireGraphCard from './QuestionaireGraphCard';
import { useSelector } from 'react-redux';

function OtherScoresMain() {
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [showGraph, setShowGraph] = useState("");
    const [questionnaires, setquestionnaires] = useState([]);
    const [loading, setLoading] = useState(false);
    const profiledata = useSelector((state) => state?.cookies?.userDetails);

    useEffect(() => {
        const storedQuestionnaires = localStorage.getItem("selectedQuestionnaires");
        if (storedQuestionnaires) {
          setSelectedQuestions(JSON.parse(storedQuestionnaires));
        }
      }, []);

    const GetQuestionnairelist = async () => {
        try {
            setLoading(true) 
            const response = await FetchQuestionnaireList(`agencyId=${profiledata._id}`);
            setquestionnaires(response?.data?.questionnaires);
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false)   
        }

    };
    useEffect(() => {
        GetQuestionnairelist()
    }, [])

    return (
        <>
            <div style={{ padding: 10 }}>
                <select name="" id="" className="custom-select_two" onChange={(e) => {
                    setShowGraph(e.target.value);
                }} >
                    <option value="">Select a question for see graph</option>
                    {questionnaires?.map((item, index) => {
                        if (selectedQuestions?.some((question) => question === item.id)) {
                            return (
                                <option key={index} value={item.id}>
                                    {item.label}
                                </option>
                            );
                        }
                        // return (
                        //   <option key={index} value={item.id}>
                        //     {item.label}
                        //   </option>
                        // );
                    })}
                </select>
            </div>
            <div>
                {showGraph !== "" && (
                    <QuestionaireGraphCard
                        loading={loading}
                        setLoading={setLoading}
                        showGraph={showGraph}
                        selecQuestionnaires={questionnaires}
                    />
                )}
            </div>
        </>
    )
}

export default OtherScoresMain