import React, { useEffect, useState } from 'react'

function WeightFactor({ initialValue, onUpdate, cateId }) {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(false);
    const [calculateWeight, setCalculateWeight] = useState(0);
    const handleEdit = async () => {
      if (isEditing) {
        setIsLoading(true);
        try {
          await onUpdate(value, cateId);
          setIsEditing(false);
        } catch (error) {
          console.error("Failed to update weight factor:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsEditing(true);
      }
    };
  
    useEffect(() => {
      if (value) {
        setCalculateWeight(calculateWeight + Number.parseFloat(value));
      }
    }, [value]);

  
    return (
      <td className="border border-gray-200 p-2 text-center">
        <div className="flex items-center justify-center space-x-2">
          <input
            type="number"
            step="0.01"
            min="0.00"
            max="1.00"
            value={value}
            onChange={(e) => setValue(Number.parseFloat(e.target.value))}
            disabled={!isEditing}
            className={`w-20 rounded px-2 py-1 text-center outline-none focus:border-primaryBlue
              ${
                isEditing
                  ? "border border-gray-300 bg-white"
                  : "border-none bg-gray-50 text-gray-700"
              }`}
          />
          <button
            onClick={handleEdit}
            disabled={isLoading}
            className={`rounded  px-3 py-1 text-sm font-medium text-white transition-colors
              ${
                isEditing
                  ? "bg-green-500 hover:bg-green-600"
                  : "bg-yellow-500 hover:bg-yellow-600"
              } ${isLoading ? "cursor-not-allowed opacity-50" : ""}`}
          >
            {isLoading ? "Saving..." : isEditing ? "Save" : "Edit"}
          </button>
        </div>
      </td>
    );
}

export default WeightFactor