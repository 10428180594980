// import React from 'react'

// const ClientExternal = () => {
//   return (
//     <View>
//       <Text>ClientExternal</Text>
//     </View>
//   )
// }

// export default ClientExternal
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import {
  ClinicTeammembers,
  FacilityTeamembers,
  FetchDropdownFacility,
  FetchFacility,
  FetchParactice,
  GetFacilityPractice,
  GetFacilityTwo,
  GetProviderType,
  PatientAddTeam,
  PatientgetTeam,
  PatientUpdateTeam,
} from "../../../../api/helper";
import { ShowToast } from "../../../../helpers/ToastService";
import {
  DropDownManuCustom,
  DropDownManuCustomLinked,
} from "../../../Components/DropDownButton";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  // practice: Yup.string().required("Practice is required"),
  // providerType: Yup.string().required("Provider Type is required"),
  // teamMember: Yup.string().required("Team Member is required"),
  fromDate: Yup.date()
    .typeError("Please select a valid From Date")
    .required("From Date is required"),
  toDate: Yup.date()
    .typeError("Please select a valid To Date")
    .min(Yup.ref("fromDate"), "To Date cannot be before From Date")
    .required("To Date is required"),
  lastSeenDate: Yup.date()
    .typeError("Please select a valid Last Seen Date")
    .required("Last Seen Date is required"),
});
const ClientExternal = ({
  info,
  closeModal,
  Fetchteammember,
  selectedMember,
}) => {
  const [data, setdata] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [teammemberdata, setteammemberdata] = useState([]);
  const [paracticedata, setparacticedata] = useState([]);
  const pageSize = 10000;
  const [selectedPracticeIndex, setSelectedPracticeIndex] = useState(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const GetAppoientmenttype = async () => {
    const response = await GetProviderType(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };

  useEffect(() => {
    GetAppoientmenttype();
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      // practice: selectedMember?.practice || "",
      // providerType: selectedMember?.providerType || "",
      // teamMember: selectedMember?.teamMember || "",
      fromDate: selectedMember?.fromDate
        ? new Date(selectedMember.fromDate).toISOString().split("T")[0]
        : "",
      toDate: selectedMember?.toDate
        ? new Date(selectedMember.toDate).toISOString().split("T")[0]
        : "",
      lastSeenDate: selectedMember?.lastSeenDate
        ? new Date(selectedMember.lastSeenDate).toISOString().split("T")[0]
        : "",
    },
  });
  useEffect(() => {
    if (selectedMember) {
      // setValue("practice", selectedMember.practice || "");
      // setValue("providerType", selectedMember.providerType || "");
      // setValue("teamMember", selectedMember.teamMember || "");
      setValue(
        "fromDate",
        selectedMember.fromDate
          ? new Date(selectedMember.fromDate).toISOString().split("T")[0]
          : ""
      );
      setValue(
        "toDate",
        selectedMember.toDate
          ? new Date(selectedMember.toDate).toISOString().split("T")[0]
          : ""
      );
      setValue(
        "lastSeenDate",
        selectedMember.lastSeenDate
          ? new Date(selectedMember.lastSeenDate).toISOString().split("T")[0]
          : ""
      );
    }
  }, [selectedMember]);
  const onSubmit = async (data) => {
    data.agencyId = info?._id;
    data.memberType = "external";
    console.log("Submitted Data:=======>", data);
    // return
    try {
      let response;

      if (selectedMember) {
        response = await PatientUpdateTeam(data, selectedMember?._id);
      } else {
        response = await PatientAddTeam(data);
        reset();
      }
      // const response = await PatientAddTeam(data);
      // console.log(response?.data, "response======facility---");
      ShowToast(response?.data?.message);
      reset();
      Fetchteammember();
      closeModal();
    } catch (error) {
      console.log(error);
      closeModal();
    }
    closeModal();
  };
  const GetFacility = async (page = 1) => {
    try {
      const Respo = await FetchDropdownFacility(profiledata?._id, pageSize, page);
      const data = Respo?.data?.data?.facilities?.map((e) => ({ name: e.facilityName, ...e }));
      setparacticedata(data || []);
      // setteammemberdata(data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePracticeSelect = async (selectedOption) => {
    console.log(selectedOption._id, '==>id')
    try {
      const Respo = await GetFacilityPractice(profiledata?._id, pageSize, 1, selectedOption._id);
      const data = Respo?.data?.data?.facilities?.map((e) => {
        return {
          name: `${e.fullName}`,
          ...e,
        };
      });
      
      setteammemberdata(data || []);
    } catch (error) {
      console.log(error, '===error')
    }


    // const index = paracticedata.findIndex(
    //   (item) => item._id === selectedOption._id
    // );

    // setSelectedPracticeIndex(index);

    // if (index !== -1) {
    //   const selectedMember = teammemberdata[index]?.fullName || "";
    //   setSelectedTeamMember(selectedMember);

    //   // ✅ Team Member field ka value update karo
    //   setValue("teamMember", selectedMember);
    //   clearErrors("teamMember"); // ✅ Agar error hai to hata do
    // }
  };

  // const GetteamemberFacility = async (page = 1) => {
  //   const id="67dd002ba610398e1e090965"
  //   try {
  //     const Respo = await GetFacilityPractice(profiledata?._id, pageSize, page,id);
  //     // console.log(Respo?.data?.data?.facilities, "hello=======d===");
  //     const data = Respo?.data?.data?.facilities?.map((e) => {
  //       return {
  //         name: `${e.facilityName}`,
  //         ...e,
  //       };
  //     });
  //     setparacticedata(data || []);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    GetFacility();
    // GetteamemberFacility()
  }, []);

  return (
    <div id="external" className="tab-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <DropDownManuCustom
            label="Practice"
            placeholder="Select option"
            options={paracticedata}
            name="facilityId"
            dropdownicon
            register={register}
            id="_id"
            onSelect={handlePracticeSelect}
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
            defaultValue={selectedMember?.practice || ""}
          />
          {errors.practice && (
            <p className="error-message">{errors.practice.message}</p>
          )}
        </div>
        <div className="form-group">
          <DropDownManuCustom
            label="Provider Type"
            placeholder="Select option"
            options={data}
            name="providerTypeId"
            dropdownicon
            register={register}
            id="_id"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
            defaultValue={selectedMember?.providerType || ""}
          />
          {errors.providerType && (
            <p className="error-message">{errors.providerType.message}</p>
          )}
        </div>
        <div className="form-group">
          <DropDownManuCustom
            label="Team Member"
            placeholder="Select option"
            options={teammemberdata}
            name="facilityExternalTeamId"
            dropdownicon
            register={register}
            id="_id"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
            // disabled={true}
            defaultValue={selectedMember?.teamMember || ""}
          />
          {/* <div style={{ marginTop: -10 }}>
            <label>{"Team Member"}</label>
            <div
              className="input-wrapper"
              // disabled={true}
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "rgba(1, 20, 52, 0.5)",
                height: 42,
                width: "100%",
                borderRadius: 5,
                fontSize: `17px`,
                backgroundColor: "lightgrey",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingLeft: 15,
              }}
            >
              {selectedTeamMember || selectedMember?.teamMember || "Select option"}
            </div>
          </div> */}
          {errors.teamMember && (
            <p className="error-message">{errors.teamMember.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -10 }}>
          <label htmlFor="from-date-external">From Date</label>
          <input
            type="date"
            id="from-date-external"
            {...register("fromDate")}
          />
          {errors.fromDate && (
            <p className="error-message">{errors.fromDate.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -13 }}>
          <label htmlFor="to-date-external">To Date</label>
          <input type="date" id="to-date-external" {...register("toDate")} />
          {errors.toDate && (
            <p className="error-message">{errors.toDate.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -13 }}>
          <label htmlFor="lastseen-date-external">Last Seen Date</label>
          <input
            type="date"
            id="lastseen-date-external"
            {...register("lastSeenDate")}
          />
          {errors.lastSeenDate && (
            <p className="error-message">{errors.lastSeenDate.message}</p>
          )}
        </div>
        <div className="form-buttons">
          <button className="cancel" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="save" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientExternal;
