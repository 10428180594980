import React, { useEffect, useState } from 'react'
import { Fetchactual } from '../../../../../api/helper';
import { interpretCompositeScore } from '../../../../../utils/main';
import { useSelector } from 'react-redux';
function Aleart({ compositeScore }) {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const patientDetails = useSelector((state) => state?.cookies?.patinetDetails)

  const [score, setScore] = useState(0);
  useEffect(() => {
    getScore();
  }, []);

  async function getScore() {
    const response = await Fetchactual(`agencyId=${profiledata._id}&patientId=${patientDetails._id}`);
    const data = response.data
    if (data?.totalScore) {
      setScore(data.totalScore[data.totalScore?.length - 1]);
    }
  }
  return (
    <>
      <div className="alert-box">
        <h3 className="section-title_two">ALERTS</h3>
        <div className="divider"></div>
        <p className="alert-message" style={{ padding: 10 }}>
          {interpretCompositeScore(
            compositeScore ? compositeScore : score?.totalScore
          )}
        </p>
      </div>

    </>
  )
}

export default Aleart