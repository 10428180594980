import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ShowToast } from "../../../../helpers/ToastService";
import {
  Clinicrequestlist,
  FetchFacility,
  FetchParactice,
  PatientAddTeam,
  PatientgetTeam,
  PatientUpdateTeam,
  GetFacilityTwo,
  FetchDropdownFacility,
  GetFacilityPractice,

} from "../../../../api/helper";
import { useSelector } from "react-redux";
import { DropDownManuCustom } from "../../../Components/DropDownButton";

const validationSchema = Yup.object().shape({
  // facility: Yup.string().required("Facility is required"),
  facilityId: Yup.string().required("Practice is required"),

  // facilityExternalTeamId:Yup.string().required("Team Member is required"),
  fromDate: Yup.date()
    .typeError("Please select a valid From Date")
    .required("From Date is required"),
  toDate: Yup.date()
    .typeError("Please select a valid To Date")
    .min(Yup.ref("fromDate"), "To Date cannot be before From Date")
    .required("To Date is required"),
  lastSeenDate: Yup.date()
    .typeError("Please select a valid Last Seen Date")
    .required("Last Seen Date is required"),
});

const ClientFacility = ({
  info,
  closeModal,
  Fetchteammember,
  selectedMember,
}) => {

  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [clinicdata, setClinicData] = useState([]);
  const [paracticedata, setparacticedata] = useState([]);
  const [teammemberdata, setteammemberdata] = useState([]);

  const getClinician = async () => {
    try {
      const response = await Clinicrequestlist();
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.companyName} / ${e.uniqueId}`,
          ...e,
        };
      });
      setClinicData(data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const GetPractice = async () => {
    const response = await FetchParactice();
    // console.log(response?.data?.data?.data, "data=====");
    //
    const data = response?.data?.data?.data?.map((e) => {
      return {
        name: `${e.facilityName}`,
        ...e,
      };
    });
    // setparacticedata(data || []);
  };
  useEffect(() => {
    GetPractice();
    getClinician();
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      practice: selectedMember?.practice || "",
      fromDate: selectedMember?.fromDate
        ? new Date(selectedMember.fromDate).toISOString().split("T")[0]
        : "",
      toDate: selectedMember?.toDate
        ? new Date(selectedMember.toDate).toISOString().split("T")[0]
        : "",
      lastSeenDate: selectedMember?.lastSeenDate
        ? new Date(selectedMember.lastSeenDate).toISOString().split("T")[0]
        : "",
    },
  });

  useEffect(() => {
    if (selectedMember) {
      setValue("practice", selectedMember.practice || "");
      setValue(
        "fromDate",
        selectedMember.fromDate
          ? new Date(selectedMember.fromDate).toISOString().split("T")[0]
          : ""
      );
      setValue(
        "toDate",
        selectedMember.toDate
          ? new Date(selectedMember.toDate).toISOString().split("T")[0]
          : ""
      );
      setValue(
        "lastSeenDate",
        selectedMember.lastSeenDate
          ? new Date(selectedMember.lastSeenDate).toISOString().split("T")[0]
          : ""
      );
    }
  }, [selectedMember]);

  const onSubmit = async (data) => {
    data.agencyId = info?._id;
    data.memberType = "facility";
    console.log("Submitted Data:", data);
    try {
      let response;

      if (selectedMember) {
        response = await PatientUpdateTeam(data, selectedMember?._id);
      } else {
        response = await PatientAddTeam(data);
        reset();
      }

      ShowToast(response?.data?.message);
      reset();
      Fetchteammember();
      closeModal();
    } catch (error) {
      console.log(error);
      closeModal();
    }
    closeModal();
  };
  const pageSize = 10000;

  const GetFacility = async (page = 1) => {
    try {
      const Respo = await FetchDropdownFacility(profiledata?._id, pageSize, page);
      const data = Respo?.data?.data?.facilities?.map((e) => ({ name: e.facilityName, ...e }));
      setparacticedata(data || []);
    } catch (error) {
      console.log(error);
    }
  };


  const handlePracticeSelect = async (selectedOption) => {
    try {
      const Respo = await GetFacilityPractice(profiledata?._id, pageSize, 1, selectedOption._id);
      const data = Respo?.data?.data?.facilities?.map((e) => {
        return {
          name: `${e.fullName}`,
          ...e,
        };
      });

      setteammemberdata(data || []);
    } catch (error) {
      console.log(error, '===error')
    }
  }

  useEffect(() => {
    GetFacility();
  }, []);
  return (
    <div id="facility" className="tab-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <DropDownManuCustom
            label="Practice"
            placeholder="Select option"
            options={paracticedata}
            name="facilityId"
            dropdownicon
            register={register}
            id="_id"
            onSelect={handlePracticeSelect}
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
            // defaultValue={selectedMember?.practice || ""}
          />
          {errors.facilityId && (
            <p className="error-message">{errors.facilityId.message}</p>
          )}
        </div>
{/* 
        <div className="form-group">
          <DropDownManuCustom
            label="Team Member"
            placeholder="Select option"
            options={teammemberdata}
            name="facilityExternalTeamId"
            dropdownicon
            register={register}
            id="_id"
            clearErrors={clearErrors}
            divstyle={{ marginTop: -5 }}
            // disabled={true}
            // defaultValue={selectedMember?.teamMember || ""}
          />
          
          {errors.facilityExternalTeamId && (
            <p className="error-message">{errors.facilityExternalTeamId.message}</p>
          )}
        </div> */}

        <div className="form-group" style={{ marginTop: -10 }}>
          <label htmlFor="from-date-facility">From Date</label>
          <input
            type="date"
            id="from-date-facility"
            {...register("fromDate")}
          />
          {errors.fromDate && (
            <p className="error-message">{errors.fromDate.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -13 }}>
          <label htmlFor="to-date-facility">To Date</label>
          <input type="date" id="to-date-facility" {...register("toDate")} />
          {errors.toDate && (
            <p className="error-message">{errors.toDate.message}</p>
          )}
        </div>
        <div className="form-group" style={{ marginTop: -13 }}>
          <label htmlFor="lastseen-date-facility">Last Seen Date</label>
          <input
            type="date"
            id="lastseen-date-facility"
            {...register("lastSeenDate")}
          />
          {errors.lastSeenDate && (
            <p className="error-message">{errors.lastSeenDate.message}</p>
          )}
        </div>
        <div className="form-buttons">
          <button className="cancel" type="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="save" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientFacility;
