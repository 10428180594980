export const Medicalhistoycategories =[
    {
      category: "head",
      label: "Head",
      options: {
        trauma: "Trauma",
        migraines: "Migraines",
        concussion: "Concussion",
        skullFracture: "Skull Fracture",
      },
    },
    {
      category: "eyes",
      label: "Eyes",
      options: {
        blindness: "Blindness",
        cataracts: "Cataracts",
        glaucoma: "Glaucoma",
        wearsGlassesContacts: "Wears glasses/contacts",
        eyeInfection: "Eye Infection",
      },
    },
    {
      category: "ears",
      label: "Ears",
      options: {
        hearingLoss: "Hearing Loss",
        tinnitus: "Tinnitus",
        earInfections: "Ear Infections",
        hearingAids: "Hearing Aids",
      },
    },
    {
      category: "noseSinuses",
      label: "Nose/Sinuses",
      options: {
        sinusInfection: "Sinus Infection",
        allergicRhinitis: "Allergic Rhinitis",
        nasalFracture: "Nasal Fracture",
        nasalPolyps: "Nasal Polyps",
      },
    },
    {
      category: "mouthThroatTeeth",
      label: "Mouth/Throat/Teeth",
      options: {
        gumDisease: "Gum Disease",
        toothDecay: "Tooth Decay",
        tonsillitis: "Tonsillitis",
        dentures: "Dentures",
      },
    },
    {
      category: "cardiovascular",
      label: "Cardiovascular",
      options: {
        hypertension: "Hypertension",
        angina: "Angina",
        heartAttack: "Heart Attack",
        dysrhythmia: "Dysrhythmia",
      },
    },
    {
      category: "respiratory",
      label: "Respiratory",
      options: {
        asthma: "Asthma",
        copd: "COPD",
        pneumonia: "Pneumonia",
        bronchitis: "Bronchitis",
      },
    },
    {
      category: "neurological",
      label: "Neurological",
      options: {
        seizures: "Seizures",
        stroke: "Stroke",
        migraines: "Migraines",
        multipleSclerosis: "Multiple Sclerosis",
      },
    },
    {
      category: "musculoskeletal",
      label: "Musculoskeletal",
      options: {
        arthritis: "Arthritis",
        gout: "Gout",
        fractures: "Fractures",
        muscleStrain: "Muscle Strain",
      },
    },
    {
      category: "skin",
      label: "Skin",
      options: {
        psoriasis: "Psoriasis",
        eczema: "Eczema",
        dermatitis: "Dermatitis",
        skincancer: "Skin Cancer",
      },
    },
    {
      category: "endocrine",
      label: "Endocrine",
      options: {
        diabetesType1: "Diabetes Type 1",
        diabetesType2: "Diabetes Type 2",
        Hypothyroidism: "Hypothyroidism",
        Hyperthyroidism: "Hyperthyroidism",
      },
    },
    {
      category: "infectiousDiseases",
      label: "Infectious Diseases",
      options: {
        tuberculosis: "Tuberculosis",
        hiv: "HIV",
        hepatitisB: "Hepatitis B",
        hepatitisC: "Hepatitis C",
      },
    },
    {
      category: "hematologicOncologic",
      label: "Hematologic/Oncologic",
      options: {
        anemia: "Anemia",
        leukemia: "Leukemia",
        lymphoma: "Lymphoma",
        clottingdisorders: "Clotting Disorders",
      },
    },
    {
      category: "psychiatric",
      label: "Psychiatric",
      options: {
        anxiety: "Anxiety",
        depression: "Depression",
        bipolarDisorder: "Bipolar Disorder",
        schizophrenia: "Schizophrenia",
      },
    },
    {
      category: "genitourinary",
      label: "Genitourinary",
      options: {
        kidneyStones: "Kidney Stones",
        uti: "UTI",
        incontinence: "Incontinence",
        chronicKidneyDisease: "Chronic Kidney Disease",
      },
    },
    {
      category: "gastrointestinal",
      label: "Gastrointestinal",
      options: {
        gerd: "GERD",
        crohnsDisease: "Crohn’s Disease",
        ulcerativeColitis: "Ulcerative Colitis",
        irritablebowelsyndrome: "Irritable Bowel Syndrome",
      },
    },
    {
      category: "immuneSystem",
      label: "Immune System",
      options: {
        autoimmuneDisorders: "Autoimmune Disorders",
        lupus: "Lupus",
        rheumatoidArthritis: "Rheumatoid Arthritis",
      },
    },
  ]


  export const FamilyHistorydata=
  [
    {
      category: "generalHealthConditions",
      label: "General - Health Conditions",
      options: {
        noHealthConcern: "No Health Concern",
        arthritis: "Arthritis",
        asthma: "Asthma",
        bleedingDisorder: "Bleeding Disorder",
        cadUnder55: "CAD < age 55 (Coronary Artery Disease)",
        copd: "COPD",
        diabetes: "Diabetes",
      },
    },
    {
      category: "cardiovascularAndOthers",
      label: "General - Cardiovascular and Others",
      options: {
        heartAttack: "Heart Attack",
        heartDisease: "Heart Disease",
        highCholesterol: "High Cholesterol",
        hypertension: "Hypertension",
        mentalIllness: "Mental Illness",
        osteoporosis: "Osteoporosis",
        stroke: "Stroke",
      },
    },
    {
      category: "cancer",
      label: "Cancer",
      options: {
        breastCA: "Breast CA",
        colonCA: "Colon CA",
        ovarianCA: "Ovarian CA",
        prostateCA: "Prostate CA",
        uterineCA: "Uterine CA",
        otherCA: "Other CA",
      },
    },
  ]

  export const PsychiatricHistorydata= [
    {
      category: "psychiatricConditions",
      label: "Psychiatric Conditions",
      options: {
        anxietyDisorders: "Anxiety Disorders",
        depression: "Depression",
        bipolarDisorder: "Bipolar Disorder",
        schizophrenia: "Schizophrenia",
        obsessiveCompulsiveDisorder:
          "Obsessive-Compulsive Disorder",
        postTraumaticStressDisorder:
          "Post-Traumatic Stress Disorder",
        substanceUseDisorders: "Substance Use Disorders",
        eatingDisorders: "Eating Disorders",
        autismSpectrumDisorder: "Autism Spectrum Disorder",
        adhd: "Attention-Def/Hyperactivity Disorder",
        personalityDisorders: "Personality Disorders",
      },
    },
    {
      category: "neurocognitiveDisorders",
      label: "Neurocognitive Disorders",
      options: {
        dementia: "Dementia",
        alzheimersDisease: "Alzheimer’s Disease",
        mildCognitiveImpairment: "Mild Cognitive Impairment (MCI)",
        parkinsonsDisease: "Parkinson’s Disease",
        huntingtonsDisease: "Huntington’s Disease",
      },
    },

    {
      category: "developmentalDisorders",
      label: "Developmental Disorders",
      options: {
        intellectualDisability: "Intellectual Disability",
        speechLanguageDisorders: "Speech and Language Disorders",
        learningDisorders: "Learning Disorders",
        touretteSyndrome: "Tourette Syndrome",
      },
    },
    {
      category: "symptoms",
      label: "Symptoms",
      options: {
        suicidalThoughts: "Suicidal Thoughts",
        selfHarm: "Self-Harm",
        hearingSeeingThings: "Hearing/Seeing Things",
        delusions: "Delusions (False Beliefs)",
        anxietyOrPanicAttacks: "Anxiety or Panic Attacks",
        moodSwings: "Mood Swings",
        paranoia: "Paranoia",
        otherSymptoms: "Other",
      },
    },
    {
      category: "mentalHealthTreatmentHistory",
      label: "Mental Health Treatment History",
      options: {
        hospitalizedForPsychiatricCondition:
          "Hospitalized for Psychiatric Condition",
        therapyForMentalHealthConcerns:
          "Therapy for Mental Health Concerns",
        prescribedPsychiatricMedications:
          "Prescribed Psychiatric Medications",
        historyOfElectroconvulsiveTherapy:
          "History of Electroconvulsive Therapy",
        otherTreatmentHistory: "Other",
      },
    },
    {
      category: "substanceUse",
      label: "Substance Use",
      options: {
        alcoholUse: "Alcohol Use",
        marijuanaUse: "Marijuana Use",
        illicitDrugUse: "Illicit Drug Use",
        intravenousDrugUse: "Intravenous Drug Use",
        noSubstanceUse: "No Substance Use",
      },
    },
    {
      category: "familypaychiaticHistory",
      label: "Family Psychiatric History",
      options: {
        anxietyDisorders: "Anxiety Disorder",
        depression: "Depression",
        bipolarDisorder: "Bipolar Disorder",
        schizophrenia: "Schizophrenia",
        ptsd: "PTSD",
        substanceUseDisorders: "Substance Use Disorder",
        otherfamilypaychiaticHistory:
          " Other",
      },
    },
    {
      category: "socialEnvironmentalstressors",
      label: "Social/Environmental Stressors",
      options: {
        financialProblems: "Financial Problems",
        rlationshipIssues: "Relationship Issues",
        jobRelatedStress: "Job-Related Stress",
        familyConflicts: "Family Conflicts",
        traumaPhysicalEmotionalSexual:
          "Trauma (Physical/Emotional/Sexual)",
        othersocialEnvironmentalstressors:
          " Other",
      },
    },
    {
      category: "cognitiveandBehavioralPatterns",
      label: "Cognitive and Behavioral Patterns",
      options: {
        difficultyConcentration:
          " Difficulty w/Memory or Concentration",
        decisionMakingProblems: "Decision-Making Problems",
        problemSolvingChallenges: "Problem-Solving Challenges",
        impulsiveBehaviors: "Impulsive Behaviors",
        othercognitiveandBehavioralPatterns:
          "Other",
      },
    },
    {
      category: "sleepandEnergyLevels",
      label: "Sleep and Energy Levels",
      options: {
        difficultyFallingAsleep: " Difficulty Falling Asleep",
        frequentWaking: "Frequent Waking",
        oversleeping: "Oversleeping",
        chronicFatigue: "Chronic Fatigue",
        lowEnergyLevels: "Low Energy Levels",
      },
    },
  ]
  export const SurgicalHistorydata=
  [
    {
      category: "cardiovascularSurgeries",
      label: "Cardiovascular Surgeries",
      options: {
        aneurysmRepair:'Aneurysm repair',
        cabg: "CABG (Coronary Artery Bypass Graft)",
        carotidEndarterectomy: "Carotid endarterectomy/stent",
        pacemakerDefibrillator: "Pacemaker/defibrillator",
        ptcaPci: "PTCA/PCI",
      },
    },
    {
      category: "commonSurgeries",
      label: "Common Surgeries",
      options: {
        appendectomy: "Appendectomy",
        backSurgery: "Back surgery",
        bariatricSurgery: " Bariatric surgery/gastric bypass",
        bilateralTubalLigation: " Bilateral tubal ligation",
        breastResectionMastectomy: "Breast resection/mastectomy",
        carpalTunnelRelease: "Carpal tunnel release surgery",

      },
    },
    {
      category: "ophthalmologySurgeries",
      label: "Ophthalmology Surgeries",
      options: {
        cataractLensSurgery: "LASIK",
        lasik: "Cataract/lens surgery"
      },
    },
    {
      category: "gynecologicalSurgeries",
      label: "Gynecological Surgeries",
      options: {
        cesareanSection:"Cesarean section",
        dilationAndCurettage: "Dilation and curettage",
        hysterectomy:"Hysterectomy",
        tahBso: "TAH-BSO (Total Abdominal Hysterectomy with Bilateral Salpingo-Oophorectomy)",
      },
    },
    {
      category: "urologicalSurgeries",
      label: "Urological Surgeries",
      options: {
        prostateSurgery:  "Prostate surgery",
        prostatectomy:  "Prostatectomy",
        turp:  "TURP (Trans Resection of the Prostate)",
        vasectomy:  "Vasectomy",
      },
    },
    {
      category: "orthopedicSurgeries",
      label: "Orthopedic Surgeries",
      options: {
        hipArthroplasty: "Hip arthroplasty",
        hipReplacement:  "Hip replacement",
        kneeArthroplasty:  "Knee arthroplasty",
        rotatorCuffSurgery:    "Rotator cuff surgery",
        spinalFusion: "Spinal fusion",
      },
    },
    {
      category: "entSurgeries",
      label: "ENT Surgeries",
      options: {
        sinusSurgery: "Sinus surgery",
        tonsillectomyAdenoidectomy: "Tonsillectomy/Adenoidectomy",
        nasalSurgery: "Nasal surgery",
      },
    },
    {
      category: "skinCancerSurgeries",
      label: "Skin and Cancer Surgeries",
      options: {
        skinCancerExcision: "Skin cancer excision",
        hemorrhoidSurgery: "Hemorrhoid surgery",
      },
    },
    {
      category: "miscellaneousSurgeries",
      label: "Miscellaneous Surgeries",
      options: {
        cholecystectomy: "Cholecystectomy/bile duct surgery",
        laminectomy: "Laminectomy",
        spinalFusion: "Spinal fusion",
       
      },
    },
  ]

  export const LifestyleDeterminantsdata=[
    {
      category: "tobaccoUse",
      label: "Tobacco Use",
      options: {
        currentEverydaySmoker: "Current every day smoker",
        currentSomeDaySmoker: "Current some day smoker",
        formerSmoker: "Former smoker",
        heavyTobaccoSmoker: "Heavy tobacco smoker",
        lightTobaccoSmoker: "Light tobacco smoker",
        neverSmoker: "Never smoker",
        smokerCurrentStatusUnknown:
          "Smoker, current status unknown",
        unknownIfEverSmoked: "Unknown if ever smoked",
      },
    },
    {
      category: "alcoholUse",
      label: "Alcohol Use",
      options: {
        doNotDrink: "Do not drink",
        drinkDaily: "Drink daily",
        frequentlyDrink: "Frequently drink",
        historyOfAlcoholism: "History of Alcoholism",
        occasionalDrink: "Occasional drink",
      },
    },
    {
      category: "substanceUse",
      label: "Substance Use",
      options: {
        ivdu: "IVDU (Intravenous Drug Use)",
        illicitDrugUse: "Illicit drug use",
        noIllicitDrugUse: "No illicit drug use",
        prescriptionDrugMisuse: "Prescription drug misuse",
        marijuanaUse: "Marijuana use",
        cocaineUse: "Cocaine use",
        heroinUse: "Heroin use",
        methamphetamineUse: "Methamphetamine use",
        hallucinogenUse: "Hallucinogen use (LSD/MDMA/Psilocybin)",
        inhalantUse: "Inhalant use (Glue, Paint Thinner)",
        stimulantUse: "Stimulant use (e.g., Adderall, Ritalin)",
        sedativeUse: "Sedative (Benzodiazepines)",
        opioidUse: "Opioid use (Morph/Oxycod/Fentanyl)",
      },
    },
    {
      category: "cardiovascularHealth",
      label: "Cardiovascular Health",
      options: {
        eatHealthyMeals: "Eat healthy meals",
        regularExercise: "Regular exercise",
        takeDailyAspirin: "Take daily aspirin",
      },
    },
    {
      category: "patternsHistorySubstanceUse",
      label: "Patterns and History of Substance Use",
      options: {
        polysubstanceUse: "Polysubstance use (multiple)",
        hxOfSubstanceRehab: "Hx of substance rehab programs",
        hxOfWithdrawalSymptoms: "Hx of withdrawal symptoms",
        affectingDailyLife: "Affecting daily life",
        impactingRelationships: "Impacting relationships",
        impactingEmployment: "Impacting employment",
        other: "Other",
      },
    },
    {
      category: "safetyPractices",
      label: "Safety Practices",
      options: {
        householdSmokeDetector: "Household Smoke Detector",
        keepFirearmsInHome: "Keep firearms in home",
        wearSeatbelts: "Wear seatbelts",
      },
    },
    {
      category: "sexualActivity",
      label: "Sexual Activity",
      options: {
        exposureToSTI: "Exposure to STI",
        nonHeterosexualActivity: "Non-Heterosexual activity",
        notSexuallyActive: "Not sexually active",
        safeSexPractices: "Safe sex practices",
        sexuallyActive: "Sexually active",
      },
    },
    {
      category: "birthGender",
      label: "Birth Gender",
      options: {
        male: "Male",
        female: "Female",
        undifferentiated: "Undifferentiated",
      },
    },
  ]