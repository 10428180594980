  import React, { useEffect, useState } from "react";
  import PatientSidebar from "./PatientSidebar";
  import { ProfileNavbar } from "../../Components/Navbar";
  import { Patientgetprofile } from "../../../api/helper";
  import Clientinfomartion from "../../AgencyFlow/AgencyScreens/Patientinfoeditscreens/Clientinfomartion";
  import PateintInfo from "./Profiles/PateintInfo";
  import PatientAddress from "./Profiles/PatientAddress";
  import PatientPayment from "./Profiles/PatientPayment";
  import PatinetNotification from "./Profiles/PatinetNotification";
  import PatientEmergencyContacts from "./Profiles/PatientEmergencyContacts";
  import PatientIdentification from "./Profiles/PatientIdentification";
  import PatientHistory from "./Profiles/PatientHistory";
  import PatinetConsents from "./Profiles/PatinetConsents";
  import PatientTeam from "./Profiles/PatientTeam";

  function PatientProfiles() {
    const [info, setInfo] = useState(null);
    const [activeTab, setActiveTab] = useState("info");
    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
    const getprofile = async (data) => {
      try {
        const response = await Patientgetprofile();
        setInfo(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getprofile();
    }, []);

    console.log(info, "info-------");            

    return (
      <div className="dashboard-container">
        <PatientSidebar />
        <div className="dashboard-content">
          <ProfileNavbar />
          <div
            className="dashboard-cards"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <div className="doctor-details-container">
              {/* <div className="Doctor_Details_txtt">Patient Details</div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: 20,
                }}
              >
                <div className="doctor-header" style={{ width: "90%" }}>
                  <img
                    className="doctor-image"
                    src={
                      info && info?.image
                        ? info?.image
                        : "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                    }
                    alt="Doctor"
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "90%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          textAlign: "left",
                          fontWeight: "900",
                          fontSize: 30,
                        }}
                      >
                        {info && info?.firstName + " " + info?.lastName}
                      </h3>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>Client ID :</div>
                        <div style={{ marginLeft: 5 }}>{info?.uniqueId}</div>
                      </div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>Gender :</div>
                        <div style={{ marginLeft: 5 }}>{info?.gender}</div>
                      </div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>
                          Emergency Contact :
                        </div>
                        <div style={{ marginLeft: 5 }}>
                          {info?.EmergencyContacts?.PhoneNumber}
                        </div>
                      </div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>Address :</div>
                        <div style={{ marginLeft: 5 }}>
                          {info?.address?.street +
                            "," +
                            info?.address?.city +
                            "," +
                            info?.address?.state +
                            "," +
                            info?.address?.country}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>Dob | Age :</div>
                        <div style={{ marginLeft: 5 }}>
                          {info?.DOB ? info?.DOB.split("T")[0] : ""}
                        </div>
                      </div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>Primary Conatct:</div>
                        <div style={{ marginLeft: 5 }}>{info?.phone}</div>
                      </div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>Payment Method :</div>
                        <div style={{ marginLeft: 5 }}>
                          {info?.PaymentSourcesId
                            ? info?.PaymentSourcesId?.name
                            : "Not available"}
                        </div>
                      </div>
                      <div style={{ alignItems: "center", display: "flex" }}>
                        <div style={{ fontWeight: "700" }}>Email Address :</div>
                        <div style={{ marginLeft: 5 }}>{info?.email}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ paddingRight: 20 }}>
                <button
                  className={`tab-button ${activeTab === "info" ? "active" : ""}`}
                  onClick={() => handleTabClick("info")}
                >
                  Information
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "Addresses" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Addresses")}
                >
                  Addresses
                </button>
                {/* <button
                      className={`tab-button ${
                        activeTab === "specialties" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("specialties")}
                    >
                      Specialties and Conditions Treated
                    </button> */}
                <button
                  className={`tab-button ${
                    activeTab === "Payment" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Payment")}
                >
                  Payment
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "Notification" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Notification")}
                >
                  Notification
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "EmergencyContacts" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("EmergencyContacts")}
                >
                  Emergency Contacts
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "Identification" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Identification")}
                >
                  Identification
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "History" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("History")}
                >
                  History
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "Consents" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Consents")}
                >
                  Consents
                </button>
                <button
                  className={`tab-button ${activeTab === "Team" ? "active" : ""}`}
                  onClick={() => handleTabClick("Team")}
                >
                  Team
                </button>
              </div>
              {activeTab === "info" && (
                <PateintInfo info={info} getprofile={getprofile} />
              )}
              {activeTab === "Addresses" && (
                <PatientAddress info={info} getprofile={getprofile} />
              )}
              {activeTab === "Payment" && (
                <PatientPayment getprofile={getprofile} info={info} />
              )}
              {activeTab === "Notification" && (
                <PatinetNotification info={info} getprofile={getprofile} />
              )}
              {activeTab === "EmergencyContacts" && (
                <PatientEmergencyContacts info={info} getprofile={getprofile} />
              )}
              {activeTab === "Identification" && (
                <PatientIdentification info={info} getprofile={getprofile} />
              )}
              {activeTab === "History" && <PatientHistory info={info} />}
              {activeTab === "Consents" && <PatinetConsents info={info} />}
              {activeTab === "Team" && <PatientTeam info={info} />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default PatientProfiles;
