import React, { useState } from 'react';
import { Eyecloseicon, Eyeopenicon } from '../assest/SvgImages';
import PhoneInput from 'react-phone-input-2'; // Import PhoneInput for country code picker
import 'react-phone-input-2/lib/style.css'; // Import default styles for the country code picker

const Inputbox = ({ label, type, formCheck, placeholder, name, value, onChange,containerstyle,inputstyle, isPassword, style, lebalstyle, register, disabled,hideBorders ,disableDropdown}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false); // Track password visibility

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible); // Toggle visibility on click
  };

  return (
    <div className="input-container" style={containerstyle}>
      {label ? <label style={lebalstyle}>{label}</label> : null}
      <div className="input-wrapper" style={inputstyle}>
        {name === "phone" ? (
          <PhoneInput
            country={'us'} 
            value={value}
            onChange={onChange} 
            inputStyle={{
              border: hideBorders ? 'none' : '1px solid #ccc', // Adjust border as needed
              outline: 'none',
              ...style // Spread additional styles if provided
            }}
            disabled={disabled}
            disableDropdown={disableDropdown}
            // {...(register ? register(name, { required: true }) : {})}
          />
        ) : (
          <input
            type={isPassword ? (isPasswordVisible ? 'text' : 'password') : type} // Toggle between password and text for password fields
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className={isPassword ? 'password-input' : ''}
            style={style}
         
            formCheck={formCheck}
            {...(register ? register(name, { required: true }) : {})}
            disabled={disabled}
            
          />
        )}
        {isPassword && (
          <span className="password-icon" onClick={togglePasswordVisibility}>
            {isPasswordVisible ? <Eyeopenicon /> : <Eyecloseicon />} 
          </span>
        )}
      </div>
    </div>
  );
};

export default Inputbox;

export const Inputboxphone = ({ label, value, onChange, name, containerstyle, inputstyle, style, lebalstyle,disabled }) => {
  const handleChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    onChange(formattedValue);
  };
  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    let cleaned = value.replace(/\D/g, "");
  
    // Format as (XXX) XXX-XXXX
    if (cleaned.length > 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    } else if (cleaned.length > 3) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}`;
    } else if (cleaned.length > 0) {
      return `(${cleaned.slice(0, 3)}`;
    }
    return "";
  };
  return (
    <div className="input-container" style={containerstyle}>
      {label && <label style={lebalstyle}>{label}</label>}
      <input
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        style={{ padding: "10px", border: "1px solid #ccc", borderRadius: "5px", width: "100%", ...style }}
        placeholder="Enter Phone Number"
        disabled={disabled}
      />
    </div>
  );
};

