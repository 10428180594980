import { apiClient, apiClientUpload } from "./api";
import {
  Agency_CHANGE_PASSWORD,
  AGENCY_LOGIN,
  AGENCY_SIGNUP,
  BASE_URL,
  CLINIC_PROFILE_GET,
  CLINIC_UPDATE,
  DOCTOR_CHANGE_PASSWORD,
  DOCTOR_LOGIN,
  DOCTOR_REQUETS,
  DOCTOR_SIGNUP,
  GET_CATEGORY,
  GET_DOCTOR_PROFILE,
  GET_METHOD,
  IMAGE_BASE_URL,
  PATIENT_GETPROFILE,
  PATIENT_LOGIN,
  PATIENT_SIGNUP,
  PATIENT_UPDATE,
  POST_METHOD,
  PUTMETHOD,
  REGISTER,
  UPDATE_DOCTOR_PROFILE,
  UPLOAD_IMAGE,
  PATIENT_LIST,
  AGENCY_LIST,
  GET_PATIENT_DETAIL,
  GET_Clinic_DETAIL,
  GET_PROVIDER_LIST,
  APPOINTMENTS,
  APPOINTMENTS_CONFIRM,
  GET_PROBLEM,
  ADD_PROBLEM,
  REQUEST,
  REQUEST_UPDATE,
  SLOT,
  CLINIC_LIST,
  PATIENT_REQUEST,
  AVAILABLE_DAYS,
  CREATE_AVAILABLE_DAYS,
  AGENCY_DOCTOR_REQUEST_UPDATE,
  AGENCY_DOCTOR_REQUEST_DETAILS,
  GET_AGENCY_REQUEST_LIST,
  GetJoinRequest,
  PATIENTUPDATEJOINREQUEST,
  SERVICECODE,
  SERVICELOCATION,
  DELETE,
  GET_PROVIDER_TYPE,
  GET_SERVICE_BOOKING,
  GET_AGENCY_BOOKING,
  GET_CONDITION_TREATED,
  GET_CREDENTIALS,
  GET_SPECIALTUES,
  GET_DELIVERY_MODE,
  GET_PAYMENT_SOURCES,
  MODALITIES,
  LANGUAGES,
  INSURANCE,
  FORGET,
  RESET_PASSWORD,
  SUBSCRIPTION,
  AGENCY_PLAN_MANAGEMENT,
  ADMIN_INSURENCE,
  PROVIDERREQUIREMENT,
  CLIENTREQUIREMENT,
  CPTCODE,
  PATIENT_UPDATE_REQUEST,
  PATIENT_UPDATE_ADD_ADDRESS,
  PATIENT_REMOVE_ADDRESS,
  CLIENT_REMOVE_ADDRESS,
  CLIENT_UPDATE_ADD_ADDRESS,
  REMOVE_PATIENT_CARD,
  ADD_PATIENT_CARD,
  ADD_PATIENT_CARDD_AGENCY,
  REMOVE_PATIENT_CARD_AGENCY,
  TEAM_MEMBER,
  GET_TEAM_PATIENT,
  INSURANCEDETAILS,
  AUTHORIZATIONS,
  TEAMMEMBERS,
  FAMILYHISTORY,
  AGENCY_SIDE_PATIENT_EMERGENCYCONTANT,
  ADDAGENCYPATIENT,
  PARACTICE,
  FACILITYTEAMMEMEBER,
  PATCHMETHOD,
  MEDICALHISTORY,
  PSYCHIATRICHISTORY,
  LIFESTYLEDETERMINANTS,
  SURGICALHISTORY,
  NOTES,
  MEASURE,
  BASE_URL_TWO,
  QUESTIONNAIRE,
  QUESTIONNAIRE_CREATE,
  CATEGORIES_CREATE,
  CATEGORIES,
  ADD_QUESTIONS,
  UPLOAD_PREDATA,
  ADD_ALLERGY,
  GET_ALLERGY,
  DELETE_ALLERGY,
  ADD_MEDICATION,
  GET_MEDICATION,
  DELETE_MEDICATION,
  ADD_PATIENTDOCUMENT,
  DELETE_PROBLEM,
  ADD_VITALS,
  GET_VITALS,
  DELETE_VITALS,
  ADD_LABORDER,
  GET_DIAGONSIS,
  GET_CARDTYPE,
  DELETE_IDENTIFICATION,
  ADD_CONSENTMANAGMENT,
  STRIPE_CREATESESSION,
  GET_MEDICATION_LIST,
  GET_ALLERGY_LIST,
  GET_LABS_LIST,
  INFORMEDCONSENT,
  ACTUAL_SCORE,
  SURVEYS,
  GET_SUBSCRIPTION,
  AUTH_TOKEN,
  CHECK_ELIGIBILITY,
  GET_ELIGIBILITY,
  CONDITION,
  FACILITY,
  SURVEYS_CATEGORY,
} from "./url";

export const uploadImage = (payload) => {
  console.log("uploadImage--->", payload);
  return apiClientUpload({
    baseURL: IMAGE_BASE_URL,
    method: POST_METHOD,
    url: UPLOAD_IMAGE,
    data: payload,
  });
};

export const getSlots = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: SLOT,
  });
};

export const getAvailableDays = (payload) => {
  // console.log(payload,'====payload')
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AVAILABLE_DAYS,
    data: payload,
  });
};

export const createAvailableDays = (payload) => {
  console.log("createAvailableDays>=======>>>", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CREATE_AVAILABLE_DAYS,
    data: payload,
  });
};

export const updateAvailableDays = (payload) => {
  // console.log(payload, "===payload");
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: AVAILABLE_DAYS,
    data: payload,
  });
};

export const updateAgencyDoctor = (payload) => {
  // console.log(payload, "===payload");
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: AGENCY_DOCTOR_REQUEST_UPDATE,
    data: payload,
  });
};
export const GetProviderReq = (payload) => {
  // console.log(payload, "===payload");
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: "request/providerRequirements",
    data: payload,
  });
};
export const getDetailsAgencyDoctor = (payload) => {
  // console.log(payload, "===payload");
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AGENCY_DOCTOR_REQUEST_DETAILS}?id=${payload}`,
    // data:payload
  });
};

export const register = (payload) => {
  // console.log("login_payload--->", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: REGISTER,
    data: payload,
  });
};

export const agencyLogin = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AGENCY_LOGIN,
    data: payload,
  });
};

export const agencySignup = (payload) => {
  console.log("agencySignup>=======>>>", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AGENCY_SIGNUP,
    data: payload,
  });
};
export const ClinicUpdate = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CLINIC_UPDATE,
    data: payload,
  });
};
export const ClinicProfileGet = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: CLINIC_PROFILE_GET,
  });
};
// doctor flow

export const DoctorSignup = (payload) => {
  // console.log("DoctorSignup>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: DOCTOR_SIGNUP,
    data: payload,
  });
};

export const ForgetUser = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: FORGET,
    data: payload,
  });
};

export const RestUserPassword = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: RESET_PASSWORD,
    data: payload,
  });
};

export const DoctorLogin = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: DOCTOR_LOGIN,
    data: payload,
  });
};

export const DoctorChangePassword = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: DOCTOR_CHANGE_PASSWORD,
    data: payload,
  });
};

export const getDoctorProfile = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: GET_DOCTOR_PROFILE,
  });
};

export const updateDoctorProfile = (payload) => {
  // console.log("updateDoctorProfile==>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: UPDATE_DOCTOR_PROFILE,
    data: payload,
  });
};

export const AllPatientsGet = (id) => {
  // console.log(id,"payload======>")
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${PATIENT_LIST}${id}`,
  });
};

export const ClinicchangePassword = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: Agency_CHANGE_PASSWORD,
    data: payload,
  });
};

export const GetCategory = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: GET_CATEGORY,
  });
};

export const GetDoctorrequest = (payload) => {
  // console.log(payload,'=====payload')
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${DOCTOR_REQUETS}`,
    data: payload,
  });
};

export const getAgencyRequestList = (payload) => {
  // console.log(payload,'=====payload')
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${GET_AGENCY_REQUEST_LIST}`,
    data: payload,
  });
};

export const GetClinic = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AGENCY_LIST}`,
  });
};

export const PatientDetails = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_PATIENT_DETAIL}${id}`,
  });
};

export const ClinicDetail = (id, doctorId) => {
  // console.log(id,'===id')

  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_Clinic_DETAIL}${id}&doctorId=${doctorId}`,
  });
};
export const GetProblems = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_PROBLEM}${id}`,
  });
};
export const DeleteProblems = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${DELETE_PROBLEM}${id}`,
  });
};
export const Addproblems = (payload) => {
  console.log("ADD_PROBLEM==>>>", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: ADD_PROBLEM,
    data: payload,
  });
};
export const Changeappointmentsstatus = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: APPOINTMENTS_CONFIRM,
    data: payload,
  });
};
export const JoinClinic = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: REQUEST,
    data: payload,
  });
};

export const JoinClinicUpdate = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${REQUEST}?requestId=${payload.requestId}`,
    data: payload,
  });
};

export const GetDoctorsRequest = (payload = {}) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${REQUEST}/getAgencyDoctor`,
    data: payload,
  });
};

export const createAgencyDoctor = (payload = {}) => {
  console.log("createAgencyDoctor===>>>", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${REQUEST}/createAgencyDoctor`,
    data: payload,
  });
};

export const createAgencyPatient = (payload = {}) => {
  console.log("createAgencyDoctor===>>>", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `patient/patinetJoinClinic`,
    data: payload,
  });
};

export const UpdateRequest = (payload, id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${REQUEST_UPDATE}${id}`,
    data: payload,
  });
};
//
// Patient flow =========>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

export const PatientSignup = (payload) => {
  // console.log("PatientSignup>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_SIGNUP,
    data: payload,
  });
};
export const AddAgencyPatientSignup = (payload) => {
  // console.log("PatientSignup>=======>>>",payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: ADDAGENCYPATIENT,
    data: payload,
  });
};
export const PatientRequest = (payload) => {
  console.log("PatientRequest>=======>>>", payload);
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_REQUEST,
    data: payload,
  });
};

export const PatientLogin = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_LOGIN,
    data: payload,
  });
};

export const Patientgetprofile = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: PATIENT_GETPROFILE,
  });
};

export const PatientUpdate = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_UPDATE,
    data: payload,
  });
};
export const PatientAddressUpdate = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_UPDATE_ADD_ADDRESS,
    data: payload,
  });
};
export const PatientAddressremove = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_REMOVE_ADDRESS,
    data: payload,
  });
};
export const ClientagencysideAddressUpdate = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CLIENT_UPDATE_ADD_ADDRESS,
    data: payload,
  });
};
export const ClientagencysideAddressremove = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CLIENT_REMOVE_ADDRESS,
    data: payload,
  });
};
export const Providerlist = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: GET_PROVIDER_LIST,
  });
};
export const Postappointments = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: APPOINTMENTS,
    data: payload,
  });
};
export const Clinicrequestlist = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: CLINIC_LIST,
  });
};
export const ClinicTeammembers = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${TEAMMEMBERS}${payload}`,
  });
};
export const FacilityTeamembers = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${FACILITYTEAMMEMEBER}`,
  });
};
export const Getmyclinicpatient = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: GetJoinRequest,
    data: payload,
  });
};
export const Patientrequestupdate = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: PATIENTUPDATEJOINREQUEST,
    data: payload,
  });
};

export const GetPlaceservice = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: SERVICECODE,
  });
};

export const AddAddressbyagency = (payload = {}) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${SERVICELOCATION}`,
    data: payload,
  });
};
export const Getserviceaddress = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: SERVICELOCATION,
    data: payload,
  });
};

export const GetProviderType = (payload) => {
  // console.log(`${GET_PROVIDER_TYPE}${payload}`,"url======")
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_PROVIDER_TYPE}${payload}`,
  });
};
export const FetchParactice = () => {
  // console.log(`${GET_PROVIDER_TYPE}${payload}`,"url======")
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${PARACTICE}`,
  });
};

export const AddProviderType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_PROVIDER_TYPE,
    data: payload,
  });
};

export const DeleteProviderType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_PROVIDER_TYPE}${payload}`,
  });
};

export const GetServiceBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_SERVICE_BOOKING}${payload}`,
  });
};

export const AddServiceBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_SERVICE_BOOKING,
    data: payload,
  });
};

export const DeleteServiceBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_SERVICE_BOOKING}${payload}`,
  });
};

//========================================

export const GetAgencyBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_AGENCY_BOOKING}${payload}`,
  });
};

export const AddAgencyBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_AGENCY_BOOKING,
    data: payload,
  });
};

export const DeleteAgencyBooking = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_AGENCY_BOOKING}${payload}`,
  });
};
//============================================

export const GetCredentials = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_CREDENTIALS}${payload}`,
  });
};
export const GetProviderRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${PROVIDERREQUIREMENT}${payload}`,
  });
};
export const GetClientRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${CLIENTREQUIREMENT}${payload}`,
  });
};
export const AddCredentials = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_CREDENTIALS,
    data: payload,
  });
};

export const AddProviderRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PROVIDERREQUIREMENT,
    data: payload,
  });
};
export const AddClientRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: CLIENTREQUIREMENT,
    data: payload,
  });
};
export const DeleteClientRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${CLIENTREQUIREMENT}${payload}`,
  });
};
export const DeleteProviderRequirement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${PROVIDERREQUIREMENT}${payload}`,
  });
};
export const DeleteCredentials = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_CREDENTIALS}${payload}`,
  });
};

//=============================================

export const GetSpecialties = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_SPECIALTUES}${payload}`,
  });
};

export const AddSpecialties = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_SPECIALTUES,
    data: payload,
  });
};

export const DeleteSpecialties = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_SPECIALTUES}${payload}`,
  });
};

//=============================================

export const GetDeliveryMode = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_DELIVERY_MODE}${payload}`,
  });
};

export const AddDeliveryMode = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_DELIVERY_MODE,
    data: payload,
  });
};

export const DeleteDeliveryMode = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_DELIVERY_MODE}${payload}`,
  });
};

//============================================

export const GetPaymentSources = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_PAYMENT_SOURCES}${payload}`,
  });
};

export const AddPaymentSources = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_PAYMENT_SOURCES,
    data: payload,
  });
};

export const DeletePaymentSources = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_PAYMENT_SOURCES}${payload}`,
  });
};

//============================================

export const GetConditionTreated = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_CONDITION_TREATED}${payload}`,
  });
};

export const AddConditionTreated = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: GET_CONDITION_TREATED,
    data: payload,
  });
};

export const DeleteConditionTreated = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${GET_CONDITION_TREATED}${payload}`,
  });
};

export const Deleteserviceaddress = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: SERVICELOCATION,
    data: payload,
  });
};

export const GetServiceParactiseaddress = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `doctor/clinicServiceLocation?id=${id}`,
  });
};

export const GetModalities = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/${MODALITIES}${payload}`,
  });
};

export const GetInsurance = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/${INSURANCE}${payload}`,
  });
};

export const GetLanguages = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/${LANGUAGES}${payload}`,
  });
};

export const AddInsurance = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/${INSURANCE}`,
    data: payload,
  });
};

export const DeleteInsurance = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/${INSURANCE}${payload}`,
  });
};

export const AddModalities = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/${MODALITIES}`,
    data: payload,
  });
};

export const DeleteModalities = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/${MODALITIES}${payload}`,
  });
};
export const FetchMODALITIES = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/${MODALITIES}${payload}`,
  });
};
export const FetchPopulationServed = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/PopulationServed${payload}`,
  });
};

export const AddPopulationServed = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/PopulationServed`,
    data: payload,
  });
};
export const FetchAppointmentType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/AppointmentType${payload}`,
  });
};
export const FetchDegreetType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/degreeType${payload}`,
  });
};
export const FetchAddAppoientmentVisit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/AppointmentVisit${payload}`,
  });
};
export const AddAppoientmenttype = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/AppointmentType`,
    data: payload,
  });
};
export const AddDegreeType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/degreeType`,
    data: payload,
  });
};
export const FetchVisitReason = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/VisitReason${payload}`,
  });
};
export const AddVisitReason = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/VisitReason`,
    data: payload,
  });
};
export const EditVisitReason = (payload, id) => {
  console.log(`${BASE_URL}agency/VisitReason${id}`, "urll=========");
  console.log(payload, "payload--------");
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `agency/VisitReason?id=${id}`,
    data: payload,
  });
};

export const DeleteVisitReason = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/VisitReason${payload}`,
  });
};
export const AddAppoientmentVisit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/AppointmentVisit`,
    data: payload,
  });
};
export const DeleteAppoientmenttype = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/AppointmentType${payload}`,
  });
};
export const DeletedegreeType = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/degreeType${payload}`,
  });
};
export const DeleteAppointmentVisit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/AppointmentVisit${payload}`,
  });
};
export const DeletePopulationServed = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/PopulationServed${payload}`,
  });
};

export const FetchLanguage = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/Languages${payload}`,
  });
};

export const AddLanguage = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/Languages`,
    data: payload,
  });
};

export const DeleteLanguage = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/Languages${payload}`,
  });
};

export const Fetchsubscription = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    // url:`${SUBSCRIPTION}?id=${id}`,
    url: `subscriptions?id=${id}`,
  });
};

export const Addsubscription = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: SUBSCRIPTION,
    data: payload,
  });
};

export const Deletesubscription = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${SUBSCRIPTION}?id=${payload}`,
  });
};
// SUBSCRIPTION

//==========AGENCY_PLAN_MANAGEMENT=====

export const FetchAgencyPlanManagement = (id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AGENCY_PLAN_MANAGEMENT}?id=${id}`,
  });
};

export const AddAgencyPlanManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AGENCY_PLAN_MANAGEMENT,
    data: payload,
  });
};

export const DeleteAgencyPlanManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${AGENCY_PLAN_MANAGEMENT}?id=${payload}`,
  });
};

// ADMIN INSURENCE

export const GetAdminInsurence = (payload) => {
  // console.log(payload,"payload--====")
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${ADMIN_INSURENCE}/search`,
    data: payload,
  });
};
export const Addcptcode = (payload) => {
  // console.log(payload,"payload--====")
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${CPTCODE}`,
    data: payload,
  });
};

export const FetchCptcodes = (data) => {
  console.log(`${CPTCODE}?${data}`, "`${CPTCODE}?${data}`====");
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${CPTCODE}?${data}`,
  });
};

export const AddInsuranceContract = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/insuranceContract`,
    data: payload,
  });
};
export const ImportCsvfile = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `agency/insuranceContractCsv`,
    data: payload,
  });
};
export const FetchInsuranceContract = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `agency/insuranceContract${payload}`,
  });
};
export const DeleteInsuranceContract = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `agency/insuranceContract${payload}`,
  });
};

export const EditInsurance = (payload, id) => {
  console.log(`${BASE_URL}agency/VisitReason${id}`, "urll=========");
  console.log(payload, "payload--------");
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `agency/${INSURANCE}?id=${id}`,
    data: payload,
  });
};

export const Patientrequestupdates = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PATIENT_UPDATE_REQUEST,
    data: payload,
  });
};
export const AgencySidePaitentEMERcontact = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AGENCY_SIDE_PATIENT_EMERGENCYCONTANT,
    data: payload,
  });
};
export const AgencySidePaitentEMERcontactUpdate = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: AGENCY_SIDE_PATIENT_EMERGENCYCONTANT,
    data: payload,
  });
};
export const Deletecontact = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: AGENCY_SIDE_PATIENT_EMERGENCYCONTANT,
    data: payload,
  });
};
export const DeleteIdentification = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: DELETE_IDENTIFICATION,
    data: payload,
  });
};
//
export const Fetchemergencycontact = (payload, patientId) => {
  // console.log( `${AGENCY_SIDE_PATIENT_EMERGENCYCONTANT}?agencyId=${payload}&patientId=${patientId}`,"urll======")
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AGENCY_SIDE_PATIENT_EMERGENCYCONTANT}?agencyId=${payload}&patientId=${patientId}`,
  });
};
export const Getagencypatientdetails = (payload) => {
  // console.log(payload, "===payload");
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `patient/patinetRequestDetails?id=${payload}`,
    // data:payload
  });
};

export const AddPatientCard = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: ADD_PATIENT_CARD,
    data: payload,
  });
};
export const RemovePatientCard = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: REMOVE_PATIENT_CARD,
    data: payload,
  });
};

export const AddPatientCardagencyside = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: ADD_PATIENT_CARDD_AGENCY,
    data: payload,
  });
};
export const RemovePatientCardagencyside = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: REMOVE_PATIENT_CARD_AGENCY,
    data: payload,
  });
};
export const PatientAddTeam = (payload) => {
  // console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: TEAM_MEMBER,
    data: payload,
  });
};
export const PatientUpdateTeam = (payload, id) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${TEAM_MEMBER}?id=${id}`,
    data: payload,
  });
};
export const PatientgetTeam = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${TEAM_MEMBER}?id=${payload}&userType=patient`,
    // data: payload,
  });
};
export const PatientagencygetTeam = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_TEAM_PATIENT}?id=${payload}`,
    // data: payload,
  });
};
export const PatientDeleteTeam = (payload) => {
  console.log("PatientUpdate=====>>>>", `${TEAM_MEMBER}?id=${payload}`);
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${TEAM_MEMBER}?id=${payload}`,
    // data: payload,
  });
};
export const ClientAddTeam = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: TEAM_MEMBER,
    data: payload,
  });
};
export const ClientgetTeam = (payload, type) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${TEAM_MEMBER}?id=${payload}&userType=agency&type=${type}`,
    // data: payload,
  });
};

export const ClientDeleteTeam = (payload) => {
  console.log("PatientUpdate=====>>>>", `${TEAM_MEMBER}?id=${payload},`);
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${TEAM_MEMBER}?id=${payload}`,
    // data: payload,
  });
};

export const Postinsurencedetails = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: INSURANCEDETAILS,
    data: payload,
  });
};
export const GETinsurencedetails = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${INSURANCEDETAILS}?agencyId=${payload}`,
    // data: payload,
  });
};
export const Deleteinsurance = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${INSURANCEDETAILS}?id=${payload}`,
    // data: payload,
  });
};

export const PostAuthorizations = (payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: AUTHORIZATIONS,
    data: payload,
  });
};
export const PatchAuthorizations = (id, payload) => {
  //console.log('PatientUpdate=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${AUTHORIZATIONS}?id=${id}`,
    data: payload,
  });
};
export const GETAuthorizations = (payload) => {
  // console.log('INSURANCEDETAILS=====>>>>',`${INSURANCEDETAILS}?agencyId=${payload}`)
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AUTHORIZATIONS}?agencyId=${payload}`,
    // data: payload,
  });
};
export const DeleteAuthorizations = (payload) => {
  // console.log('INSURANCEDETAILS=====>>>>',`${INSURANCEDETAILS}?agencyId=${payload}`)
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${AUTHORIZATIONS}?id=${payload}`,
    // data: payload,
  });
};
export const FamilyHistoryonsubmit = (payload) => {
  // console.log('FamilyHistoryonsubmit=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: FAMILYHISTORY,
    data: payload,
  });
};
export const FamilyHistoryonsubmitUpdate = (id, payload) => {
  // console.log('FamilyHistoryonsubmit=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${FAMILYHISTORY}?id=${id}`,
    data: payload,
  });
};

export const FetchFamilyHistory = (agencyId, patientId) => {
  // console.log('FamilyHistoryonsubmit=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${FAMILYHISTORY}?agencyId=${agencyId}&patientId=${patientId}`,
  });
};

export const MedicalHistoryonsubmit = (payload) => {
  // console.log('FamilyHistoryonsubmit=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: MEDICALHISTORY,
    data: payload,
  });
};

export const FetchMedicalHistory = (agencyId, patientId) => {
  // console.log('FamilyHistoryonsubmit=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${MEDICALHISTORY}?agencyId=${agencyId}&patientId=${patientId}`,
  });
};

export const MedicalHistoryonsubmitUpdate = (id, payload) => {
  // console.log('FamilyHistoryonsubmit=====>>>>',payload)
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${MEDICALHISTORY}?id=${id}`,
    data: payload,
  });
};

export const PsychiatricHistoryonsubmit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: PSYCHIATRICHISTORY,
    data: payload,
  });
};

export const FetchPsychiatricHistory = (agencyId, patientId) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${PSYCHIATRICHISTORY}?agencyId=${agencyId}&patientId=${patientId}`,
  });
};

export const PsychiatricHistoryonsubmitUpdate = (id, payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${PSYCHIATRICHISTORY}?id=${id}`,
    data: payload,
  });
};

export const LifestyleDeterminantsnsubmit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: LIFESTYLEDETERMINANTS,
    data: payload,
  });
};

export const FetchLifestyleDeterminants = (agencyId, patientId) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${LIFESTYLEDETERMINANTS}?agencyId=${agencyId}&patientId=${patientId}`,
  });
};

export const LifestyleDeterminantsonsubmitUpdate = (id, payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${LIFESTYLEDETERMINANTS}?id=${id}`,
    data: payload,
  });
};

export const SurgicalHistoryonsubmit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: SURGICALHISTORY,
    data: payload,
  });
};

export const GetSurgicalHistory = (agencyId, patientId) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${SURGICALHISTORY}?agencyId=${agencyId}&patientId=${patientId}`,
  });
};

export const SurgicalHistoryonsubmitUpdate = (id, payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${SURGICALHISTORY}?id=${id}`,
    data: payload,
  });
};

export const NotesSubmit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: NOTES,
    data: payload,
  });
};

export const Getnotesdata = (query) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${NOTES}${query}`,
  });
};
export const updateNotes = (id, payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${NOTES}?id=${id}`,
    data: payload,
  });
};

export const MeasuresSubmit = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: MEASURE,
    data: payload,
  });
};

export const GetMeasuresdata = (query) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${MEASURE}${query}`,
  });
};
export const ArchivedMeasures = (id, payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${MEASURE}?id=${id}`,
    data: payload,
  });
};
//
export const FetchQuestionnaireList = (query) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: GET_METHOD,
    url: `${QUESTIONNAIRE}?${query}`,
  });
};
export const Fetchactual = (query) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: GET_METHOD,
    url: `${ACTUAL_SCORE}?${query}`,
  });
};
export const Fetchservays = (query) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: GET_METHOD,
    url: `${SURVEYS}?${query}`,
  });
};

export const getIndividualScore = (payload) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: GET_METHOD,
    url: `${SURVEYS}/individualScore/${payload}`,
  });
};




export const getCatrgoryInSurvay = (query) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: GET_METHOD,
    url: `${SURVEYS_CATEGORY}?${query}`,
  });
};

export const completeSurvey = (payload) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: POST_METHOD,
    url: `${SURVEYS}/complete`,
    data:payload
  });
};


export const UploadQuestionnaireList = (payload) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: POST_METHOD,
    url: `${QUESTIONNAIRE_CREATE}`,
    data: payload,
  });
};


export const Uploadcategoriescreate = (payload) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: POST_METHOD,
    url: `${CATEGORIES_CREATE}`,
    data: payload,
  });
};
export const Fetchcategoriescreate = (query) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: GET_METHOD,
    url: `${CATEGORIES}?${query}`,
  });
};

export const updateCategory = (id, payload) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: PUTMETHOD,
    url: `${CATEGORIES}/${id}`,
    data:payload

  });
};

export const Patchaddquestion = (payload) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: PATCHMETHOD,
    url: `${ADD_QUESTIONS}`,
    data: payload,
  });
};
export const updatePreData = (payload) => {
  return apiClient({
    baseURL: BASE_URL_TWO,
    method: POST_METHOD,
    url: `${UPLOAD_PREDATA}`,
    data: payload,
  });
};
export const AddpatientAllergy = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${ADD_ALLERGY}`,
    data: payload,
  });
};
export const GetpatientAllergy = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_ALLERGY}${payload}`,
  });
};
export const DeletepatientAllergy = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${DELETE_ALLERGY}${payload}`,
  });
};

export const AddpatientMedication = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${ADD_MEDICATION}`,
    data: payload,
  });
};
export const GetpatientMedication = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_MEDICATION}${payload}`,
  });
};
export const DeletepatientMedication = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${DELETE_MEDICATION}${payload}`,
  });
};

export const Addpatientdocument = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${ADD_PATIENTDOCUMENT}`,
    data: payload,
  });
};
export const Getpatientdocument = (payload, type) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${ADD_PATIENTDOCUMENT}?patientId=${payload}&documentType=${type}`,
  });
};
export const Deletepatientdocument = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${ADD_PATIENTDOCUMENT}?id=${payload}`,
  });
};

export const AddpatientVitals = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${ADD_VITALS}`,
    data: payload,
  });
};
export const GetpatientVitals = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_VITALS}${payload}`,
  });
};
export const DeletepatientVitals = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${DELETE_VITALS}${payload}`,
  });
};

export const Addpatientlaborder = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${ADD_LABORDER}`,
    data: payload,
  });
};
export const Getpatientlaborder = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${ADD_LABORDER}?patientId=${payload}`,
  });
};
export const Deletepatientlaborder = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${ADD_LABORDER}?id=${payload}`,
  });
};

export const FetchCurrentDiagnosis = (search, page, pageSize) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_DIAGONSIS}?search=${search}&page=${page}&limit=10`,
  });
};
export const FetchCurrentLabs = (search, page, pageSize) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_LABS_LIST}?search=${search}&page=${page}&limit=10`,
  });
};
export const FetchCurrentMedication = (search, page, pageSize) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_MEDICATION_LIST}?search=${search}&page=${page}&limit=10`,
  });
};
export const FetchSampleAllergy = (search, page, pageSize) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_ALLERGY_LIST}?search=${search}&page=${page}&limit=10`,
  });
};
export const Fetchcardtype = () => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_CARDTYPE}`,
  });
};

export const AddconsentManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${ADD_CONSENTMANAGMENT}`,
    data: payload,
  });
};
export const GetconsentManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${ADD_CONSENTMANAGMENT}?agencyId=${payload}`,
    // data:payload
  });
};
export const RemoveconsentManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${ADD_CONSENTMANAGMENT}?id=${payload}`,
    // data:payload
  });
};
export const UpdatedataconsentManagement = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PUTMETHOD,
    url: `${ADD_CONSENTMANAGMENT}`,
    data: payload,
  });
};

// stripe
export const PostStripeCreateSession = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${STRIPE_CREATESESSION}`,
    data: payload,
  });
};
export const FetchStripeCreateSession = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${GET_SUBSCRIPTION}`,
    data: payload,
  });
};

export const AddPatientSign = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${INFORMEDCONSENT}`,
    data: payload,
  });
};
export const UpdatePatientSign = (payload, id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${INFORMEDCONSENT}?id=${id}`,
    data: payload,
  });
};
export const GetPatientSign = (payload, id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${INFORMEDCONSENT}?patientId=${payload}&consentManagementId=${id}`,
  });
};

export const FetchEligibilityToken = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${AUTH_TOKEN}`,
    data: payload,
  });
};

export const FetchEligibilityCheck = async (payload) => {
  const tokendata = {
    client_id: "RrjZ0fpjL0DCX71YIupKPGxEM0LFm3Aw",
    client_secret: "2zXhOLbVnwh4YOFA",
    grant_type: "client_credentials",
  };

  try {
    const tokenResponse = await FetchEligibilityToken(tokendata);
    const accessToken = tokenResponse?.data?.access_token;

    if (!accessToken) {
      throw new Error("Access token not received");
    }

    const customApiClient = apiClient.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const response = await customApiClient({
      method: POST_METHOD,
      url: CHECK_ELIGIBILITY,
      data: payload,
    });
    // console.log(response,"")
    return response;
  } catch (error) {
    console.log(error, "error in FetchEligibilityCheck");
    throw error;
  }
};

export const FetchEligibility = (agencyId, patinetId, limit, page) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${GET_ELIGIBILITY}?agencyId=${agencyId}&patinetId=${patinetId}&limit=${limit}&page=${page}`,
    // data: payload,
  });
};

export const AddPaymentConditions = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${CONDITION}`,
    data: payload,
  });
};
export const FetchCondition = (patientId, agencyId) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${CONDITION}/details?patientId=${patientId}&agencyId=${agencyId}`,
  });
};
export const UpdateCondition = (payload, Id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${CONDITION}?id=${Id}`,
    data: payload,
  });
};
export const AddFacility = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${FACILITY}`,
    data: payload,
  });
};

export const FetchFacility = (payload, pageSize, page) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${FACILITY}?agencyId=${payload}&limit=${pageSize}&page=${page}`,
  });
};
export const DeleteFacility = (payload, ) => {
  return apiClient({
    baseURL: BASE_URL,
    method: DELETE,
    url: `${FACILITY}/FacilityExternalTeam?id=${payload}`,
  });
};
export const FetchDropdownFacility = (payload, pageSize, ) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${FACILITY}?agencyId=${payload}&limit=${pageSize}`,
  });
};

export const  GetFacilityTwo = (payload,pageSize=100,page=1) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${FACILITY}/FacilityExternalTeam?agencyId=${payload}&limit=${pageSize}&page=${page}`,
    // data: payload,
  });
};
export const  GetFacilityPractice = (payload,pageSize,page,facilityId) => {
  return apiClient({
    baseURL: BASE_URL,
    method: GET_METHOD,
    url: `${FACILITY}/FacilityExternalTeam?facilityId=${facilityId}`,
    // data: payload,
  });
};
export const  AddFacilityTwo = (payload) => {
  return apiClient({
    baseURL: BASE_URL,
    method: POST_METHOD,
    url: `${FACILITY}/FacilityExternalTeam`,
    data: payload,
  });
};

export const  EditFacilityExternalTeam = (payload,id) => {
  return apiClient({
    baseURL: BASE_URL,
    method: PATCHMETHOD,
    url: `${FACILITY}/FacilityExternalTeam?id=${id}`,
    data: payload,
  });
};