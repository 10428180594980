import React, { useEffect, useState } from "react";
import {
  AddPatientCardagencyside,
  FetchAgencyPlanManagement,
  FetchStripeCreateSession,
  PostStripeCreateSession,
} from "../../../../api/helper";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ShowToast } from "../../../../helpers/ToastService";
import { format } from "date-fns";

const validationSchema = Yup.object().shape({
  cardHolderName: Yup.string().required("Card Name is required"),

  cardNumber: Yup.string()
    .required("Card number is required")
    .matches(/^[0-9]{16}$/, "Card number must be 16 digits"),
  expMonth: Yup.number()
    .required("Expiration month is required")
    .min(1, "Invalid month")
    .max(12, "Invalid month"),
  expYear: Yup.number()
    .required("Expiration year is required")
    .min(new Date().getFullYear(), "Year cannot be in the past")
    .max(new Date().getFullYear() + 10, "Year cannot exceed +10 years"),
  CVV: Yup.string()
    .required("CVV is required")
    .matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits"),
});
const MemberPlan = ({ info, getDteails }) => {
  const [activeTab, setActiveTab] = useState("biweekly");
  const [enrollModalData, setEnrollModalData] = useState(null);
  const [enrollalldata, setenrollalldata] = useState(null);
  const [detailsModalData, setDetailsModalData] = useState(null);
  const [plans, setPlans] = useState([]);
  const [activeplan, setactiveplan] = useState([]);
  const [detailsmodal, setdetailsmodal] = useState(false);
  const [upgradeplanmodal, setupgradeplanmodal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    reset();
  };
  const onSubmit = async (data) => {
    console.log("Form Submitted:", data);
    data.id = info?._id;
    try {
      const response = await AddPatientCardagencyside(data);
      ShowToast(response?.data?.message);
      getDteails();
      reset();
      closeModal();
    } catch (error) {}
  };
  const fetchData = async () => {
    try {
      const respones = await FetchAgencyPlanManagement(profiledata?._id);
      setPlans(respones.data.data);
    } catch (error) {
      console.log(error, "===>>");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const switchTab = (tab) => {
    setActiveTab(tab);
  };
  const openEnrollModal = (planName, price, plan) => {
    setEnrollModalData({ planName, price });
    setenrollalldata(plan);
  };

  const closeEnrollModal = () => {
    setEnrollModalData(null);
    setenrollalldata(null);
  };
  const openDetailsModal = (planName, price, frequency, services) => {
    setDetailsModalData({ planName, price, frequency, services });
  };
  const closeDetailsModal = () => {
    setDetailsModalData(null);
  };
  const filterPlansByType = (type) => {
    return plans.filter(
      (plan) => plan.planType.toLowerCase() === type.toLowerCase()
    );
  };

  const PostStripe = async (id) => {
    const data = {
      planId: id,
      patientId: info?.patientId?._id,
      patientRequestId: info?._id,
      agencyId: info?.agencyId?._id,
    };
    // return
    try {
      const respo = await PostStripeCreateSession(data);
      window.location.href = respo?.data?.data;
      closeEnrollModal();
    } catch (error) {
      console.log(error);
    }
  };

  const GetActivePlan = async () => {
    const data = {
      patientId: info?.patientId?._id,
      agencyId: info?.agencyId?._id,
    };
    try {
      const respo = await FetchStripeCreateSession(data);
      setactiveplan(respo?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetActivePlan();
  }, []);
  console.log(profiledata?.phone, "profiledata=====");
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {activeplan.length > 0 ? (
        <div
          className="membership-card_MemberPlan"
          style={{ alignSelf: "center" }}
        >
          <div className="card-header_MemberPlan">
            <div className="program-name">Direct Care Membership</div>
            <div className="membership-status_MemberPlan active">Active</div>
          </div>
          <div className="card-content_MemberPlan">
            <div className="info-group">
              <div className="label_MemberPlan">Member Name</div>
              <div className="value_MemberPlan">
                {activeplan[0]?.patientId?.firstName +
                  " " +
                  activeplan[0]?.patientId?.lastName}
              </div>
            </div>
            <div className="info-group">
              <div className="label_MemberPlan">Membership ID</div>
              <div className="value_MemberPlan">{activeplan[0]?.membershipId}</div>
            </div>
            <div className="info-group">
              <div className="label_MemberPlan">Plan Name</div>
              <div className="value_MemberPlan">
                {activeplan[0]?.planId?.PlanName}
              </div>
            </div>
            <div className="info-group">
              <div className="label_MemberPlan">Effective Date</div>
              <div className="value_MemberPlan">
                {format(activeplan[0]?.startDate, "yyyy/mm/dd")}
              </div>
            </div>
            <div className="info-group">
              <div className="label_MemberPlan">Fee</div>
              <div className="value_MemberPlan">
                ${activeplan[0]?.planId?.fee} /{" "}
                {activeplan[0]?.planId?.planType}
              </div>
            </div>
            <div className="info-group">
              <div className="label_MemberPlan">Benefits Used</div>
              <div className="progress-bar_MemberPlan">
                <div className="progress_MemberPlan" style={{ width: "5%" }} />
              </div>
              <div className="progress-text_MemberPlan">5% Used</div>
            </div>
          </div>
          <div className="action-buttons_MemberPlan">
            <button
              onClick={() => {
                setupgradeplanmodal(true);
              }}
            >
              Upgrade Plan
            </button>
            <button
              onClick={() => {
                setdetailsmodal(true);
              }}
            >
              Plan Details
            </button>
            <button>Renew</button>
          </div>
          <div className="card-footer_MemberPlan">
            <span>
              Member Support: <span>1-800-DPC-CARE</span>
            </span>
            <span className="card-number_MemberPlan">Phone #: {profiledata?.phone}</span>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="tabs_MemberPlan">
              {["biweekly", "monthly", "biannually", "annually"].map((tab) => (
                <button
                  key={tab}
                  className={`tab-button_MemberPlan ${
                    activeTab === tab ? "active" : ""
                  }`}
                  onClick={() => switchTab(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
          </div>

          {["biweekly", "monthly", "biannually", "annually"].map((tab) => (
            <div
              key={tab}
              className={`plans-container_MemberPlan ${
                activeTab === tab ? "active" : ""
              }`}
            >
              {filterPlansByType(tab).length === 0 ? (
                <div className="no-plans-message_MemberPlan">
                  No {tab.charAt(0).toUpperCase() + tab.slice(1)} Subscription
                  Plan available
                </div>
              ) : (
                filterPlansByType(tab).map((plan) => {
                  const isSubscribed = activeplan.some(
                    (active) => active.planId?._id === plan._id
                  );
                  return (
                    <div key={plan._id} className="plan-card_MemberPlan">
                      {isSubscribed && (
                        <span style={{ color: "Green", fontWeight: "bold" }}>
                          {" "}
                          Active
                        </span>
                      )}
                      <div className="plan-title_MemberPlan">
                        {plan.PlanName}
                      </div>
                      <div className="plan-frequency_MemberPlan">{`Charged ${plan.planType.toLowerCase()}`}</div>
                      <div className="plan-price_MemberPlan">{`$${plan.fee} / ${
                        tab.charAt(0).toUpperCase() + tab.slice(1)
                      }`}</div>
                      <ul className="features_MemberPlan">
                        <li>
                          <i className="fas_MemberPlan fa-user-md" />
                          {plan?.Benefits}
                        </li>
                      </ul>
                      <div className="cta-buttons_MemberPlan">
                        <button
                          className="cta-button_MemberPlan"
                          onClick={() =>
                            openEnrollModal(plan.PlanName, `$${plan.fee}`, plan)
                          }
                        >
                          Enroll
                        </button>
                        <button
                          className="cta-button_MemberPlan details-button"
                          onClick={() =>
                            openDetailsModal(
                              plan.PlanName,
                              `$${plan.fee}`,
                              plan.planType,
                              plan.AddIndividualItems.map((item) => ({
                                service: item.item,
                                unit: item.count,
                              }))
                            )
                          }
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          ))}

      
        </>
      )}
    {enrollModalData && (
            <div className="modal_MemberPlan">
              <div className="modal-content_MemberPlan">
                <h2>Enroll in Plan</h2>
                <p>
                  <strong>Plan:</strong> {enrollModalData.planName}
                </p>
                <p>
                  <strong>Price:</strong> {enrollModalData.price}
                </p>

                {info?.patientCards?.length > 0 ? (
                  info.patientCards.map((card) => (
                    <div key={card._id} className="payment-options_MemberPlan">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <label style={{ width: "70%" }}>
                          Card ending in {card.cardNumber.slice(-4)}
                        </label>
                        <input
                          type="radio"
                          name="payment-method"
                          value="card1"
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No cards available.</p>
                )}
                <label
                  style={{ width: "30%", cursor: "pointer" }}
                  className="tab-button_MemberPlan"
                  onClick={openModal}
                >
                  Add New Card
                </label>
                <div
                  id="card-element_MemberPlan"
                  className="element_MemberPlan"
                  style={{ display: "none" }}
                />
                <button
                  className="cta-button_MemberPlan"
                  onClick={() => {
                    PostStripe(enrollalldata?._id);
                  }}
                >
                  Confirm Enrollment
                </button>
                <button
                  className="close-button_MemberPlan"
                  onClick={closeEnrollModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {detailsModalData && (
            <div className="modal_MemberPlan">
              <div className="modal-content_MemberPlan">
                <h2>{detailsModalData.planName}</h2>
                <p>
                  <strong>Price:</strong> {detailsModalData.price}
                </p>
                <p>
                  <strong>Frequency:</strong> {detailsModalData.frequency}
                </p>

                <table>
                  <thead>
                    <tr>
                      <th>Individual Items</th>
                      <th>count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsModalData.services.map((service, index) => (
                      <tr key={index}>
                        <td>{service.service}</td>
                        <td>{service.unit}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="close-button_MemberPlan"
                  onClick={closeDetailsModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}
          {showModal && (
            <div
              className="modal_PaymentForm"
              id="addDocumentModal"
              style={{ backgroundColor: "none" }}
            >
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid black",
                  borderRadius: 7,
                }}
              >
                <div className="payment-container">
                  <div
                    style={{
                      fontSize: "20px",
                      cursor: "pointer",
                      width: 50,
                      position: "absolute",
                      right: 18,
                      top: 27,
                    }}
                    onClick={closeModal}
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkP8S8v6oIxwDrJfdNdFU7FvVNZCcK025zZg&s"
                      style={{ height: 20, width: 20 }}
                    />
                  </div>
                  <h1>Credit Card Payment Form</h1>
                  <p className="terms_twoo">
                    By continuing you agree to our <a>Terms &amp; Conditions</a>
                  </p>
                  <form id="paymentForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="radio-group">
                      <div className="radio-group_label">
                        <input
                          type="radio"
                          {...register("cardType", {
                            required: "Card type is required",
                          })}
                          value="credit"
                          required
                        />
                        <label style={{ marginBottom: 20 }}> Credit Card</label>
                      </div>

                      <div className="radio-group_label">
                        <input
                          type="radio"
                          {...register("cardType", {
                            required: "Card type is required",
                          })}
                          value="debit"
                        />

                        <label style={{ marginBottom: 20 }}> Debit Card</label>
                      </div>
                    </div>
                    <div className="form-group_twoooo">
                      <label htmlFor="cardHolder">Card Holder Name</label>
                      <input
                        type="text"
                        id="cardHolderName"
                        placeholder="Enter Name"
                        {...register("cardHolderName")}
                      />
                      {errors.cardHolderName && (
                        <p className="error-text">
                          {errors.cardHolderName.message}
                        </p>
                      )}
                    </div>
                    <div className="form-group_twoooo">
                      <label htmlFor="cardNumber">Card Number</label>
                      <input
                        type="text"
                        id="cardNumber"
                        placeholder="**** **** **** 1234"
                        maxLength={16}
                        {...register("cardNumber")}
                      />
                      {errors.cardNumber && (
                        <p className="error-text">
                          {errors.cardNumber.message}
                        </p>
                      )}
                      <div className="card-icons">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/0/04/Visa.svg"
                          alt="Visa"
                        />
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg"
                          alt="MasterCard"
                        />
                      </div>
                    </div>
                    <div className="form-inline_twoooo">
                      <div
                        className="form-group_twoooo"
                        style={{ width: "30%" }}
                      >
                        <label htmlFor="expMonth">Exp Month</label>
                        <select id="expMonth" {...register("expMonth")}>
                          <option value="" disabled selected>
                            Select
                          </option>
                          {[...Array(12)].map((_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                        {errors.expMonth && (
                          <p className="error-text">
                            {errors.expMonth.message}
                          </p>
                        )}
                      </div>
                      <div
                        className="form-group_twoooo"
                        style={{ width: "30%" }}
                      >
                        <label htmlFor="expYear">Exp Year</label>
                        <select id="expYear" {...register("expYear")}>
                          <option value="" disabled selected>
                            Select
                          </option>
                          {[...Array(11)].map((_, i) => (
                            <option
                              key={i}
                              value={new Date().getFullYear() + i}
                            >
                              {new Date().getFullYear() + i}
                            </option>
                          ))}
                        </select>
                        {errors.expYear && (
                          <p className="error-text">{errors.expYear.message}</p>
                        )}
                      </div>
                      <div
                        className="form-group_twoooo"
                        style={{ width: "30%" }}
                      >
                        <label htmlFor="cvv">CVV</label>
                        <input
                          type="text"
                          id="CVV"
                          placeholder="123"
                          maxLength={4}
                          {...register("CVV")}
                        />
                        {errors.CVV && (
                          <p className="error-text">{errors.CVV.message}</p>
                        )}
                      </div>
                    </div>
                    <button className="btn-submit">+ Add New Card Now</button>
                  </form>
                </div>
              </div>
            </div>
          )}
      {detailsmodal && (
        <div className="modal_MemberPlan">
          <div className="modal-content_MemberPlan">
            <h2>{activeplan[0]?.planId?.PlanName}</h2>
            <p>
              <strong>Price:</strong> {activeplan[0]?.planId?.fee}
            </p>
            <p>
              <strong>Frequency:</strong>
              {activeplan[0]?.planId?.planType}
            </p>
            <p>
              <strong>Benefits:</strong>
              {activeplan[0]?.planId?.Benefits}
            </p>
            <table>
              <thead>
                <tr>
                  <th>Individual Items</th>
                  <th>count</th>
                </tr>
              </thead>
              <tbody>
                {activeplan[0]?.planId?.AddIndividualItems.map(
                  (service, index) => (
                    <tr key={index}>
                      <td>{service.item}</td>
                      <td>{service.count}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <button
              className="close-button_MemberPlan"
              onClick={() => {
                setdetailsmodal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {upgradeplanmodal && (
        <div className="new_modal_upgrade_plan">
          <div
            style={{
              width: "80%",
              backgroundColor: "white",
              paddingTop: 20,
              paddingBottom: 20,
              height: "90vh",
              borderRadius: 10,
              overflow: "auto",
            }}
          >
            <div
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="tabs_MemberPlan">
                {["biweekly", "monthly", "biannually", "annually"].map(
                  (tab) => (
                    <button
                      key={tab}
                      className={`tab-button_MemberPlan ${
                        activeTab === tab ? "active" : ""
                      }`}
                      onClick={() => switchTab(tab)}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  )
                )}
              </div>
            </div>

            {["biweekly", "monthly", "biannually", "annually"].map((tab) => (
              <div
                key={tab}
                className={`plans-container_MemberPlan ${
                  activeTab === tab ? "active" : ""
                }`}
              >
                {filterPlansByType(tab).length === 0 ? (
                  <div className="no-plans-message_MemberPlan">
                    No {tab.charAt(0).toUpperCase() + tab.slice(1)} Subscription
                    Plan available
                  </div>
                ) : (
                  filterPlansByType(tab).map((plan) => {
                    const isSubscribed = activeplan.some(
                      (active) => active.planId?._id === plan._id
                    );
                    return (
                      <div key={plan._id} className="plan-card_MemberPlan">
                        {isSubscribed && (
                          <span style={{ color: "Green", fontWeight: "bold" }}>
                            {" "}
                            Active
                          </span>
                        )}
                        <div className="plan-title_MemberPlan">
                          {plan.PlanName}
                        </div>
                        <div className="plan-frequency_MemberPlan">{`Charged ${plan.planType.toLowerCase()}`}</div>
                        <div className="plan-price_MemberPlan">{`$${
                          plan.fee
                        } / ${
                          tab.charAt(0).toUpperCase() + tab.slice(1)
                        }`}</div>
                        <ul className="features_MemberPlan">
                          <li>
                            <i className="fas_MemberPlan fa-user-md" />
                            {plan?.Benefits}
                          </li>
                        </ul>
                        <div className="cta-buttons_MemberPlan">
                          <button
                            className="cta-button_MemberPlan"
                            onClick={() =>{
                              setupgradeplanmodal(false);
                              openEnrollModal(
                                plan.PlanName,
                                `$${plan.fee}`,
                                plan
                              )
                            }
                             
                            }
                          >
                            Enroll
                          </button>
                          <button
                            className="cta-button_MemberPlan details-button"
                            onClick={() =>{
                              setupgradeplanmodal(false);
                              openDetailsModal(
                                plan.PlanName,
                                `$${plan.fee}`,
                                plan.planType,
                                plan.AddIndividualItems.map((item) => ({
                                  service: item.item,
                                  unit: item.count,
                                }))
                              )
                            }}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            ))}
            <button
              className="close-button_MemberPlan"
              onClick={() => {
                setupgradeplanmodal(false);
              }}
            >
              Cancel Upgrade
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberPlan;
