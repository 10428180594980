import { useEffect } from "react";
import QuestionForms from "./QuestionForms";

const QustionsMain = ({
  currentCategory,
  currentQuestion,
  responses,
  handleResponse,
  handleBack,
  handleNext,
  handleRestart,
  categoriesState,
  data
}) => {

  return (
    <>
      {
        data ? (
         <div style={{ marginTop: "2rem" }}>
  <h2
    style={{
      fontSize: "1.25rem",
      fontWeight: "bold",
      textAlign: "center",
      color: "#041c32",
      marginBottom: "1.5rem",
    }}
  >
    {data[currentCategory]?.name}
  </h2>

  <QuestionForms
    question={categoriesState[currentCategory]?.questions[currentQuestion]}
    answers={categoriesState[currentCategory]?.answers}
    value={responses[`${currentCategory}-${currentQuestion}`]}
    onChange={handleResponse}
  />

  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      marginTop: "2rem",
    }}
  >
    <div style={{ display: "flex", gap: "1rem" }}>
      <button
        onClick={handleBack}
        disabled={currentCategory === 0 && currentQuestion === 0}
        style={{
          backgroundColor: "#1E3A8A",
          color: "white",
          fontWeight: "bold",
          padding: "0.5rem 1rem",
          borderRadius: "6px",
          opacity: currentCategory === 0 && currentQuestion === 0 ? "0.5" : "1",
          cursor:
            currentCategory === 0 && currentQuestion === 0
              ? "not-allowed"
              : "pointer",
          transition: "background-color 0.3s ease",
        }}
        onMouseEnter={(e) => {
          if (currentCategory !== 0 || currentQuestion !== 0) {
            e.target.style.backgroundColor = "#1D4ED8";
          }
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#1E3A8A";
        }}
      >
        Back
      </button>

      <button
        onClick={handleRestart}
        style={{
          backgroundColor: "#1E3A8A",
          color: "white",
          fontWeight: "bold",
          padding: "0.5rem 1rem",
          borderRadius: "6px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#1D4ED8")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#1E3A8A")}
      >
        Restart
      </button>
    </div>

    <button
      onClick={handleNext}
      disabled={responses[`${currentCategory}-${currentQuestion}`] === undefined}
      style={{
        backgroundColor: "#1E3A8A",
        color: "white",
        fontWeight: "bold",
        padding: "0.5rem 1rem",
        borderRadius: "6px",
        opacity:
          responses[`${currentCategory}-${currentQuestion}`] === undefined
            ? "0.5"
            : "1",
        cursor:
          responses[`${currentCategory}-${currentQuestion}`] === undefined
            ? "not-allowed"
            : "pointer",
        transition: "background-color 0.3s ease",
      }}
      onMouseEnter={(e) => {
        if (responses[`${currentCategory}-${currentQuestion}`] !== undefined) {
          e.target.style.backgroundColor = "#1D4ED8";
        }
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = "#1E3A8A";
      }}
    >
      Next
    </button>
  </div>
</div>

        ) : (
          <h1>You dont have any data</h1>
        )}
    </>
  );
};

export default QustionsMain;
