import React, { useState, useEffect } from "react";

const CalendarView = () => {
  const [appointments, setAppointments] = useState([]);
  const [currentView, setCurrentView] = useState("week");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [miniSelectedDate, setMiniSelectedDate] = useState(null);
  const [clients, setClients] = useState([]);
  const [providers, setProviders] = useState([]);
  const [showAppointmentPanel, setShowAppointmentPanel] = useState(false);
  const [editingAppointmentId, setEditingAppointmentId] = useState(null);
  const [appointmentForm, setAppointmentForm] = useState({
    clientId: "",
    providerId: "",
    date: "",
    start: "",
    duration: "",
    type: "in-office",
    visitReason: "",
    status: "Confirmed",
  });
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [currentAgendaTab, setCurrentAgendaTab] = useState("Scheduled");
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendar, setCalendar] = useState([]);
  const selectedMonth = {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
  };

    const daysInMonth = new Date(selectedMonth.year, selectedMonth.month + 1, 0).getDate();
    const firstDay = new Date(selectedMonth.year, selectedMonth.month, 1).getDay();

    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const emptyCells = Array.from({ length: firstDay }, () => null);
    const calendarDays = [...emptyCells, ...daysArray];;
  const [filters, setFilters] = useState({
    provider: "",
    resource: "",
    location: "",
    reason: "",
    status: "",
  });
  const [weeklySchedule, setWeeklySchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const [schedulingConstraints, setSchedulingConstraints] = useState({
    minGap: 15,
    maxAppointments: 8,
    maxWeeklyAppointments: 40,
    bookingLeadTime: 24,
    cancellationLeadTime: 24,
    breakStart: "12:00",
    breakEnd: "13:00",
    minApptDuration: 15,
    maxApptDuration: 60,
    bufferBefore: 5,
    bufferAfter: 5,
    calendarIncrement: 15,
  });
  useEffect(() => {
    generateMiniCalendar(new Date().getFullYear(), new Date().getMonth());
  }, []);

  const generateMiniCalendar = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    let daysArray = [];
    for (let i = 0; i < firstDay; i++) {
      daysArray.push(null);
    }
    for (let day = 1; day <= lastDate; day++) {
      daysArray.push(day);
    }
    setCalendar(daysArray);
  };

  const handleDateClick = (year, month, day) => {
    const formattedDate = `${year}-${String(month + 1).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")}`;
    setSelectedDate(formattedDate);
  };

  const filteredAppointments = appointments.filter((app) => {
    if (app.date !== selectedDate) return false;
    if (
      filters.provider &&
      !app.provider.toLowerCase().includes(filters.provider.toLowerCase())
    )
      return false;
    if (
      filters.resource &&
      (!app.resource ||
        !app.resource.toLowerCase().includes(filters.resource.toLowerCase()))
    )
      return false;
    if (
      filters.location &&
      (!app.location ||
        !app.location.toLowerCase().includes(filters.location.toLowerCase()))
    )
      return false;
    if (
      filters.reason &&
      (!app.visitReason ||
        !app.visitReason.toLowerCase().includes(filters.reason.toLowerCase()))
    )
      return false;
    if (
      filters.status &&
      (!app.status ||
        !app.status.toLowerCase().includes(filters.status.toLowerCase()))
    )
      return false;
    return true;
  });
  useEffect(() => {
    // Populate clients and providers (replace with your actual data fetching logic)
    setClients([
      { id: "client1", name: "Alice Johnson", dob: "1980-05-12" },
      { id: "client2", name: "Bob Smith", dob: "1975-09-23" },
      // Add more clients as needed
    ]);
    setProviders([
      { id: "provider1", name: "Dr. NAGELEY MICHEL, DNP" },
      { id: "provider2", name: "Dr. John Doe" },
      // Add more providers as needed
    ]);
  }, []);

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const handleDateChange = (direction) => {
    const newDate = new Date(currentDate);
    if (direction === "prev") {
      newDate.setDate(newDate.getDate() - 1);
    } else if (direction === "next") {
      newDate.setDate(newDate.getDate() + 1);
    } else {
      newDate.setDate(new Date().getDate());
    }
    setCurrentDate(newDate);
  };

  const handleInputsettingChange = (e) => {
    const { name, value } = e.target;
    setAppointmentForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingAppointmentId) {
      // Update existing appointment
      setAppointments((prev) =>
        prev.map((app) =>
          app.id === editingAppointmentId ? { ...app, ...appointmentForm } : app
        )
      );
    } else {
      // Create new appointment
      const newAppointment = {
        id: Date.now().toString(), // Simple ID generation
        ...appointmentForm,
      };
      setAppointments((prev) => [...prev, newAppointment]);
    }
    resetAppointmentForm();
    setShowAppointmentPanel(false);
  };

  const resetAppointmentForm = () => {
    setAppointmentForm({
      clientId: "",
      providerId: "",
      date: "",
      start: "",
      duration: "",
      type: "in-office",
      visitReason: "",
      status: "Confirmed",
    });
    setEditingAppointmentId(null);
  };

  const renderAgendaView = () => {
    const filteredAppointments = appointments.filter((app) => {
      if (currentAgendaTab === "Scheduled")
        return (
          app.status !== "Completed" &&
          app.status !== "No Show" &&
          app.status !== "Cancelled"
        );
      if (currentAgendaTab === "Finished")
        return (
          app.status === "Completed" ||
          app.status === "No Show" ||
          app.status === "Cancelled"
        );
      return app.status === "Pending";
    });

    return (
      <div className="agenda-view-container">
        {filteredAppointments.map((app) => (
          <div key={app.id} className="agenda-item">
            <h4>
              {app.start} - {app.end}
            </h4>
            <div>
              <strong>Client:</strong> {app.clientId} <br />
              <strong>Provider:</strong> {app.providerId} <br />
              <strong>Visit Reason:</strong> {app.visitReason} <br />
              <strong>Status:</strong> {app.status}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const addTimeBlock = (day) => {
    const newBlock = { start: "08:00", end: "17:00", location: "Main Office" };
    setWeeklySchedule((prevState) => ({
      ...prevState,
      [day]: [...prevState[day], newBlock],
    }));
  };

  const removeTimeBlock = (day, index) => {
    setWeeklySchedule((prevState) => {
      const newDaySchedule = prevState[day].filter((_, i) => i !== index);
      return {
        ...prevState,
        [day]: newDaySchedule,
      };
    });
  };

  const handleSaveSettings = () => {
    // Save the settings here
    alert("Settings saved successfully!");
    setShowSettingsModal(false); // Close modal after saving
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSchedulingConstraints((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTimeBlockChange = (day, index, field, value) => {
    const newSchedule = [...weeklySchedule[day]];
    newSchedule[index][field] = value;
    setWeeklySchedule((prevState) => ({
      ...prevState,
      [day]: newSchedule,
    }));
  };
  const today = new Date().toLocaleDateString("en-US", {
    weekday: "short",
    month: "numeric",
    day: "numeric",
  });

  const hours = ["12 AM", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM"];

  return (
    <>
      <div className="container_CalendarView">
        {/* Header Bar */}
        <div className="header-bar_CalendarView">
          <div className="header-left_CalendarView">
            <div className="view-toggle_CalendarView">
              <button
                onClick={() => handleViewChange("week")}
                style={{ marginRight: 5 }}
                className="nav-button_CalendarView"
              >
                Week
              </button>
              <button
                onClick={() => handleViewChange("day")}
                style={{ marginRight: 5 }}
                className="nav-button_CalendarView"
              >
                Day
              </button>
              <button
                onClick={() => handleViewChange("month")}
                style={{ marginRight: 5 }}
                className="nav-button_CalendarView"
              >
                Month
              </button>
              <button
                onClick={() => handleViewChange("agenda")}
                className="nav-button_CalendarView"
              >
                Agenda
              </button>
            </div>
            <button
              onClick={() => handleDateChange("prev")}
              className="nav-button_CalendarView"
            >
              Prev
            </button>
            <button
              onClick={() => handleDateChange("today")}
              className="today-button_CalendarView"
            >
              Today
            </button>
            <button
              onClick={() => handleDateChange("next")}
              className="nav-button_CalendarView"
            >
              Next
            </button>
            <div className="date-range_CalendarView" id="date-range">
              {currentDate.toDateString()}
            </div>
          </div>
          <div>
            <button
              onClick={() => setShowAppointmentPanel(true)}
              className="add-new-button_CalendarView"
            >
              + Create Appointment
            </button>
            <button
              onClick={() => setShowSyncModal(true)}
              className="synch-button_CalendarView"
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              Calendar Synch
            </button>
            <button
              onClick={() => setShowSettingsModal(true)}
              className="settings-button_CalendarView"
            >
              Settings
            </button>
          </div>
        </div>

        {/* Search Container for Appointments Table */}
        <div id="search-container">
          <input
            type="text"
            id="search-input"
            placeholder="Search appointments..."
          />
        </div>

        {/* FLEX CONTAINER: Mini Calendar (left) & Main Calendar & Table (right) */}
        <div style={{ display: "flex", gap: 20 }}>
          <div className="mini-calendar-container_CalendarView">
            {/* Calendar */}
            <div id="miniCalendar">
              <table>
                <thead>
                  <tr>
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                      (day) => (
                        <th key={day}>{day}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: Math.ceil(calendar.length / 7) }).map(
                    (_, weekIndex) => (
                      <tr key={weekIndex}>
                        {calendar
                          .slice(weekIndex * 7, weekIndex * 7 + 7)
                          .map((day, idx) => (
                            <td
                              key={idx}
                              className={
                                day === new Date().getDate() ? "today" : ""
                              }
                              onClick={() =>
                                day &&
                                handleDateClick(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  day
                                )
                              }
                            >
                              {day || ""}
                            </td>
                          ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>

            {/* Filters */}
            <div className="calendar-filters_CalendarView">
              {["provider", "resource", "location", "reason", "status"].map(
                (filter) => (
                  <div key={filter}>
                    <label htmlFor={`${filter}Filter`}>
                      {filter.charAt(0).toUpperCase() + filter.slice(1)}:
                    </label>
                    <input
                      type="text"
                      id={`${filter}Filter`}
                      placeholder={`Filter by ${filter}`}
                      value={filters[filter]}
                      onChange={(e) =>
                        setFilters({ ...filters, [filter]: e.target.value })
                      }
                    />
                  </div>
                )
              )}
            </div>

            {/* Selected Date */}
            <div id="selectedMiniDay">
              {selectedDate
                ? `Selected Date: ${selectedDate}`
                : "No Date Selected"}
            </div>

            {/* Appointment List */}
            <ul id="miniEventList">
              {selectedDate && filteredAppointments.length > 0 ? (
                filteredAppointments.map((app, index) => (
                  <li
                    key={index}
                    onClick={() => alert(`Edit Appointment ${app.id}`)}
                  >
                    {app.start}-{app.end} | {app.client} | {app.provider}
                  </li>
                ))
              ) : (
                <li>No appointments for this date.</li>
              )}
            </ul>
          </div>

          {/* Right Column: Calendar Container & Appointments Table */}
          <div style={{ flex: 1 }}>
            {/* Calendar Container */}
            <div id="calendar-container" />
            {currentView === "week" && (
        <div className="week-view_CalendarView">
          <div className="week-view-header_CalendarView">
            {[...Array(7)].map((_, i) => {
              const date = new Date(selectedDate);
              date.setDate(date.getDate() - date.getDay() + i);
              return (
                <div key={i} className="week-day-header_CalendarView">
                  {date.toLocaleDateString("en-US", { weekday: "short", month: "numeric", day: "numeric" })}
                </div>
              );
            })}
          </div>
          <div className="week-view-grid_CalendarView">
            {[...Array(6)].map((_, hour) => (
              <div key={hour} className="week-hour-row_CalendarView">
                <div className="week-time-cell_CalendarView">{hour === 0 ? "12 AM" : `${hour} AM`}</div>
                {[...Array(7)].map((_, day) => (
                  <div key={day} className="week-day-cell_CalendarView"></div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
            {currentView === "day" && (
        <div className="day-view_CalendarView">
          <div className="day-view-header_CalendarView">  {currentDate.toDateString()}</div>
          <div className="day-view-grid_CalendarView">
            <div>
              {hours.map((hour, index) => (
                <div key={index} className="day-view-time_CalendarView">
                  {hour}
                </div>
              ))}
            </div>
            <div className="day-view-rows_CalendarView">
              {hours.map((_, index) => (
                <div key={index} className="day-view-row_CalendarView"></div>
              ))}
            </div>
          </div>
        </div>
      )}
       {currentView === "month" && (
       <div className="month-view_CalendarView">
       <div className="month-view-header_CalendarView">
         {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
           <div key={day} className="month-view-day-header_CalendarView">
             {day}
           </div>
         ))}
       </div>
       <div className="month-view-grid_CalendarView">
         {calendarDays.map((day, index) => (
           <div
             key={index}
             className={`month-view-day_CalendarView ${day ? "current-month" : "empty"}`}
           >
             {day}
           </div>
         ))}
       </div>
     </div>
      )}
            {currentView === "agenda" && (
              <div>
                <div className="agenda-tabs_CalendarView">
                  <button
                    className={`agenda-tab_CalendarView ${
                      currentAgendaTab === "Scheduled" ? "active" : ""
                    }`}
                    onClick={() => setCurrentAgendaTab("Scheduled")}
                  >
                    Scheduled Items
                  </button>
                  <button
                    className={`agenda-tab_CalendarView ${
                      currentAgendaTab === "Finished" ? "active" : ""
                    }`}
                    onClick={() => setCurrentAgendaTab("Finished")}
                  >
                    Finished Items
                  </button>
                  <button
                    className={`agenda-tab_CalendarView ${
                      currentAgendaTab === "Pending" ? "active" : ""
                    }`}
                    onClick={() => setCurrentAgendaTab("Pending")}
                  >
                    Pending Items
                  </button>
                </div>
                {renderAgendaView()}
              </div>
            )}
            {/* Appointments Table Container */}
            <div className="appointments-table-container_CalendarView">
              <h3 style={{ textAlign: "left" }}>Appointments List</h3>
              <table className="appointments-table_CalendarView">
                <thead>
                  <tr>
                    <th>SessionID</th>
                    <th>Client</th>
                    <th>Provider</th>
                    <th>Type</th>
                    <th>Payer</th>
                    <th>Duration</th>
                    <th>Billing Unit</th>
                    <th>SubTotal</th>
                    <th>Visit Reason</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>PA Number</th>
                    <th>Ref. NPI</th>
                    <th>Status</th>
                    <th>Create Note</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {appointments.map((app) => (
                    <tr key={app.id}>
                      <td>{app.id}</td>
                      <td>{app.clientId}</td>
                      <td>{app.providerId}</td>
                      <td>{app.type}</td>
                      <td>{app.payer}</td>
                      <td>{app.duration}</td>
                      <td>{app.billingUnit}</td>
                      <td>{app.subTotal}</td>
                      <td>{app.visitReason}</td>
                      <td>{app.date}</td>
                      <td>{app.location}</td>
                      <td>{app.paNumber}</td>
                      <td>{app.refNPI}</td>
                      <td>{app.status}</td>
                      <td>
                        <button
                          onClick={() => {
                            /* openEditModal(app.id) */
                          }}
                        >
                          Create Note
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            /* deleteAppointment(app.id) */
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination Controls */}
              <div
                id="pagination-controls"
                className="pagination-controls_CalendarView"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Appointment Panel (Slide-in Quick Create Modal) */}
      {/* {showAppointmentPanel && ( */}
      <div
        className={`appointment-panel_CalendarView ${
          showAppointmentPanel ? "show" : ""
        }`}
      >
        <div className="modal-content_CalendarView">
          <span
            className="close_CalendarView"
            onClick={() => setShowAppointmentPanel(false)}
          >
            &times;
          </span>
          <h2 id="modal-title">
            {editingAppointmentId ? "Edit Appointment" : "Add Appointment"}
          </h2>
          <form
            id="confirm-appointment-form"
            className="form-container_CalendarView"
            onSubmit={handleSubmit}
          >
            {/* Client Information Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Client Information
              </div>
              <div>
                <div className="form-group_CalendarView">
                  <label htmlFor="client-select">Select Client:</label>
                  <select
                    id="client-select"
                    name="clientId"
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">-- Select Client --</option>
                    {clients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="client-dob">DOB:</label>
                  <input
                    type="text"
                    id="client-dob"
                    name="client-dob"
                    value={
                      appointmentForm.clientId
                        ? clients.find((c) => c.id === appointmentForm.clientId)
                            ?.dob
                        : ""
                    }
                    disabled
                  />
                </div>
              </div>
            </div>
            {/* Provider Information Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Provider Information
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="provider-select">Select Provider:</label>
                  <select
                    id="provider-select"
                    name="providerId"
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">-- Select Provider --</option>
                    {providers.map((provider) => (
                      <option key={provider.id} value={provider.id}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* Appointment Details Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Appointment Details
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="appointment-type">Appointment Type:</label>
                  <select
                    id="appointment-type"
                    name="type"
                    required
                    value={appointmentForm.type}
                    onChange={handleInputChange}
                  >
                    <option value="in-office">In-Office</option>
                    <option value="telehealth">Telehealth</option>
                    <option value="home-visit">Home Visit</option>
                    <option value="availability">Availability</option>
                    <option value="busy">Busy/Vacation</option>
                  </select>
                </div>
                <div
                  className="form-group_CalendarView"
                  id="telehealth-url-container"
                  style={{
                    display:
                      appointmentForm.type === "telehealth" ? "block" : "none",
                  }}
                >
                  <label htmlFor="telehealth-url">Telehealth URL:</label>
                  <a id="telehealth-url" href="#" target="_blank">
                    Join Telehealth Session
                  </a>
                </div>
              </div>
              <div >
                <div className="form-group_CalendarView">
                  <label htmlFor="date">Date:</label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    required
                    value={appointmentForm.date}
                    onChange={handleInputChange}
                  />
                  <div className="error-message_CalendarView" id="date-error">
                    Please select a valid date.
                  </div>
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="time">Start Time:</label>
                  <input
                    type="time"
                    id="time"
                    name="start"
                    required
                    value={appointmentForm.start}
                    onChange={handleInputChange}
                  />
                  <div className="error-message_CalendarView" id="time-error">
                    Please select a valid time.
                  </div>
                </div>
              </div>
              <div>
                <div className="form-group_CalendarView">
                  <label htmlFor="visit-reason">Visit Reason:</label>
                  <select
                    id="visit-reason"
                    name="visitReason"
                    required
                    value={appointmentForm.visitReason}
                    onChange={handleInputChange}
                  >
                    <option value="Psych Eval. & Mgmt. for New Pt">
                      Psych Eval. &amp; Mgmt. for New Pt
                    </option>
                    <option value="Follow-up Visit">Follow-up Visit</option>
                    <option value="Medication Management">
                      Medication Management
                    </option>
                    <option value="Therapy Session">Therapy Session</option>
                  </select>
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="duration">Duration (in mins):</label>
                  <input
                    type="number"
                    id="duration"
                    name="duration"
                    min={1}
                    required
                    value={appointmentForm.duration}
                    onChange={handleInputChange}
                  />
                  <div
                    className="error-message_CalendarView"
                    id="duration-error"
                  >
                    Please enter a valid duration.
                  </div>
                </div>
              </div>
              {/* New Status Field */}
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="appointment-status">Status:</label>
                  <select
                    id="appointment-status"
                    name="status"
                    required
                    value={appointmentForm.status}
                    onChange={handleInputChange}
                  >
                    <option value="Confirmed">Confirmed</option>
                    <option value="Start Session">Start Session</option>
                    <option value="Completed">Completed</option>
                    <option value="No Show">No Show</option>
                    <option value="Rescheduled">Rescheduled</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Clocked In">Clocked In</option>
                    <option value="Clocked Out">Clocked Out</option>
                    <option value="Pending">Pending</option>
                  </select>
                </div>
              </div>
            </div>
            {/* Repeat Appointment Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Repeat Appointment
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="repeats">Repeats:</label>
                  <input type="checkbox" id="repeats" name="repeats" />
                </div>
              </div>
              <div id="repeat-options" style={{ display: "none" }}>
                <div className="form-row_CalendarView">
                  <label>Repeat Days:</label>
                  <div className="repeat-days-container_CalendarView">
                    {["sun", "mon", "tue", "wed", "thu", "fri", "sat"].map(
                      (day) => (
                        <label className="day-pill_CalendarView" key={day}>
                          <input
                            type="checkbox"
                            name="repeat-day"
                            value={day}
                          />
                          <span>{day.charAt(0).toUpperCase()}</span>
                        </label>
                      )
                    )}
                  </div>
                </div>
                <div className="form-row_CalendarView">
                  <label>End Condition:</label>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="end-condition"
                        value="end-of-month"
                        defaultChecked
                      />{" "}
                      Until the End of Month
                    </label>
                    <br />
                    <label>
                      <input
                        type="radio"
                        name="end-condition"
                        value="specific-date"
                      />{" "}
                      Specific Date
                    </label>
                    <input
                      type="date"
                      id="specific-date"
                      name="specific-date"
                    />
                    <br />
                    <label>
                      <input
                        type="radio"
                        name="end-condition"
                        value="number-of-events"
                      />{" "}
                      Number of Events
                    </label>
                    <input
                      type="number"
                      id="number-of-events"
                      name="number-of-events"
                      placeholder="Enter number of events"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Service Location Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Service Details
              </div>
              <div >
                <div className="form-group_CalendarView">
                  <label htmlFor="location">Service Location:</label>
                  <select id="location" name="location" required>
                    <option>EnnHealth - Telehealth</option>
                    <option>In-Office</option>
                    <option>Home Visit</option>
                    <option>Add Another Location</option>
                  </select>
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="payment">Payment Source:</label>
                  <input
                    type="text"
                    id="payment"
                    name="payment"
                    defaultValue="Private Pay"
                    disabled
                  />
                </div>
              </div>
            </div>
            {/* Patient Intake Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Patient Intake
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="patient-intake">Required Documents:</label>
                  <div className="multiselect_CalendarView">
                    <div
                      className="tag-container_CalendarView"
                      id="selectedTags"
                    />
                    <div className="checkboxes_CalendarView" id="checkboxes">
                      {[
                        "Medical History",
                        "Consent Forms",
                        "Insurance Information",
                        "Personal Information",
                        "Telehealth Consent",
                        "Credit Card Information",
                        "PHQ-9",
                        "GAD-7",
                        "Financial Responsibility",
                      ].map((doc) => (
                        <label key={doc}>
                          <input
                            type="checkbox"
                            id={doc.replace(/\s+/g, "-").toLowerCase()}
                            value={doc}
                          />
                          {doc}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Provider Task Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Provider Task
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="provider-task">Tasks:</label>
                  <div className="multiselect_CalendarView provider-task">
                    <div
                      className="tag-container_CalendarView"
                      id="selectedProviderTasks"
                    />
                    <div
                      className="checkboxes_CalendarView"
                      id="provider-checkboxes"
                    >
                      {[
                        "Review Patient History",
                        "Call Patient",
                        "Update Records",
                        "Consult with Team",
                      ].map((task) => (
                        <label key={task}>
                          <input
                            type="checkbox"
                            id={task.replace(/\s+/g, "-").toLowerCase()}
                            value={task}
                          />
                          {task}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Confirmation Methods Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Patient Confirmation Methods
              </div>
              <div className="form-row_CalendarView">
                <label>
                  <input
                    type="checkbox"
                    id="email-confirm"
                    name="email-confirm"
                    defaultChecked
                  />
                  Send confirmation Email to emichel@acsyom.com
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="sms-confirm"
                    name="sms-confirm"
                    defaultChecked
                  />
                  Send confirmation SMS to (352) 205-3479
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="inapp-confirm"
                    name="inapp-confirm"
                    defaultChecked
                  />
                  Send push notification to the app
                </label>
              </div>
            </div>
            {/* Provider Confirmation Methods Section */}
            <div className="section_CalendarView">
              <div
                className="section-header_CalendarView"
                style={{ textAlign: "left" }}
              >
                Provider Confirmation Methods
              </div>
              <div className="form-row_CalendarView">
                <label>
                  <input
                    type="checkbox"
                    id="provider-email-confirm"
                    name="provider-email-confirm"
                    defaultChecked
                  />
                  Send confirmation Email to provider@example.com
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="provider-sms-confirm"
                    name="provider-sms-confirm"
                    defaultChecked
                  />
                  Send confirmation SMS to (000) 000-0000
                </label>
                <label>
                  <input
                    type="checkbox"
                    id="provider-inapp-confirm"
                    name="provider-inapp-confirm"
                    defaultChecked
                  />
                  Send push notification to provider app
                </label>
              </div>
            </div>
            {/* Form Buttons */}
            <div className="button-container_CalendarView">
              <button type="submit" className="confirm-btn_CalendarView">
                Confirm Appointment
              </button>
              <button
                type="button"
                className="decline-btn_CalendarView"
                onClick={() => setShowAppointmentPanel(false)}
              >
                Decline Appointment
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* )} */}

      {/* SETTINGS MODAL */}
      {showSettingsModal && (
        <div id="settings-modal" className="modal_CalendarView">
          <div className="modal-content_CalendarView">
            <div className="close_CalendarView">
              <span
                style={{ width: 30 }}
                onClick={() => setShowSettingsModal(false)}
              >
                &times;
              </span>
            </div>
            <h2>Working Hours Settings</h2>
            <p>
              Configure your working hours, locations, and scheduling
              constraints.
            </p>
            <div id="settings-days-container" style={{ marginBottom: 20 }}>
              {Object.keys(weeklySchedule).map((day) => (
                <div key={day} className="day-row_CalendarView">
                  <div className="day-row-header_CalendarView">
                    <strong>{day}</strong>
                    <button
                      type="button"
                      className="add-block-btn_CalendarView"
                      onClick={() => addTimeBlock(day)}
                    >
                      Add Time Block
                    </button>
                  </div>
                  <div className="time-blocks_CalendarView">
                    {weeklySchedule[day].map((block, index) => (
                      <div key={index} className="time-block_CalendarView">
                        <label>Start:</label>
                        <input
                          type="time"
                          value={block.start}
                          onChange={(e) =>
                            handleTimeBlockChange(
                              day,
                              index,
                              "start",
                              e.target.value
                            )
                          }
                        />
                        <label>End:</label>
                        <input
                          type="time"
                          value={block.end}
                          onChange={(e) =>
                            handleTimeBlockChange(
                              day,
                              index,
                              "end",
                              e.target.value
                            )
                          }
                        />
                        <label>Location:</label>
                        <select
                          value={block.location}
                          onChange={(e) =>
                            handleTimeBlockChange(
                              day,
                              index,
                              "location",
                              e.target.value
                            )
                          }
                        >
                          <option value="Main Office">Main Office</option>
                          <option value="Satellite Office">
                            Satellite Office
                          </option>
                          <option value="Telehealth">Telehealth</option>
                        </select>
                        <button
                          type="button"
                          className="remove-block-btn_CalendarView"
                          onClick={() => removeTimeBlock(day, index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="section_CalendarView" id="constraints-section">
              <div className="section-header_CalendarView" style={{textAlign:'left'}}>
                Scheduling Constraints
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="min-gap">
                    Minimum Gap Between Sessions (minutes):
                  </label>
                  <input
                    type="number"
                    id="min-gap"
                    name="min-gap"
                    min={0}
                    defaultValue={15}
                  />
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="max-appointments">
                    Maximum Appointments Per Day:
                  </label>
                  <input
                    type="number"
                    id="max-appointments"
                    name="max-appointments"
                    min={1}
                    defaultValue={8}
                  />
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="max-weekly-appointments">
                    Maximum Appointments Per Week:
                  </label>
                  <input
                    type="number"
                    id="max-weekly-appointments"
                    name="max-weekly-appointments"
                    min={1}
                    defaultValue={40}
                  />
                </div>
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="booking-leadtime">
                    Client Booking Lead Time (Hours):
                  </label>
                  <input
                    type="number"
                    id="booking-leadtime"
                    name="booking-leadtime"
                    min={1}
                    defaultValue={24}
                  />
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="cancellation-leadtime">
                    Cancellation Lead Time (Hours):
                  </label>
                  <input
                    type="number"
                    id="cancellation-leadtime"
                    name="cancellation-leadtime"
                    min={1}
                    defaultValue={24}
                  />
                </div>
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="break-start">Mandatory Break (Start):</label>
                  <input
                    type="time"
                    id="break-start"
                    name="break-start"
                    defaultValue="12:00"
                  />
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="break-end">Mandatory Break (End):</label>
                  <input
                    type="time"
                    id="break-end"
                    name="break-end"
                    defaultValue="13:00"
                  />
                </div>
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="min-appt-duration">
                    Minimum Appointment Duration (Minutes):
                  </label>
                  <input
                    type="number"
                    id="min-appt-duration"
                    name="min-appt-duration"
                    min={1}
                    defaultValue={15}
                  />
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="max-appt-duration">
                    Maximum Appointment Duration (Minutes):
                  </label>
                  <input
                    type="number"
                    id="max-appt-duration"
                    name="max-appt-duration"
                    min={1}
                    defaultValue={60}
                  />
                </div>
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="buffer-before">
                    Buffer Time Before (Minutes):
                  </label>
                  <input
                    type="number"
                    id="buffer-before"
                    name="buffer-before"
                    min={0}
                    defaultValue={5}
                  />
                </div>
                <div className="form-group_CalendarView">
                  <label htmlFor="buffer-after">
                    Buffer Time After (Minutes):
                  </label>
                  <input
                    type="number"
                    id="buffer-after"
                    name="buffer-after"
                    min={0}
                    defaultValue={5}
                  />
                </div>
              </div>
              <div className="form-row_CalendarView">
                <div className="form-group_CalendarView">
                  <label htmlFor="calendar-increment">
                    Calendar Increment (minutes):
                  </label>
                  <select id="calendar-increment" name="calendar-increment">
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15} selected="">
                      15
                    </option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={60}>60</option>
                  </select>
                </div>
              </div>
              <p className="small_CalendarView">
                <em>
                  For advanced constraints (equipment/room availability,
                  multi-provider overlaps, time zone rules, etc.), configure in
                  admin console or add here.
                </em>
              </p>
            </div>

            <div className="button-container_CalendarView">
              <button
                type="button"
                className="save-settings-button_CalendarView"
                onClick={handleSaveSettings}
              >
                Save Settings
              </button>
            </div>
          </div>
        </div>
      )}

    
      {showSyncModal && (
        <div id="synch-modal" className="modal_CalendarView">
          <div className="modal-content_CalendarView">
            <span
              className="close_CalendarView"
              onClick={() => setShowSyncModal(false)}
            >
              ×
            </span>
            <h2>Calendar Synchronization</h2>
            <p style={{textAlign:'left'}}>Select your calendar service to synchronize:</p>
            <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
              <button id="sync-google" className="synch-button_CalendarView">
                Google Calendar
              </button>
              <button id="sync-outlook" className="synch-button_CalendarView">
                Outlook
              </button>
              <button id="sync-ical" className="synch-button_CalendarView">
                iCalendar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Provider Details Modal */}
      {/* <div id="provider-modal" className="modal_CalendarView">
        <div className="modal-content_CalendarView">
          <span className="close_CalendarView" id="provider-close">
            ×
          </span>
          <h2>Provider Details</h2>
          <div id="provider-details" />
        </div>
      </div> */}

      {/* Client Details Modal */}
      {/* <div id="client-modal" className="modal_CalendarView">
        <div className="modal-content_CalendarView">
          <span className="close_CalendarView" id="client-close">
            ×
          </span>
          <h2>Client Details</h2>
          <div id="client-details" />
        </div>
      </div> */}

      {/* Render Agenda View */}
    </>
  );
};

export default CalendarView;
