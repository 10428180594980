import React, { useEffect, useState } from "react";
import {
  completeSurvey,
  Fetchactual,
  Fetchcategoriescreate,
  Fetchservays,
  getCatrgoryInSurvay,
} from "../../../../api/helper";
import MentalHealthMetricsCard from "../MentalHealthMetricsCard";
import QustionsMainWrapper from "../QustionsMainWrapper";
import Aleart from "./Measere/Aleart";
import MH_ScoreMain from "./Measere/MH_ScoreMain";
import TabOtherAndResults from "./Measere/TabOtherAndResults";
import SendQuestionsnaireMain from "./Measere/SendQuestionsnaire/SendQuestionsnaireMain";
import QustionsMain from "./QustionsMain/QustionsMain";
import { categoryInfoData } from "./QustionsMain/categoryInfoData";
import MentalHealthMetricsMain from "./Measere/MentalHealthMetricsMain";
import { interpretCompositeScore } from "../../../../utils/main";
import { ShowToast } from "../../../../helpers/ToastService";
import { useSelector } from "react-redux";

const MeasuresTabScreen = ({ info }) => {
  const [latestCategories, setLatestCategories] = useState([]);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [responses, setResponses] = useState({});
  const [categoriesState, setCategoriesState] = useState([]);
  const [compositeScore, setCompositeScore] = useState(0);
  const [categories, setCategories] = useState(null);
  const [isStart, setIsStart] = useState(true)
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  // `agencyId=${profiledata._id}&patientId=${patientDetails._id}`

  const getCategoryData = async () => {
    try {
      // `agencyId=${profiledata._id}`
      const response = await getCatrgoryInSurvay(`agencyId=${profiledata._id}`)
      setCategoriesState(response.data.categories)
    } catch (error) {
      console.log(error.message)
    }
  }
  useEffect(() => {
    getCategoryData()
  }, [])


  const handleNext = async () => {
    if (
      categoriesState &&
      currentQuestion < categoriesState[currentCategory].questions.length - 1
    ) {
      setCurrentQuestion((prev) => prev + 1);
    } else if (currentCategory < categoriesState.length - 1) {
      setCurrentCategory((prev) => prev + 1);
      setCurrentQuestion(0);
    } else {
      // complate survey
      const data = {
        responses: responses,
        categoryInfo: JSON.stringify(categoryInfoData(categoriesState)),
        mhScore: compositeScore,
        alerts: interpretCompositeScore(compositeScore),
        agencyId:profiledata._id,
        patientId:info._id
      };
      
      try {
        const response = await completeSurvey(data);
        if (response) {
          setTimeout(() => {
            localStorage.removeItem("mh_responses");
            localStorage.removeItem("selectedQuestionnaires");
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        ShowToast("Something went wrong ! Please try again","warning")
      }
    }
    if (!isStart) {
      // dispatch(start());
    }
  };



  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    } else if (currentCategory > 0) {
      setCurrentCategory((prev) => prev - 1);
      setCurrentQuestion(
        categoriesState[currentCategory - 1].questions.length - 1
      );
    }
  };

  const handleRestart = () => {
    if (
      window.confirm(
        "Are you sure you want to restart the survey? All responses will be lost."
      )
    ) {
      localStorage.removeItem("mh_responses");
      setResponses({});
      setCurrentCategory(0);
      setCurrentQuestion(0);
      setCategoriesState(
        categories.map((cat) => ({ ...cat, score: 0, lastUpdated: "" }))
      );
      setCompositeScore(0);
    }
  };

  const handleResponse = (value) => {
    const newResponses = {
      ...responses,
      [`${currentCategory}-${currentQuestion}`]: value,
    };
    setResponses(newResponses);
    localStorage.setItem("mh_responses", JSON.stringify(newResponses));
    updateScores(newResponses);
  };

  const updateScores = (currentResponses) => {
    const updatedCategories = categoriesState.map((category, catIndex) => {
      let categoryScore = 0;
      category.questions.forEach((_, qIndex) => {
        const response = currentResponses[`${catIndex}-${qIndex}`];
        if (response !== undefined) {
          categoryScore += response;
        }
      });

      return {
        ...category,
        score: categoryScore,
        lastUpdated: new Date().toLocaleDateString(),
      };
    });

    setCategoriesState(updatedCategories);
    calculateCompositeScore(updatedCategories);
  };

  const calculateCompositeScore = (updatedCategories) => {
    let totalWeighted = 0;
    let totalWeight = 0;

    updatedCategories.forEach((cat) => {
      const numQuestions = cat.questions.length;
      const maxScore =
        numQuestions * Math.max(...cat.answers.map((a) => a.value));
      const normalized = maxScore > 0 ? (cat.score / maxScore) * 100 : 0;

      const w = cat?.weight || 0;
      totalWeighted += normalized * w;
      totalWeight += w;
    });

    const newCompositeScore = Math.round(
      totalWeight > 0 ? totalWeighted / totalWeight : 0
    );

    // if its more then 100
    if (newCompositeScore > 100) {
      setCompositeScore(100);
    } else {
      setCompositeScore(newCompositeScore);
    }
  };

  const GetFetchcategoriescreate = async () => {
    try {
      const response = await Fetchcategoriescreate();
      setCategories(response?.data?.categories);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetFetchcategoriescreate();
  }, []);


  const GetActualcode = async () => {
    try {
      const response = await Fetchactual(`agencyId=${profiledata._id}&patientId=${info._id}`);
      if (response?.data?.totalScore) {
        setScore(
          response?.data?.totalScore[response?.data?.totalScore?.length - 1]
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const Getsurveys = async () => {
    try {
      const response = await Fetchservays(`agencyId=${profiledata._id}`);
      if (response?.data?.surveys) {
        const survery =
          response?.data?.surveys[response?.data?.surveys?.length - 1];
        setLatestCategories(survery?.categoryInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Getsurveys();
    GetActualcode();
  }, []);
  return (
    <div className="dashboard-container_two">
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ display: "flex", width: "50%" }}>
          <Aleart compositeScore={compositeScore} />
          <MH_ScoreMain compositeScore={compositeScore} />
        </div>
        <TabOtherAndResults />
      </div>
      <div
        className="key-indicator"
      >
        <MentalHealthMetricsMain
          categoriesState={categoriesState}
          currentQuestion={currentQuestion}
          categories={categories}
        />
      </div>
      <QustionsMain
        currentQuestion={currentQuestion}
        currentCategory={currentCategory}
        handleBack={handleBack}
        handleNext={handleNext}
        handleResponse={handleResponse}
        handleRestart={handleRestart}
        responses={responses}
        categoriesState={categoriesState}
        data={categories}
      />
    </div>
  );
};

export default MeasuresTabScreen;
